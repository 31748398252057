import React, { Component } from "react";
import { Calendar } from "react-calendar";
import Dropdown from "../../components/Dropdown";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import print from "../../assets/print.png"
import zoom from "../../assets/zoom.png"
import download from "../../assets/download.png"
import { withRouter } from "../../components/withRouter";
import { deletedevice, getDevices, getHandheldDevice, getStoreName } from "../../Path/Path";
import Qrmodal from "../../components/Qrmodal";
import { toast } from "react-toastify";
import MyInput from "../../components/MyInput";
import { PaginatedItems } from "../../components/Paginated";
import { Autocomplete, TextField } from "@mui/material";
import Loader from '../../components/Loader';
import { PaginatedItemsOffline } from "../../components/PaginatedOffline";
import AlertDialog from "../../components/AlertDialog";
class Devices extends Component {
    state = {
        location: [],
        deviceID: [],
        data: [],
        TableFilter: [],
        modalopen: false,
        qrdata: null,
        locationinput: "",
        deviceIDname: "",
        itemsPerPage: 10,
        loading: false,
        deleteModal: null,
    }
    componentDidMount() {
        this.saveNewUsersToState()
    }
    saveNewUsersToState = async () => {
        try {
            this.setState({ loading: true })
            const data = await getDevices();
            this.setState({ data: data, TableFilter: data })
            this.setState({ loading: false })
        } catch (error) {
            this.setState({loading: false})
            toast.error("Something went wrong")
        }
    }

    handler = () => {
        this.props.history("/adddevices")
    }
    modalopen = (item) => {
        this.setState({ modalopen: !this.state.modalopen, qrdata: item })
    }
    editHandler = (item) => {

        this.props.history("/adddevices", { state: { item } })
    }
    deleteHandler = async (myID) => {

        try {
            this.setState({ loading: true })
            await deletedevice(myID);
            this.saveNewUsersToState();
            toast.success("Device deleted successfully")
            this.setState({deleteModal: null})
        } catch (err) {
            toast.warn(err.message)
            this.setState({ loading: false })
        }
    }
    searchHandler = () => {

        if (!this.state.locationinput && !this.state.deviceIDname) {
            this.setState({ TableFilter: this.state.data })
            return
        }
        const res = this.state.data.filter(i => i.userName.toLowerCase().includes(this.state.locationinput.toLowerCase()))

        const res1 = res.filter(i => i.deviceUniqueID.toLowerCase().includes(this.state.deviceIDname.toLowerCase()))
        console.log('res1: ', res1);

        this.setState({ TableFilter: res1 })
    }
    render() {
        const { location, deviceID, qrdata } = this.state;
        console.log(this.state.data);

        const headers = [
            "UserName",
            "Server_Ip_Protocol",
            "Server_Ip",
            "Flow_Server_Ip_Protocol",
            "Flow_Server_Ip",
            "UUID",
            "Active",
            "Device Unique ID",
            "Store Name"
        ]

        const colNames = [
            "userName",
            "serverProtocol",
            "server",
            "flowServerProtocol",
            "flowServerIp",
            "uuid",
            "active",
            "deviceUniqueID",
            "siteId.storename"
        ]

        return (
            <>
            <AlertDialog
                    deleteThis="User"
                    onDeletePress={() => this.deleteHandler(this.state?.deleteModal)}
                    open={this.state.deleteModal ? true : false}
                    handleClose={() => this.setState({ deleteModal: null })}
                />
                <Loader loading={this.state.loading} />
                <div style={{ position: 'relative' }}>
                    {
                        this.state.modalopen &&
                        <Qrmodal
                            close={() => this.setState({ modalopen: false })}
                            value={`https|${qrdata?.server}|https${qrdata?.flowServerIp}|${qrdata?.userName}|${qrdata?.password}|${qrdata?.siteId?.storecode}`}
                        />
                    }



                    <div className="dashboard-main">
                        <div className="dashboard-top">
                            <div> <p className="stock-count" style={{ fontSize: '25px', margin: 0 }}>Devices</p>
                                <h1 style={{ color: 'black', fontSize: '15px' }}>{new Date().toLocaleString('hi-IN')} </h1>
                            </div>
                            <button className="run-btn"><p className="add-text" onClick={() => this.handler()}>Add Device</p></button>

                        </div>
                        <div className="users-bottom">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", width: "500px" }}>
                                {/* <p className="filter-text">Filters</p> */}
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    size={'small'}
                                    options={this.state.TableFilter?.map((item => item?.userName)).reduce((noDupArr, entry) => {
                                        if (noDupArr?.includes(entry)) {
                                            return noDupArr;
                                        } else {
                                            return [...noDupArr, entry];
                                        }
                                    }, []).filter((item => item !== null))}
                                    onChange={(e, v) => this.setState({ locationinput: v === null ? '' : v })}
                                    sx={{ width: 235 }}
                                    renderInput={(params) => <TextField
                                        {...params} label="UserName" />}
                                />
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    size={'small'}
                                    options={this.state.TableFilter?.map((item => item?.deviceUniqueID)).reduce((noDupArr, entry) => {
                                        if (noDupArr?.includes(entry)) {
                                            return noDupArr;
                                        } else {
                                            return [...noDupArr, entry];
                                        }
                                    }, []).filter((item => item !== null && item !== undefined))}
                                    onChange={(e, v) => this.setState({ deviceIDname: v === null ? '' : v })}
                                    sx={{ width: 235 }}
                                    renderInput={(params) => <TextField
                                        {...params} label="Device ID" />}
                                />
                            </div>


                            <button className="run-btn"><p className="run-text" onClick={() => this.searchHandler()}>Search</p></button>






                        </div>
                        <div style={{ paddingTop: "2%" }}>
                            <PaginatedItemsOffline headers={headers} editHandler={(item) => this.editHandler(item)} deleteHandler={(item) => this.setState({deleteModal: item._id})} Action QRCode modalopen={(item) => this.modalopen(item)} colNames={colNames} itemsPerPage={this.state.itemsPerPage} data={this.state.TableFilter} />
                        </div>
                    </div>
                </div>


            </>
        )
    }
}


export default withRouter(Devices);