import React, { Component } from "react";

import print from "../../assets/print.png"
import zoom from "../../assets/zoom.png"
import download from "../../assets/download.png"
import MyInput from "../../components/MyInput";
import Dropdown from "../../components/Dropdown";
import DeviceDropDown from "../../components/DeviceDropDown";
import { addDevices, adduserPost, editdevice, editUser, getRoles, getStores } from "../../Path/Path";
import { withRouter } from "../../components/withRouter";
import { v4 as uuidv4 } from 'uuid';
import { toast } from "react-toastify"
import Loader from "../../components/Loader";
import { Autocomplete, TextField } from '@mui/material';
class AddDevice extends Component {
    constructor(props) {
        super(props)

        this.state = {
            server: "",
            username: "",
            password: "",
            deviceuniqueid: "",
            status: "",
            storeName: "",
            role: [],
            slectrole: "",
            store: [],
            storeNameList: [],
            storeName: "",
            dropStore: "",
            dropRole: "",
            selectstore: "",
            data: [],
            active: "",
            showStatus: false,
            showRole: false,
            showStoreId: false,
            loading: false,
            serverProtocol: '',
            flowServerProtocol: '',
            flowServerIp: '',
            storeItems:[]
        }
    }

    async componentDidMount() {
        this.setState({ loading: true })
        const store = await getStores();
        const storesName = store.map((elem) => elem.storename)
        this.setState({ storeNameList: storesName, storeItems: store })
        this.setState({ loading: false })
        if (this.props.location.state?.item) {

            const item = this.props.location.state.item
            console.log('item: ', item);
            this.setState({
                username: item.userName,
                server: item.server,
                password: item.password,
                deviceuniqueid: item.deviceUniqueID,
                serverProtocol: item.serverProtocol,
                flowServerProtocol: item.flowServerProtocol,
                flowServerIp: item.flowServerIp,
                // active: item.active,
                active: item?.active === true ? "True" : "false",
                storeName: item?.siteId?.storename,
                store: item.siteId,
                dropStore: item?._id,
            })
        }
    }

    sendHandler = async () => {

        const storeId = this.state.storeItems.find(i => i.storename === this.state.storeName)
        console.log('storeId: ', storeId);
        const body = {
            "userName": this.state.username,
            "password": this.state.password,
            "server": this.state.server,
            "uuid": uuidv4(),
            "active": this.state.active === "true" ? true : false,
            "deviceUniqueID": this.state.deviceuniqueid,
            "siteId": storeId?._id,
            "serverProtocol": this.state.serverProtocol,
            "flowServerProtocol": this.state.flowServerProtocol,
            "flowServerIp": this.state.flowServerIp,
        }
        if (this.state.username === "") {
            toast.warn("Enter User Name")
        }
        else if (this.state.server === "") {
            toast.warn("Enter Server")
        }
        else if (this.state.password === "") {
            toast.warn("Enter Password")
        }
        else if (this.state.deviceuniqueid === "") {
            toast.warn("Enter Device Unique ID")
        }
        else if (this.state.serverProtocol === "") {
            toast.warn("Enter Server Protocol ")
        }
        else if (this.state.flowServerProtocol === "") {
            toast.warn("Enter Flow Server Protocol ")
        }
        else if (this.state.flowServerIp === "") {
            toast.warn("Enter Flow Server Ip")
        }
        else if (this.state.active === "") {
            toast.warn("Select Status")
        }
        else if (this.state.store === "") {
            toast.warn("Select Store")
        }

        else {
            if (this.props.location?.state?.item) {


                await editdevice(body, this.props.location.state.item._id)
                toast.success("Device edited successfully")
                this.props.history("/devices")

            } else {
                await addDevices(body).then((res) => {
                    toast.success("Device added successfully")
                    this.props.history("/devices")
                }).catch((err) => {
                    toast.warn("Something went wrong")
                })
            }
        }






    }

    render() {
        // console.log(this.state.fil._id);

        return (
            <>
                <Loader loading={this.state.loading} />
                <div className="dashboard-main">
                    <div className="dashboard-top">
                        <div> <p className="stock-count">Add Devices  </p></div>
                        <div style={{ display: "flex", width: "13%", justifyContent: "space-between", marginTop: "0.3%" }}>
                            <div >
                                <img src={zoom}></img>
                            </div>
                            <div>
                                <img src={print}></img>
                            </div>
                            <div>
                                <img width={30} height={30} src={download}></img>
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingInline: 20, marginTop: 20, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", flexWrap: 'wrap' }}>
                        {/* <div> <p className="filter-text">Filters</p></div> */}

                        <TextField
                            sx={{ width: 235 }}
                            defaultValue={this.state.username}
                            placeholder="User Name"
                            value={this.state.username}
                            size={'small'}
                            onChange={(data) => this.setState({ username: data.target.value })} id="outlined-basic" label="User Name" variant="outlined" />
                        <TextField
                            sx={{ width: 235 }}
                            defaultValue={this.state.password}
                            placeholder="Password"
                            value={this.state.password}
                            size={'small'}
                            onChange={(data) => this.setState({ password: data.target.value })} id="outlined-basic" label="Password" variant="outlined" />
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            size={'small'}
                            value={this.state.serverProtocol}
                            options={["http", "https"]}
                            onChange={(e, v) => this.setState({ serverProtocol: (v === null || v === undefined) ? '' : v })}
                            sx={{ width: 235 }}
                            renderInput={(params) => <TextField
                                {...params} label="Server Protocol" />}
                        />
                        <TextField
                            sx={{ width: 235 }}
                            defaultValue={this.state.server}
                            value={this.state.server}
                            placeholder="Server IP"
                            size={'small'}
                            onChange={(data) => this.setState({ server: data.target.value })} id="outlined-basic" label="Server IP" variant="outlined" />
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            size={'small'}
                            value={this.state.flowServerProtocol}
                            options={["http", "https"]}
                            onChange={(e, v) => this.setState({ flowServerProtocol: (v === null || v === undefined) ? '' : v })}
                            sx={{ width: 235 }}
                            renderInput={(params) => <TextField
                                {...params} label="Flow Server Protocol" />}
                        />




                    </div>

                    <div style={{ paddingInline: 20, marginTop: 15, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", flexWrap: 'wrap' }}>
                        <TextField
                            sx={{ width: 235 }}
                            defaultValue={this.state.flowServerIp}
                            placeholder="Flow Server IP"
                            value={this.state.flowServerIp}
                            size={'small'}
                            onChange={(data) => this.setState({ flowServerIp: data.target.value })} id="outlined-basic" label="Flow Server IP" variant="outlined" />
                        <TextField
                            sx={{ width: 235 }}
                            defaultValue={this.state.deviceuniqueid}
                            value={this.state.deviceuniqueid}
                            placeholder="Device Unique ID"
                            size={'small'}
                            onChange={(data) => this.setState({ deviceuniqueid: data.target.value })} id="outlined-basic" label="Device Unique ID" variant="outlined" />

                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            size={'small'}
                            value={this.state.active}
                            options={["Active", "Deactive"]}
                            onChange={(e, v) => this.setState({ active: (v === null || v === undefined) ? '' : v })}
                            sx={{ width: 235 }}
                            renderInput={(params) => <TextField
                                {...params} label="Status" />}
                        />
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            size={'small'}
                            value={this.state.storeName}
                            options={this.state.storeItems?.map((item => item?.storename)).reduce((noDupArr, entry) => {
                                if (noDupArr?.includes(entry)) {
                                    return noDupArr;
                                } else {
                                    return [...noDupArr, entry];
                                }
                            }, []).filter((item => item !== null && item !== undefined ))}
                            onChange={(e, v) => this.setState({ storeName: v === null ? '' : v })}
                            sx={{ width: 235 }}
                            renderInput={(params) => <TextField
                                {...params} label="Stores" />}
                        />

                    </div>
                    <div style={{ paddingLeft: 20, marginTop: 20, marginBottom: 20 }} >
                        <button className="run-btn"><p className="run-text" onClick={() => this.sendHandler()}>Add Device</p></button>
                    </div>
                </div>

            </>
        )
    }
}

export default withRouter(AddDevice);