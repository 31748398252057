import { TextField } from "@mui/material";
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { GrDocumentCsv } from "react-icons/gr";
import Loader from "../components/Loader";
import { PaginatedItemsOffline } from "../components/PaginatedOffline";
import { withRouter } from "../components/withRouter";
class CountDetails extends Component {
  state = {
    data1: [],
    item: [],
    operationItems: [
      {
        Store: "00125",
        Department: "750",
        Date: "22-09-13",
        Accuracy: "0.00",
        UIA: "0.00",
        Expected: "39",
        Counted: "0",
        Overs: "0",
        Unders: "-0.39",
        ExpectedSF: "0",
        ExpectedSR: "0",
        Department: "perfume",
        Brand: "136-DG Men",
        Diff: "13",
      },
    ],
    loading: false,
    SKU_Over: "",
    SKU_Real: "",
    ItemBarCode: "",
    SKU_Stock: "",
    data: "",
    storeName: [],
    dropdata: "",
    filter: [],
    mydate: "",
    visible: false,
    itemsPerPage: 10,
    csvDataReal: [],
    csvDataOvers: [],
    csvDataStock: [],
    csvDataRealFake: [],
    csvDataOversFake: [],
    csvDataStockFake: [],
    stats: {
      groupedSKU: [],
      dataOrderWise: [],
      uniqueArray: [],
    },
  };
  searchDataReal = () => {
  const { heading } = this.props;
  const { SKU_Real, csvDataRealFake } = this.state;

  if (!SKU_Real) {
    this.setState({ csvDataReal: csvDataRealFake });
    return;
  }

  let filterArray = [];

  if (heading === "Zero Stock / No Soh") {
    filterArray = csvDataRealFake.filter(
      (item) => item?.SKU_Real === SKU_Real || item?.ItemBarCode === SKU_Real
    );
  } else {
    filterArray = csvDataRealFake.filter(
      (item) => item?.SKU === SKU_Real || item?.ItemBarCode === SKU_Real
    );
  }

  this.setState({ csvDataReal: filterArray });
};

  async componentDidMount() {
    this.setState({ loading: true });
    const getDescripency = (await this.props?.data) || [];

    this.setState({
      csvDataReal: getDescripency,
      csvDataRealFake: getDescripency,
    });
    this.setState({ loading: false });
  }
  replaceAll = (str, find, replace) => {
    var escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return str.replace(new RegExp(escapedFind, "g"), replace);
  };
  render() {
    const headers =
      this.props?.heading === "Unders"
        ? [
            "SOHDate",
            "UniqueID",
            "ItemBarCode",
            "StoreID",
            "Expected",
            "unders",
            "matched",
            "scanned",
            "Site",
            "Site_Desc",
            "Article",
          ]
        : this.props?.heading === "Overs"
        ? [
            "SOHDate",
            "UniqueID",
            "ItemBarCode",
            "StoreID",
            "Expected",
            "overs",
            "matched",
            "Scanned",
            "Site",
            "Site_Desc",
            "Article",
          ]
        : this.props?.heading === "Out Of Stock"
        ? [
            "SOHDate",
            "UniqueID",
            "ItemBarCode",
            "StoreID",
            "Expected",
            "unders",
            "matched",
            "Site",
            "Site_Des",
            "Article",
          ]
        : this.props?.heading === "On Hand"
        ? [
            "SOHDate",
            "UniqueID",
            "ItemBarCode",
            "StoreID",
            "Expected",
            "overs",
            "unders",
            "matched",
            "Scanned",
            "Site",
            "Site_Desc",
            "Article",
          ]
        : this.props?.heading === "Zero Stock / No Soh"
        ? ["SKU", "Qty."]
        : "";

    const colNames =
      this.props?.heading === "Unders"
        ? [
            "SOHDate",
            "UniqueID",
            "ItemBarCode",
            "StoreID",
            "Expected",
            "unders",
            "matched",
            "scanned",
            "Site",
            "Site_Desc",
            "Article",
          ]
        : this.props?.heading === "Overs"
        ? [
            "SOHDate",
            "UniqueID",
            "ItemBarCode",
            "StoreID",
            "Expected",
            "overs",
            "matched",
            "Scanned",
            "Site",
            "Site_Desc",
            "Article",
          ]
        : this.props?.heading === "Out Of Stock"
        ? [
            "SOHDate",
            "UniqueID",
            "ItemBarCode",
            "StoreID",
            "Expected",
            "unders",
            "matched",
            "Site",
            "Site_Desc",
            "Article",
          ]
        : this.props?.heading === "On Hand"
        ? [
            "SOHDate",
            "UniqueID",
            "ItemBarCode",
            "StoreID",
            "Expected",
            "overs",
            "unders",
            "matched",
            "Scanned",
            "Site",
            "Site_Desc",
            "Article",
          ]
        : this.props?.heading === "Zero Stock / No Soh"
        ? ["SKU_Original", "SOHQTY"]
        : [];

    const csvDataReal =
      this.state.csvDataReal.map((item) => {
        return {
          ...item,
          unders: item.SOHQTY - item?.matched,
          Scanned: item?.SOHQTY + item?.overs,
          barcode: item?.SKU,
          Expected: item?.SOHQTY,
          UniqueID: item?.UniqueID,
        };
      }) || [];
    const csvDataOvers =
      this.state.csvDataReal.map((item) => {
        return { ...item, Expected: item?.SOHQTY };
      }) || [];
    const csvDataStock =
      this.state.csvDataStock.map((item) => {
        return { ...item, Expected: item?.SOHQTY };
      }) || [];
    return (
      <>
        <Loader loading={this.state.loading} />
        <div>
          <div className="dashboard-main">
            <div className="dashboard-top">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <p
                  className="stock-count"
                  style={{ fontSize: "25px", margin: 0 }}
                >
                  {this.props?.heading}
                </p>

                <CSVLink
                  filename={this.props?.heading}
                  data={csvDataReal}
                  headers={colNames}
                >
                  <GrDocumentCsv
                    color="white"
                    fill="white"
                    fontSize={25}
                    style={{ marginRight: 5, cursor: "pointer" }}
                  />
                </CSVLink>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                gridColumnGap: "20px",
                justifyContent: "space-between",
                flexWrap: "wrap",
                marginTop: 20,
                marginBottom: 20,
                padding: "15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gridColumnGap: "10px",
                  flexWrap: "wrap",
                }}
              >
                <TextField
                  sx={{ width: 235 }}
                  defaultValue={this.state.SKU_Real || this.state.ItemBarCode}
                  value={this.state.SKU_Real}
                  placeholder={
                    this.props?.heading === "Zero Stock / No Soh"
                      ? "SKU"
                      : "Barcode"
                  }
                  size={"small"}
                  onChange={(data) =>
                    this.setState(
                      { SKU_Real: data.target.value } || {
                        ItemBarCode: data.target.value,
                      }
                    )
                  }
                  id="outlined-basic"
                  label={
                    this.props?.heading === "Zero Stock / No Soh"
                      ? "SKU"
                      : "Barcode"
                  }
                  variant="outlined"
                />
              </div>

              <button
                onClick={
                  () => this.searchDataReal()
                  // fetchDataWithParams()
                }
                className="run-btn"
              >
                <p className="run-text">Search</p>
              </button>
            </div>

            <div style={{ height: "72vh", overflowY: "scroll" }}>
              <PaginatedItemsOffline
                noAction
                headers={headers}
                colNames={colNames}
                itemsPerPage={15}
                data={this.state.csvDataReal.map((item) => {
                  return {
                    ...item,
                    unders: item.SOHQTY - item?.matched,
                    Scanned: item?.SOHQTY + item?.overs,
                    barcode: item?.SKU,
                    Expected: item?.SOHQTY,
                  };
                })}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(CountDetails);
