import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { GrDocumentCsv } from "react-icons/gr";
import Loader from "../components/Loader";
import { PaginatedItems } from "../components/Paginated";
import PoquantityDetails from "./POQuantityDetails";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { createRoot } from "react-dom/client";
import { Link } from "react-router-dom";
import { getTO, localServer } from "../Path/Path";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "./common/capitalizeFirstLetter";
import { formatItem } from "./common/FormatItems";
import { HeadAndRowTO } from "../components/Helper";
import TableModal from "../components/TableModal";

const TransferOrder = () => {
  const [printingItems, setPrintingItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [toDate, setToDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [obdNumber, setObdNumber] = useState("");
  const [paginationData, setPaginationData] = useState({});
  const navigate = useNavigate();
    const [showDetailModal, setShowDetailModal] = useState(null);
    const [status, setStatus] = useState("");

  useEffect(() => {
    getPrintedItems();
  }, []);
  const validateDate = (date) => {
    const dateFormat = "MM/DD/YYYY";
    if (!date) {
      return true;
    }
    if (!moment(date, dateFormat, true).isValid()) {
      // toast.error("Invalid date format.");
      return false;
    }
    if (moment(date).isAfter(moment())) {
      return false;
    }
    return true;
  };

  const getPrintedItems = async (pageNumber = 1) => {
    const newFromDate = validateDate(fromDate);
    const newToDate = validateDate(toDate);
    if (!newFromDate || !newToDate) {
      toast.error("Please enter valid date ranges.");
      return;
    }
    setLoading(true);
    try {
      const params = {
        page: pageNumber,
        OBD_No: obdNumber,
        startDate: fromDate
          ? moment(fromDate).startOf("day").format("YYYY-MM-DD")
          : "",
        endDate: toDate ? moment(toDate).endOf("day").format("YYYY-MM-DD") : "",
      };
      const data = await getTO(params);

      data.data.data.forEach(element => {
        const createdItemsCount = element.items.filter((items) => items.process_status === "created").length
        element.Items = createdItemsCount
      });

      if (data && Array.isArray(data.data.data)) {
        const filtering = data?.data?.data;
        setPrintingItems(filtering);
        setPaginationData(data?.data);
        setCurrentPage(data?.page);
        setTotalDocuments(data?.total);
        setItemsPerPage(data?.data?.size);
        setLoading(false);
        if (data?.data?.data?.length === 0) {
          toast.error("No data found.");
        } else {
          setCurrentPage(data.data.page);
          setTotalDocuments(data.data.total);
          setItemsPerPage(10);
        }
      } else {
        console.error("Unexpected API response structure:", data);
      }
    } catch (err) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

const onGetData = (e, value,filter) => {
  e.preventDefault();
  setLoading(true);
  try {
    const response = paginationData.data;
    const document = response.find((doc) => doc.OBD_No === value);
    if (document) {
      let items = document.items;
      console.log("here is items",items)
      if(filter)
      {
        console.log("enter in if")
        const filteredItems = items.filter((item) => item.process_status === filter);
        console.log("filtered itens",items)
        items = filteredItems
      }
      if (items.length > 0) {
        // navigate("/transferOrderItems", {
        //   state: { item: items , filter},
        // });
        setShowDetailModal(items)
        setStatus(filter)
      } else {
        toast.error("No items available.");
      }
    } else {
      toast.error("No matching document found.");
    }
  } catch (error) {
    console.error("Error in onGetData:", error);
    toast.error("An error occurred while fetching data.");
  } finally {
    setLoading(false);
  }
};

  const getCSV = async () => {
    const newFromDate = validateDate(fromDate);
    const newToDate = validateDate(toDate);
    if (!newFromDate || !newToDate) {
      toast.error("Please enter valid date ranges.");
      return;
    }

    setLoading(true);
    try {
      const params = {
        OBD_No: obdNumber,
        startDate: fromDate
          ? moment(fromDate).startOf("day").toISOString()
          : "",
        endDate: toDate ? moment(toDate).endOf("day").toISOString() : "",
      };
      const response = await axios.get(`${localServer}store/TO/csv`, {
        params,
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Transfer Order.csv");
      document.body.appendChild(link);

      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
    setLoading(false);
  };

  const headers = [
    "Created Time",
    "OBD Number",
    "Delivery Type",
    "OBD Destination Site",
    "Items",
    "Device ID",
    "Default Items",
    "Packed Qty.",
    "Shipped Qty.",
    "Received Qty.",
    "Packing Remarks",
    "Shipping Remarks",
    "Receiving Remarks",
    "Packing User",
    "Shipping User",
    "Receiving User",
    "Add Char 1",
    "Add Char 2",
    "Add Char 3",
    "Add Char 4",
	"Status",
	"Confirmation Status",
  ];

  const colNames = [
    "createdAt",
    "OBD_No",
    "Deliv_Type",
    "OBD_Destination_Site",
    "Items",
    "deviceId",
    "default_items",
    "packed_items",
    "shipped_items",
    "received_items",
    "packing_remarks",
    "shipping_remarks",
    "receiving_remarks",
    "PackingUser",
    "ShippingUser",
    "ReceivingUser",
    "Add_Char1",
    "Add_Char2",
    "Add_Char3",
    "Add_Char4",
    "status",
    "confirmation_status",
  ];

    	const startItem = currentPage ? (currentPage - 1) * itemsPerPage + 1 : 0;
      const endItem = currentPage
        ? Math.min(currentPage * itemsPerPage, totalDocuments)
        : 0;

  return (
    <React.Fragment>
      <Loader loading={loading} />
      <div className="dashboard-main">
        <TableModal
          closeHandler={() => setShowDetailModal(null)}
          modalOpen={!!showDetailModal}
          HeadAndRow={HeadAndRowTO.filter((f) => {
            if (status === "created") {
              return (
                f.header !== "Receiving Process Qty." &&
                f.header !== "Receiving Date" &&
                f.header !== "Packing Process Qty." &&
                f.header !== "Packing Date" &&
                f.header !== "Shipping Process Qty." &&
                f.header !== "Shipping Date"
              );
            } else if (status === "packed") {
              return (
                f.header !== "Receiving Process Qty." &&
                f.header !== "Receiving Date" &&
                // f.header !== "Packing Process Qty." &&
                // f.header !== "Packing Date" &&
                f.header !== "Shipping Process Qty." &&
                f.header !== "Shipping Date"
              );
            } else if (status === "received") {
              return (
                // f.header !== "Receiving Process Qty." &&
                // f.header !== "Receiving Date" &&
                f.header !== "Packing Process Qty." &&
                f.header !== "Packing Date" &&
                f.header !== "Shipping Process Qty." &&
                f.header !== "Shipping Date"
              );
            } else if (status === "shipped") {
              return (
                f.header !== "Receiving Process Qty." &&
                f.header !== "Receiving Date" &&
                f.header !== "Packing Process Qty." &&
                f.header !== "Packing Date"
                // f.header !== "Shipping Process Qty." &&
                // f.header !== "Shipping Date"
              );
            } else {
              return true;
            }
          })}
          data={showDetailModal}
          links={["Barcode", "PO_Article_GTIN"]}
          tableTitle="Transfer Order Item Details"
        />
        <div
          className="dashboard-top"
          style={{ display: "flex", flexDirection: "column", padding: "10px" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                flex: "1",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p
                className="stock-count"
                style={{ fontSize: "25px", margin: 0 }}
              >
                {" "}
                Transfer Order{" "}
              </p>
            </div>
            <GrDocumentCsv
              onClick={getCSV}
              color="black"
              fill="white"
              fontSize={25}
              style={{ marginRight: 5, cursor: "pointer" }}
            />
          </div>
          <div>
            <h1 style={{ color: "black", fontSize: "15px" }}>
              {new Date().toLocaleString("hi-IN")}
            </h1>
          </div>
        </div>
        <div className="dashboard-bottom" style={{ marginTop: "0.5%" }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              gridColumnGap: "20px",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                gridColumnGap: "10px",
                gridRowGap: "10px",
                flexWrap: "wrap",
              }}
            >
              <DesktopDatePicker
                label="From Date"
                clearable
                showTodayButton
                inputFormat="MM/DD/YYYY"
                size={"small"}
                maxDate={new Date()}
                value={fromDate}
                onChange={(data) => setFromDate(data)}
                componentsProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                renderInput={(params) => (
                  <TextField sx={{ width: 170 }} size={"small"} {...params} />
                )}
              />
              <DesktopDatePicker
                label="To Date"
                clearable
                showTodayButton
                inputFormat="MM/DD/YYYY"
                size={"small"}
                maxDate={new Date()}
                value={toDate}
                onChange={(data) => setToDate(data)}
                componentsProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                renderInput={(params) => (
                  <TextField sx={{ width: 170 }} size={"small"} {...params} />
                )}
              />
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                freeSolo={true}
                size={"small"}
                options={printingItems
                  ?.filter((item) => item?.OBD_No !== "" && item?.OBD_No !== undefined)
                  .map((item) => item?.OBD_No)
                  .reduce((noDupArr, entry) => {
                    if (noDupArr.includes(entry)) {
                      return noDupArr;
                    } else {
                      return [...noDupArr, entry];
                    }
                  }, [])}
                onChange={(e, v) => setObdNumber(v === null ? "" : v)}
                onInputChange={(e) =>
                  setObdNumber(
                    e.target?.value === 0 || e.target?.value === undefined
                      ? ""
                      : e.target?.value
                  )
                }
                sx={{ width: 170 }}
                renderInput={(params) => (
                  <TextField {...params} label="OBD Number" />
                )}
              />
            </div>
            <button onClick={() => getPrintedItems()} className="run-btn">
              <p className="run-text">Search</p>
            </button>
          </div>
          <div
            style={{ marginTop: "10px", overflowX: "scroll", height: "600px" }}
          >
            <PaginatedItems
              pageCountNew={paginationData?.next + paginationData?.previous + 1}
              getData={(pageNumber) => getPrintedItems(pageNumber)}
              totalLength={paginationData?.next}
              itemsPerPage={10}
              headers={headers}
              colNames={colNames}
              forcePage={paginationData?.previous || 0}
              data={printingItems.map((item) => ({
                ...formatItem(item),
                createdAt: moment(item.createdAt).format(
                  "MM/DD/YYYY hh:mm:ss A"
                ),
                OBD_No: item.OBD_No ? (
                  <Link to="#" onClick={(e) => onGetData(e, item.OBD_No, null)}>
                    {item.OBD_No}
                  </Link>
                ) : (
                  item.OBD_No || "--"
                ),
                default_items: item.default_items ? (
                  <Link
                    to="#"
                    onClick={(e) => onGetData(e, item.OBD_No, "created")}
                  >
                    {item.default_items}
                  </Link>
                ) : (
                  item.default_items || "--"
                ),
                packed_items:
                  item.status !== "created" &&
                  item.status !== "Created" &&
                  item.packed_items ? (
                    <Link
                      to="#"
                      onClick={(e) => onGetData(e, item.OBD_No, "packed")}
                    >
                      {item.packed_items}
                    </Link>
                  ) : (
                    item.packed_items || "--"
                  ),
                shipped_items:
                  item.status !== "created" &&
                  item.status !== "Created" &&
                  item.shipped_items ? (
                    <Link
                      to="#"
                      onClick={(e) => onGetData(e, item.OBD_No, "shipped")}
                    >
                      {item.shipped_items}
                    </Link>
                  ) : (
                    item.shipped_items || "--"
                  ),
                received_items:
                  item.status !== "created" &&
                  item.status !== "Created" &&
                  item.received_items ? (
                    <Link
                      to="#"
                      onClick={(e) => onGetData(e, item.OBD_No, "received")}
                    >
                      {item.received_items}
                    </Link>
                  ) : (
                    item.received_items || "--"
                  ),
                PO_Status: capitalizeFirstLetter(item.PO_Status),
                status: capitalizeFirstLetter(item.status),
              }))}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
              color: "white",
              padding: "10px",
              gap: "10px",
            }}
          >
            <h5>ROWS PER PAGE: 10</h5>
            <h5>{`${startItem}-${endItem} of ${totalDocuments}`}</h5>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TransferOrder;
