import { Autocomplete, createTheme, TextField, ThemeProvider } from "@mui/material";
import axios from "axios";
import jwtDecode from "jwt-decode";
import React, { Component } from "react";
import { toast } from "react-toastify";
import shirt from "../../assets/shirt.png";
import { encode } from "../../components/EncodeTag";
import Loader from "../../components/Loader";
import { Print_Service } from "../../components/printService";
import { withRouter } from "../../components/withRouter";
import ZplModal from "../../components/ZplModal";
import { getItemMaster, getStores, localServer, Path, PathCustom } from "../../Path/Path";
import PrinterList from "./PrinterList";

class Tagit extends Component {
    state = {
        ItemMaster: [],
        epc: null,
        stores: [],
        zplList: [],
        store: '',
        zpl: '',
        modalOpen: false,
        poNumber: '',
        supplierNo: '',
        shippment: '',
        comments: '',
        specificVal: '',
        userName: '',
        qty: '',
        zplData: null,
        storeData: null,
        uuid: '',
        loading: false,
        printer: null,
        printerVal: '',
        printerCount: 1,
    }



    storeHandler = (data) => {
        this.setState({ store: data?.storename })
        this.setState({ storeData: data });
        console.log(data);
    }
    printHandler = (data) => {
        this.setState({ printer: data })
        this.setState({ printerVal: data?.name })
        // setDevices(deviceListFilter.filter((item => item.name === data.name)))
        // console.log(deviceListFilter.filter((item => item.name === data.name)));
        console.log(data);
    }
    zplHandler = (data) => {
        this.setState({ zpl: data?.zplName })
        this.setState({ zplData: data });

    }
    fetchData = async () => {
        this.setState({ loading: true })

        this.setState({ loading: false })
    }
    async componentDidMount() {

        const data = await localStorage.getItem('loginData')
        const parseData = await JSON.parse(data)
        const token = await parseData.token
        if (token) {
            const decodetoken = await jwtDecode(token)
            await this.setState({ userName: decodetoken?.payload?.userName })
        }

        await getStores().then((async res => {
            console.log(res);
            await this.setState({ stores: res })
        }))
            .catch((err => {
                console.log(err);
            }))
        await axios.get(`${localServer}store/zpl/get`)
            .then((async res => {
                await this.setState({ zplList: res.data })
            }))
            .catch((err => {
                console.log(err);
            }))
    }
    checkSkuCode = async (e) => {
        await this.setState({ uuid: e.target.value })
        if (this.state.uuid.length < 5) {
            console.log('not greater than 7');
        } else {
            this.setState({ loading: true })
            // this.setState({ uuid: e.target.value })






            // window.location.reload()



            await this.setState({ uuid: e.target.value })
            await getItemMaster(this.state.uuid).then((async res => {
                console.log(res);
                await this.setState({ ItemMaster: res.data.data })


                let leCo = e.target.value?.trim().length

                // Saqib's remarkable contribution


                this.setState({ loading: false })
            }))
                .catch((err => {
                    console.log(err);
                    this.setState({ loading: false })
                }))


        }
    }
    printData = async () => {
        const loopValue = Number(this.state.printerCount)
        console.log(loopValue);
        if (this.state.uuid === '') {
            toast.error("Please Enter UUID ")
        } else if (this.state.ItemMaster.length === 0) {
            toast.error("Sku does exists in Item Master")
        } else if (this.state.zplData === null) {
            toast.error("Please Select Zpl ")
        }
        // else if (this.state.storeData === null) {
        //     toast.error("Please Select Store ")
        // }
        else if (loopValue === 0) {
            toast.error("Please Enter Printer Count")
        } else if (this.state.printer === null) {
            toast.error("Please Select Printer")
        }
        else {
            this.setState({ loading: true })
            let epcs = []
            let leCo = this.state.uuid?.trim().length
            // Saqib's remarkable contribution
            var skuEdited = ''
            skuEdited = this.state.uuid
            if (leCo < 14) {
                for (var i = 0; i < (14 - leCo); i++) {
                    skuEdited = "0" + this.state.uuid
                }
            }
            const gettingEPCS = await axios.post(`${localServer}store/seq/sequence-new?type=DEV&increment=${loopValue}&sku=${skuEdited}`)
            if (gettingEPCS?.data.success === false) {
                toast.error("Something Went Wrong")
            } else {
                epcs = await gettingEPCS?.data?.data
                for (let index = 0; index < epcs?.length; index++) {
                    console.log(`${index} is printed `)
                    let leCo = this.state.uuid?.trim().length
                    // Saqib's remarkable contribution
                    var skuEdited = ''
                    skuEdited = this.state.uuid

                    if (leCo < 14) {
                        for (var i = 0; i < (14 - leCo); i++) {
                            skuEdited = "0" + this.state.uuid
                        }
                    }
                    console.log(skuEdited, 'skuEdited');
                    const epc = epcs[index]
                    await this.setState({ epc: epc })
                    if (this.state.ItemMaster.length !== 0) {
                        function replaceAll(str, find, replace) {
                            var escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
                            return str.replace(new RegExp(escapedFind, 'g'), replace);
                        }
                        var replacingVars24 = await replaceAll(this.state.zplData?.zplBody, "${serialNumber}", epc);
                        var replacingVars25 = await replaceAll(replacingVars24, "${Date}", new Date());
                        var replacingVars26 = await replaceAll(replacingVars25, "${UniqueID}", this.state.specificVal);
                        var replacingVars27 = await replaceAll(replacingVars26, "${CategoryFriendlyName}", this.state.ItemMaster[0]?.CategoryFriendlyName?.join("|"));
                        var replacingVars28 = await replaceAll(replacingVars27, "${ItemBarCode}", this.state.ItemMaster[0]?.ItemBarCode);
                        var replacingVars29 = await replaceAll(replacingVars28, "${ItemId}", this.state.ItemMaster[0]?.ItemId);
                        var replacingVars30 = await replaceAll(replacingVars29, "${MafAttrBrandOne}", this.state.ItemMaster[0]?.MafAttrBrandOne);
                        var replacingVars31 = await replaceAll(replacingVars30, "${MafAttrBrandSeason}", this.state.ItemMaster[0]?.MafAttrBrandSeason);
                        var replacingVars32 = await replaceAll(replacingVars31, "${brand_description}", this.state.ItemMaster[0]?.brand_description);
                        var replacingVars33 = await replaceAll(replacingVars32, "${MafDescription}", this.state.ItemMaster[0]?.MafDescription);
                        var replacingVars34 = await replaceAll(replacingVars33, "${MafInventColorId}", this.state.ItemMaster[0]?.MafInventColorId);
                        var replacingVars35 = await replaceAll(replacingVars34, "${MafInventSizeId}", this.state.ItemMaster[0]?.MafInventSizeId);
                        var replacingVars36 = await replaceAll(replacingVars35, "${OrigCountryRegionId}", this.state.ItemMaster[0]?.OrigCountryRegionId);
                        var replacingVars37 = await replaceAll(replacingVars36, "${OriginalSellingPrice}", this.state.ItemMaster[0]?.OriginalSellingPrice);
                        var replacingVars38 = await replaceAll(replacingVars37, "${SAB_ProductName}", this.state.ItemMaster[0]?.SAB_ProductName);
                        var replacingVars39 = await replaceAll(replacingVars38, "${SenderId}", this.state.ItemMaster[0]?.SenderId);
                        var replacingVars40 = await replaceAll(replacingVars39, "${TextValue}", this.state.ItemMaster[0]?.TextValue?.join("|"));
                        var replacingVars41 = await replaceAll(replacingVars40, "${PurchaseOrganization}", this.state.ItemMaster[0]?.PurchaseOrganization);
                        var replacingVars42 = await replaceAll(replacingVars41, "${barcode}", this.state.ItemMaster[0]?.barcode);
                        var replacingVars43 = await replaceAll(replacingVars42, "${SupplierCode}", this.state.ItemMaster[0]?.SupplierCode);
                        var replacingVars44 = await replaceAll(replacingVars43, "${SupplierName}", this.state.ItemMaster[0]?.SupplierName);

                        var lastVal = await replacingVars44

                        console.log(replacingVars27, 'replacingVars26');
                        let networkCallData = lastVal
                        console.log(epc, '${serialNumber}');
                        let secondBody = {
                            "group": ">VIRGIN",
                            "thingTypeCode": "ITEM",
                            "serialNumber": epc,
                            "udfs": {
                                "Retail_Bizlocation": {
                                    "value": this.state.store ? this.state.store : ''
                                },
                                "deviceId": {
                                    "value": this.state.specificVal ? this.state.specificVal : ''
                                },
                                "sourceModule": {
                                    "value": "Printing"
                                },
                                "Retail_Printer": {
                                    "value": "PrinterID"
                                },
                                "Retail_Product_SKU": {
                                    "value": this.state.ItemMaster[0]?.barcode ? this.state.ItemMaster[0]?.barcode : ''
                                },
                                "Retail_Product_SKUOriginal": { "value": this.state.ItemMaster[0]?.article_number ? this.state.ItemMaster[0]?.article_number : '' },
                                "Retail_Product_UniqueID": {
                                    "value": this.state.specificVal ? this.state.specificVal : ''
                                },
                                "Retail_Product_UPC": {
                                    "value": this.state.ItemMaster[0]?.article_number ? this.state.ItemMaster[0]?.article_number : ''
                                },
                                "Retail_ZPL": {
                                    "value": "VIRGINzpl_EN"
                                },
                                "source": {
                                    "value": "PRINTING_APP"
                                },
                                "user": {
                                    "value": this.state.storeData?.storename ? this.state.storeData?.storename : ''
                                },
                                "zone": {
                                    "value": "0000405.00101.1"
                                },
                                "po_number": {
                                    "value": this.state.poNumber ? this.state.poNumber : ''
                                },
                                "comments": {
                                    "value": ""
                                },
                                "invoice_number": {
                                    "value": ""
                                },
                                "username": this.state.userName
                            },
                        }
                        let body = {
                            "apiVersion": "2.0",
                            "group": "LULU_LEMON",
                            "user": "LULU_LEMON",
                            "location": "MOE",
                            "thingTypeCode": "ITEM",
                            "tagId": epc,
                            "barcode": this.state.ItemMaster[0]?.ItemBarCode,
                            "articleNumber": this.state.ItemMaster[0]?.article_number,
                            "invoiceNumber": "",
                            "deviceId": "",
                            "sourceModule": "Printing",
                            "printerId": "PR-001",
                            "zpl": this.state.zplData?.zplName,
                            "source": "Printing_APP",
                            "zone": "zone_1",
                            "userName": this.state.userName,
                            "comment": "New comment",
                            "TextValue": this.state.ItemMaster[0]?.TextValue?.join("|"),
                            "FriendlyName": this.state.ItemMaster[0]?.FriendlyName?.join("|"),
                            "CategoryFriendlyName": this.state.ItemMaster[0]?.CategoryFriendlyName?.join("|"),
                            "ItemBarCode": this.state.ItemMaster[0]?.ItemBarCode,
                            "ItemId": this.state.ItemMaster[0]?.ItemId,
                            "MafAttrBrandOne": this.state.ItemMaster[0]?.MafAttrBrandOne,
                            "MafAttrBrandSeason": this.state.ItemMaster[0]?.MafAttrBrandSeason,
                            "MafDescription": this.state.ItemMaster[0]?.MafDescription,
                            "MafInventColorId": this.state.ItemMaster[0]?.MafInventColorId,
                            "MafInventSizeId": this.state.ItemMaster[0]?.MafInventSizeId,
                            "OrigCountryRegionId": this.state.ItemMaster[0]?.OrigCountryRegionId,
                            "RetailSeasonCode": this.state.ItemMaster[0]?.RetailSeasonCode,
                            "SAB_ProductName": this.state.ItemMaster[0]?.SAB_ProductName,
                            "SenderId": this.state.ItemMaster[0]?.SenderId,
                            "OriginalSellingPrice": this.state.ItemMaster[0]?.OriginalSellingPrice
                        }
                        // let sendingPrintingVal = await postPrintingPo([secondBody])
                        console.log(body);
                        let sendingPrintingVal2 = await axios.post(`${PathCustom}store/printed-items`, body)
                        // \\\testsig
                        Print_Service.print(this.state.printer, replacingVars44)
                        console.log(index, 'index');


                    } else {
                        toast.error("Sku code is not exist or already print ")
                    }

                }
            }
            await this.setState({ details: {}, uuid: '', ItemMaster: [] })
            this.setState({ loading: false })
        }

        // }
    }

    render() {

        const darkTheme = createTheme({
            palette: {
                mode: 'light',
            },
        });
        const details = this.state.ItemMaster[0]
        return (
            <React.Fragment>
                <Loader loading={this.state.loading} />
                <ThemeProvider theme={darkTheme} >

                    <iframe id="printf" name="printf" style={{ display: 'none' }} ></iframe>
                    <ZplModal Heading={'Details'} height={'100px'} onClick={() => this.setState({ modalOpen: false })} open={this.state.modalOpen} >
                        <div style={{ display: 'flex', width: '100%', gridColumnGap: '10px', }}>
                            <TextField
                                sx={{ width: 200 }}
                                defaultValue={this.state.poNumber}
                                value={this.state.poNumber}
                                placeholder={this.state.poNumber}
                                onChange={(e) => this.setState({ poNumber: e.target.value })}
                                // InputLabelProps={{ shrink: true }}
                                size={'small'}
                                // disabled
                                id="outlined-basic" label="PO #" variant="outlined" />
                            <TextField
                                sx={{ width: 200 }}
                                // defaultValue={this.state.supplierNo}
                                value={this.state.supplierNo}
                                placeholder={this.state.supplierNo}
                                onChange={(e) => this.setState({ supplierNo: e.target.value })}
                                size={'small'}
                                // disabled
                                id="outlined-basic" label="Supplier ID" variant="outlined" />
                        </div>
                        <div style={{ display: 'flex', width: '100%', gridColumnGap: '10px', marginTop: '20px' }}>
                            <TextField
                                sx={{ width: 200 }}
                                defaultValue={this.state.shippment}
                                value={this.state.shippment}
                                placeholder={this.state.shippment}
                                onChange={(e) => this.setState({ shippment: e.target.value })}
                                size={'small'}
                                // disabled
                                id="outlined-basic" label="Shipment #" variant="outlined" />
                            <TextField
                                sx={{ width: 200 }}
                                defaultValue={this.state.comments}
                                value={this.state.comments}
                                placeholder={this.state.comments}
                                onChange={(e) => this.setState({ comments: e.target.value })}
                                size={'small'}
                                // disabled
                                id="outlined-basic" label="Comments" variant="outlined" />
                        </div>
                    </ZplModal>
                    <div className="admin-main">
                        <div className="dashboard-top">
                            <div> <p className="stock-count">Tag IT</p>
                                <h1 style={{ color: 'black', fontSize: '15px' }}>
                                    {new Date().toLocaleString('hi-IN')}
                                </h1>
                            </div>

                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div className="admin-bottom">
                                <div className="white-box-1">
                                    <div style={{ display: "flex", justifyContent: "space-between", padding: "3%" }}>
                                        <div style={{ display: "flex", alignItems: 'center' }}>
                                            <p className="total-score-tag">Total Stores</p>
                                            <input style={{ margin: '2px 0 0 5px' }} type="checkbox" size={40}></input>
                                        </div>
                                        <div>
                                            <button onClick={() => this.setState({ modalOpen: true })} className="detail-btn"><p className="detail-text-tag">Details</p></button>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "3%", paddingRight: "3%" }}>
                                        <div style={{ width: '100%', display:"flex", justifyContent:"center" }}>
                                            
                                            <div style={{ width: '100%' }}>
                                                <TextField
                                                    sx={{ width: '100%', marginTop:"20px" }}
                                                    defaultValue={this.state.uuid}
                                                    value={this.state.uuid}
                                                    placeholder={this.state.uuid}

                                                    onChange={(e) => this.checkSkuCode(e)}
                                                    size={'small'}
                                                    id="outlined-basic" label="UUID" variant="outlined" />
                                            </div>
                                        </div>
                                        {/* <div> */}
                                        {/* <div>
                                                <p style={{ color: "black" }}>QTY</p>
                                            </div> */}
                                        {/* <div>
                                                <TextField
                                                    sx={{ width: 200 }}
                                                    defaultValue={this.state.qty}
                                                    value={this.state.qty}
                                                    placeholder={this.state.qty}
                                                    onChange={(e) => this.setState({ qty: e.target.value })}
                                                    size={'small'}
                                                    // disabled
                                                    id="outlined-basic" label="Qty" variant="outlined" />
                                               
                                            </div> */}
                                        {/* </div> */}

                                    </div>
                                    <div style={{display: "flex", flexDirection: "column", marginTop:11, paddingLeft: "3%", paddingRight: "3%" , alignItems:"center"}}>
                                    
                                            <div style={{width:'100%'}}>
                                                <TextField
                                                    sx={{ width: '100%' }}
                                                    value={details?.SAB_ProductName}
                                                    size={'small'}
                                                    // disabled
                                                    id="outlined-basic" label={"SAB Product Name"} variant="outlined" />
                                            </div>
                                        
                                            <div style={{width: "100%"}}>
                                                <TextField
                                                    sx={{ width: "100%", marginTop: "15px" }}
                                                    value={details?.FriendlyName?.join('|')}
                                                    size={'small'}
                                                    id="outlined-basic" label={'Friendly Name'} variant="outlined" />
                                            </div>
                                        
                                            <div style={{width: "100%"}}>
                                                <TextField
                                                    sx={{ width: "100%", marginTop:"15px" }}
                                                    value={details?.MafAttrBrandSeason || ""}
                                                    size={'small'}
                                                    id="outlined-basic" label={'Maf Attr Brand Season'} variant="outlined" />
                                            </div>
                                        
                                            <div style={{width: "100%"}}>
                                                <TextField
                                                    sx={{ width: "100%", marginTop:"15px" }}
                                                    value={details?.RetailSeasonCode || ""}
                                                    size={'small'}
                                                    id="outlined-basic" label={'Retail Season Code'} variant="outlined" />
                                            </div>

                                    </div>
                                    
                                </div>
                            </div>
                            <div className="admin-bottom">
                                <div className="white-box-2">
                                    <div style={{ padding: "3%" }}>
                                        <p className="img-preview">Image priview is not available</p>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <img src={shirt}></img>
                                    </div>
                                    <div style={{ padding: "3%" }}>
                                        <div>
                                            {/* <p style={{ color: "black" }}>EPC</p> */}
                                        </div>
                                        <div>
                                            <TextField
                                                sx={{ width: 300 }}
                                                defaultValue={this.state.epc}
                                                value={this.state.epc}
                                                disabled
                                                placeholder={this.state.epc}

                                                size={'small'}
                                                // disabled
                                                id="outlined-basic" label={this.state.epc !== null ? this.state.epc : 'EPC'} variant="outlined" />
                                            {/* <input disabled className="epc-input" placeholder={this.state.epc || "EPC"}></input> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="admin-bottom">
                                <div className="white-box-3">
                                    <div style={{ padding: "3%" , marginTop:"10px"}}>
                                        {/* <PickerSelect
                                    value={"All Sizes"}
                                /> */}
                                        {/* <DropDownPrinter
                                            value={this.state.store}
                                            list={this.state.stores}
                                            placeholder="Select Store"
                                            blackClass={'drop-input-black'}
                                            onClickList={(data) => this.storeHandler(data)}
                                        /> */}
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            size={'small'}
                                            options={[]}
                                            disabled
                                            getOptionLabel={(option) => 'LULU-LEMON'}
                                            onChange={(e, v) => this.storeHandler(v === null ? null : v)}
                                            sx={{ width: 200 }}
                                            renderInput={(params) => <TextField
                                                {...params} label="LULU-LEMON" />}
                                        />
                                        {/* <DropdownZpl
                                            value={this.state.zpl}
                                            list={this.state.zplList}
                                            blackClass={'drop-input-black'}
                                            placeholder="Select Zpl "
                                            onClickList={(data) => this.zplHandler(data)}
                                        /> */}

                                        <PrinterList
                                            value={this.state.printerVal}
                                            blackClass={'drop-input-black'}
                                            placeholder="Printer"
                                            onClickList={(data) => this.printHandler(data)}
                                        />
                                        <Autocomplete
                                            style={{ marginTop: '20px' }}
                                            disablePortal
                                            id="combo-box-demo"
                                            size={'small'}
                                            options={this.state.zplList}
                                            getOptionLabel={(option) => option?.zplName}
                                            onChange={(e, v) => this.zplHandler(v === null ? null : v)}
                                            sx={{ width: 200 }}
                                            renderInput={(params) => <TextField
                                                {...params} label="Zpl" />}
                                        />
                                        <div>
                                            {/* <input min={1} onChange={(e) => this.setState({ printerCount: e.target.value })} type={'number'} defaultValue={this.state.printerCount || 1} className="epc-input" style={{ color: 'black' }} placeholder={"Printer Count"}></input> */}
                                            <TextField
                                                sx={{ width: 200 }}
                                                style={{ marginTop: '20px' }}
                                                defaultValue={this.state.printerCount}
                                                value={this.state.printerCount}
                                                placeholder={this.state.printerCount}
                                                onChange={(e) => this.setState({ printerCount: e.target.value })}
                                                size={'small'}
                                                // disabled
                                                id="outlined-basic" label={'Printer Count'} variant="outlined" />
                                        </div>
                                        {/* 
                                <PickerSelect
                                    value={"All Sizes"}
                                />
                                <PickerSelect
                                    value={"All Sizes"}
                                /> */}
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", marginTop: "15%", marginBottom: "3%" }}>
                                        <button onClick={() => this.printData()} className="print-btn">
                                            <p className="print-text">Print</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </ThemeProvider>
            </React.Fragment>

        )
    }
}
export default withRouter(Tagit)