import { Autocomplete, TextField } from "@mui/material";
import moment from "moment";
import React, { useState, useEffect } from "react";
import Loader from "../components/Loader";
import { PaginatedItems } from "../components/Paginated";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Link } from "react-router-dom";
import { getPackingDrill, getPO } from "../Path/Path";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "./common/capitalizeFirstLetter";
import { formatItem } from "./common/FormatItems";
import { HeadAndRowDrillDown } from "../components/Helper";
import DrillDownModal from "./DrillDownModal";

const DrillDown = () => {
  const [printingItems, setPrintingItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [toDate, setToDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [poNumber, setPoNumber] = useState("");
  const [paginationData, setPaginationData] = useState({});
  const [showDetailModal, setShowDetailModal] = useState(null);

  useEffect(() => {
    getPrintedItems();
  }, []);
  const validateDate = (date) => {
    const dateFormat = "MM/DD/YYYY";
    if (!date) {
      return true;
    }
    if (!moment(date, dateFormat, true).isValid()) {
      return false;
    }
    if (moment(date).isAfter(moment())) {
      return false;
    }
    return true;
  };

  const getPrintedItems = async (pageNumber = 1) => {
    const newFromDate = validateDate(fromDate);
    const newToDate = validateDate(toDate);
    if (!newFromDate || !newToDate) {
      toast.error("Please enter valid date ranges.");
      return;
    }
    setLoading(true);
    try {
      const params = {
        page: pageNumber,
        PO_Number: poNumber,
        startDate: fromDate
          ? moment(fromDate).startOf("day").format("YYYY-MM-DD")
          : "",
        endDate: toDate ? moment(toDate).endOf("day").format("YYYY-MM-DD") : "",
      };
      const data = await getPO(params);
      data.data.data.forEach((po) => {
        const createdItemsCount = po.items.filter(
          (item) => item.process_status === "created"
        ).length;
        po.PO_Item = createdItemsCount;
      });

      if (data && Array.isArray(data.data.data)) {
        const filtering = data?.data?.data;
        setPrintingItems(filtering);
        setPaginationData(data?.data);
        setCurrentPage(data?.page);
        setTotalDocuments(data.data.total);
        setItemsPerPage(data?.data?.size);
        setLoading(false);
        if (data?.data?.data?.length === 0) {
          toast.error("No data found.");
        } else {
          setCurrentPage(data.data.page);
          setTotalDocuments(data.data.total);
          setItemsPerPage(10);
        }
      } else {
        console.error("Unexpected API response structure:", data);
      }
    } catch (err) {
      toast.error("Something went wrong:");
    } finally {
      setLoading(false);
    }
  };

  const onGetData = async (e, value) => {
      e.preventDefault();
      setLoading(true);
      try {
        console.log({value})
        const params = {
          poNumber: value,
        };
        const response = await getPackingDrill(params);
      if (response.data) {
        let items = response.data.data;
        if (items.length > 0) {
          setShowDetailModal(items);
        } else {
          toast.error("No items available.");
        }
      } else {
        toast.error("No matching document found.");
      }
    } catch (error) {
      console.error("Error in onGetData:", error);
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const headers = [
    "Created Time",
    "PO Doc Type",
    "PO Number",
    "PO Item",
    "PO Vendor",
    "PO Vendor Name",
    "PO Currency Key",
    "PO Action",
    "Device ID",
    "Qty.",
    "PO Status",
    "Status",
  ];
  const colNames = [
    "createdAt",
    "PO_Doc_Type",
    "PO_Number",
    "PO_Item",
    "PO_Vendor",
    "PO_Vendor_Name",
    "PO_Currency_Key",
    "PO_Action",
    "deviceId",
    "default_items",
    "PO_Status",
    "status",
  ];
  const startItem = currentPage ? (currentPage - 1) * itemsPerPage + 1 : 0;
  const endItem = currentPage
    ? Math.min(currentPage * itemsPerPage, totalDocuments)
    : 0;

  return (
    <React.Fragment>
      <Loader loading={loading} />
      <div className="dashboard-main">
        <DrillDownModal
          closeHandler={() => setShowDetailModal(null)}
          modalOpen={!!showDetailModal}
          HeadAndRow={HeadAndRowDrillDown}
          data={showDetailModal}
          links={["cartonNo","EAN"]}
          tableTitle="PO Drill Down Items Detail"
        />
        <div
          className="dashboard-top"
          style={{ display: "flex", flexDirection: "column", padding: "10px" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                flex: "1",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p
                className="stock-count"
                style={{ fontSize: "25px", margin: 0 }}
              >
                {" "}
                PO Drill Down{" "}
              </p>
            </div>
          </div>
          <div>
            <h1 style={{ color: "black", fontSize: "15px" }}>
              {new Date().toLocaleString("hi-IN")}
            </h1>
          </div>
        </div>
        <div className="dashboard-bottom" style={{ marginTop: "0.5%" }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              gridColumnGap: "20px",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                gridColumnGap: "10px",
                gridRowGap: "10px",
                flexWrap: "wrap",
              }}
            >
              <DesktopDatePicker
                label="From Date"
                clearable
                showTodayButton
                inputFormat="MM/DD/YYYY"
                size={"small"}
                maxDate={new Date()}
                value={fromDate}
                onChange={(data) => setFromDate(data)}
                componentsProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                renderInput={(params) => (
                  <TextField sx={{ width: 170 }} size={"small"} {...params} />
                )}
              />
              <DesktopDatePicker
                label="To Date"
                clearable
                showTodayButton
                inputFormat="MM/DD/YYYY"
                size={"small"}
                maxDate={new Date()}
                value={toDate}
                onChange={(data) => setToDate(data)}
                componentsProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                renderInput={(params) => (
                  <TextField sx={{ width: 170 }} size={"small"} {...params} />
                )}
              />
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                freeSolo={true}
                size={"small"}
                options={printingItems
                  ?.filter((item) => item?.PO_Number !== "" && item?.PO_Number !== undefined)
                  .map((item) => item?.PO_Number)
                  .reduce((noDupArr, entry) => {
                    if (noDupArr.includes(entry)) {
                      return noDupArr;
                    } else {
                      return [...noDupArr, entry];
                    }
                  }, [])}
                onChange={(e, v) => setPoNumber(v === null ? "" : v)}
                onInputChange={(e) =>
                  setPoNumber(
                    e.target?.value === 0 || e.target?.value === undefined
                      ? ""
                      : e.target?.value
                  )
                }
                sx={{ width: 170 }}
                renderInput={(params) => (
                  <TextField {...params} label="PO Number" />
                )}
              />
            </div>
            <button onClick={() => getPrintedItems()} className="run-btn">
              <p className="run-text">Search</p>
            </button>
          </div>
          <div
            style={{ marginTop: "10px", overflowX: "scroll", height: "600px" }}
          >
            <PaginatedItems
              pageCountNew={paginationData?.next + paginationData?.previous + 1}
              getData={(pageNumber) => getPrintedItems(pageNumber)}
              totalLength={paginationData?.next}
              itemsPerPage={10}
              headers={headers}
              colNames={colNames}
              forcePage={paginationData?.previous || 0}
              data={printingItems.map((item) => ({
                ...formatItem(item),
                createdAt: moment(item.createdAt).format(
                  "MM/DD/YYYY hh:mm:ss A"
                ),
                PO_Number: item.PO_Number ? (
                  <Link
                    to="#"
                    onClick={(e) => onGetData(e, item.PO_Number)}
                  >
                    {item.PO_Number}
                  </Link>
                ) : (
                  item.PO_Number || "--"
                ),
                PO_Status: capitalizeFirstLetter(item.PO_Status),
                status: capitalizeFirstLetter(item.status),
              }))}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
              color: "white",
              padding: "10px",
              gap: "10px",
            }}
          >
            <h5>ROWS PER PAGE: 10</h5>
            <h5>{`${startItem}-${endItem} of ${totalDocuments}`}</h5>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DrillDown;
