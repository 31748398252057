import React, { Component } from "react";
import { Calendar } from "react-calendar";
import Dropdown from "../../components/Dropdown";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import print from "../../assets/print.png"
import zoom from "../../assets/zoom.png"
import download from "../../assets/download.png"
import MyInput from "../../components/MyInput";
import { withRouter } from "../../components/withRouter";
import { deleteRoles, getRoles } from "../../Path/Path";
import { toast } from "react-toastify";
import PermissionsModal from "../../components/PermissionsModal";
import { PaginatedItems } from "../../components/Paginated";
import { Autocomplete, TextField } from "@mui/material";
import Loader from "../../components/Loader";
import { PaginatedItemsOffline } from "../../components/PaginatedOffline";
import AlertDialog from "../../components/AlertDialog";

class Roles extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            tableFilter: [],
            roleName: "",
            visible: false,
            itemsPerPage: 10,
            loading: false,
            deleteModal: null,
        }
    }

    componentDidMount() {
        this.saveNewUsersToState()
    }

    saveNewUsersToState = async () => {
        this.setState({ loading: true })
        const roles = await getRoles();
        this.setState({ data: roles, tableFilter: roles })
        this.setState({ loading: false })
    }

    deleteHandler = async (id) => {
        try {
            await deleteRoles(id)
            this.saveNewUsersToState();
            toast.success("Role deleted successfully")
            this.setState({deleteModal: null})
        } catch (err) {
            toast.warn(err.message)
        }

    }

    editHandler = (item) => {
        this.props.history("/addrole", { state: { item } })
    }

    searchHandler = () => {
        if (!this.state.roleName) {
            this.setState({ tableFilter: this.state.data })
            return
        }
        const res = this.state.data.filter(i => i.role_name.toLowerCase().includes(this.state.roleName.toLowerCase()))
        this.setState({ tableFilter: res })
    }

    showPermissionHandler = (item) => {
        this.setState({ visible: true, permissions: item.permissions, roles_name: item.role_name })
    }

    handler = () => {
        this.props.history("/addrole")
    }
    render() {

        const headers = [

            "Role Name",
            "Created Date"
        ]

        const colNames = [

            "role_name",
            "createdAt"
        ]
        return (
            <>
            <AlertDialog
                    deleteThis="User"
                    onDeletePress={() => this.deleteHandler(this.state?.deleteModal)}
                    open={this.state.deleteModal ? true : false}
                    handleClose={() => this.setState({ deleteModal: null })}
                />
                <Loader loading={this.state.loading} />
                <div className="dashboard-main">
                    <div className="dashboard-top">
                        <div> <p className="stock-count" style={{ fontSize: '25px', margin: 0 }}>Users Roles</p>
                            <h1 style={{ color: 'black', fontSize: '15px' }}>{new Date().toLocaleString('hi-IN')} </h1>
                        </div>
                        <button className="run-btn"><p className="add-text" onClick={() => this.handler()}>Add Roles</p></button>
                    </div>
                    <div className="users-bottom">
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", width: "280px" }}>
                            {/* <p className="filter-text">Filters</p> */}
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                size={'small'}
                                options={this.state.tableFilter?.map((item => item?.role_name)).reduce((noDupArr, entry) => {
                                    if (noDupArr?.includes(entry)) {
                                        return noDupArr;
                                    } else {
                                        return [...noDupArr, entry];
                                    }
                                }, []).filter((item => item !== null && item !== undefined))}
                                onChange={(e, v) => this.setState({ roleName: v === null ? '' : v })}
                                sx={{ width: 235 }}
                                renderInput={(params) => <TextField
                                    {...params} label="Role Name" />}
                            />
                        </div>
                        <button className="run-btn"><p className="run-text" onClick={() => this.searchHandler()}>Search</p></button>
                    </div>
                    <div style={{ paddingTop: "2%" }}>
                        <PaginatedItemsOffline headers={headers} editHandler={(item) => this.editHandler(item)} deleteHandler={(item) => this.setState({deleteModal: item._id})} showPermissionHandler={(item) => this.showPermissionHandler(item)} Action Permissions colNames={colNames} itemsPerPage={this.state.itemsPerPage} data={this.state.tableFilter} />
                    </div>
                    <PermissionsModal
                        permissions={this.state.permissions}
                        roles_name={this.state.roles_name}
                        open={this.state.visible ? true : false}
                        onClick={() => this.setState({ visible: false })}
                    />
                </div>
            </>
        )
    }
}

export default withRouter(Roles);