import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { GrDocumentCsv } from "react-icons/gr";
import Loader from "../components/Loader";
import { PaginatedItems } from "../components/Paginated";
import PoquantityDetails from "./POQuantityDetails";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { createRoot } from "react-dom/client";
import { Link } from "react-router-dom";
import { getPO, localServer } from "../Path/Path";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "./common/capitalizeFirstLetter";
import { formatItem } from "./common/FormatItems";
import TableModal from "../components/TableModal";
import { HeadAndRowPO } from "../components/Helper";

const PurchaseOrder = () => {
  const [printingItems, setPrintingItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [toDate, setToDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [poNumber, setPoNumber] = useState("");
  const [paginationData, setPaginationData] = useState({});
  const navigate = useNavigate();
  const [showDetailModal, setShowDetailModal] = useState(null);
  const [status, setStatus] = useState("");

  useEffect(() => {
    getPrintedItems();
  }, []);
  const validateDate = (date) => {
    const dateFormat = "MM/DD/YYYY";
    if (!date) {
      return true;
    }
    if (!moment(date, dateFormat, true).isValid()) {
      // toast.error("Invalid date format.");
      return false;
    }
    if (moment(date).isAfter(moment())) {
      return false;
    }
    return true;
  };

  const getPrintedItems = async (pageNumber = 1) => {
    const newFromDate = validateDate(fromDate);
    const newToDate = validateDate(toDate);
    if (!newFromDate || !newToDate) {
      toast.error("Please enter valid date ranges.");
      return;
    }
    setLoading(true);
    try {
      const params = {
        page: pageNumber,
        PO_Number: poNumber,
        startDate: fromDate
          ? moment(fromDate).startOf("day").format("YYYY-MM-DD")
          : "",
        endDate: toDate ? moment(toDate).endOf("day").format("YYYY-MM-DD") : "",
      };
      const data = await getPO(params);
      data.data.data.forEach((po) => {
        const createdItemsCount = po.items.filter(
          (item) => item.process_status === "created"
        ).length;
        po.PO_Item = createdItemsCount;
      });

      if (data && Array.isArray(data.data.data)) {
        const filtering = data?.data?.data;
        console.log({ filtering });
        setPrintingItems(filtering);
        setPaginationData(data?.data);
        setCurrentPage(data?.page);
        setTotalDocuments(data.data.total);
        setItemsPerPage(data?.data?.size);
        setLoading(false);
        if (data?.data?.data?.length === 0) {
          toast.error("No data found.");
        } else {
          setCurrentPage(data.data.page);
          setTotalDocuments(data.data.total);
          setItemsPerPage(10);
        }
      } else {
        console.error("Unexpected API response structure:", data);
      }
    } catch (err) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const onGetData = (e, value, filter) => {
    e.preventDefault();
    setLoading(true);
    try {
      console.log("enter in try catch");
      const response = paginationData.data;
      const document = response.find((doc) => doc.PO_Number === value);
      if (document) {
        let items = document.items;
        if (filter) {
          const filteredItems = items.filter(
            (item) => item.process_status === filter
          );
          items = filteredItems;
          console.log({ items });
        }
        if (items.length > 0) {
          // navigate("/purchaseOrderItems", {
          //   state: { item: items, filter },
          // });
          setShowDetailModal(items);
          setStatus(filter);
        } else {
          toast.error("No items available.");
        }
      } else {
        toast.error("No matching document found.");
      }
    } catch (error) {
      console.error("Error in onGetData:", error);
      toast.error("An error occurred while fetching data.");
    } finally {
      setLoading(false);
    }
  };

  const getCSV = async () => {
    const newFromDate = validateDate(fromDate);
    const newToDate = validateDate(toDate);
    if (!newFromDate || !newToDate) {
      toast.error("Please enter valid date ranges.");
      return;
    }

    setLoading(true);
    try {
      const params = {
        PO_Number: poNumber,
        startDate: fromDate
          ? moment(fromDate).startOf("day").toISOString()
          : "",
        endDate: toDate ? moment(toDate).endOf("day").toISOString() : "",
      };
      const response = await axios.get(`${localServer}store/PO/csv`, {
        params,
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Purchase Order.csv");
      document.body.appendChild(link);

      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
    setLoading(false);
  };

  const headers = [
    "Created Time",
    "PO Doc Type",
    "PO Number",
    "PO Item",
    "PO Vendor",
    "PO Vendor Name",
    "PO Currency Key",
    "PO Action",
    "Device ID",
    "Qty.",
    "Packed Qty.",
    "Shipped Qty.",
    "Received Qty.",
    "Packing Date",
    "Shipping Remarks",
    "Receiving Remarks",
    "Packing User",
    "Shipping User",
    "Receiving User",
    "Add Char2",
    "Add Char3",
    "Add Char4",
    "PO Status",
    "Status",
  ];
  const colNames = [
    "createdAt",
    "PO_Doc_Type",
    "PO_Number",
    "PO_Item",
    "PO_Vendor",
    "PO_Vendor_Name",
    "PO_Currency_Key",
    "PO_Action",
    "deviceId",
    "default_items",
    "packed_items",
    "shipped_items",
    "received_items",
    "packing_remarks",
    "shipping_remarks",
    "receiving_remarks",
    "PackingUser",
    "ShippingUser",
    "ReceivingUser",
    "Add_Char2",
    "Add_Char3",
    "Add_Char4",
    "PO_Status",
    "status",
  ];
  const startItem = currentPage ? (currentPage - 1) * itemsPerPage + 1 : 0;
  const endItem = currentPage
    ? Math.min(currentPage * itemsPerPage, totalDocuments)
    : 0;

  return (
    <React.Fragment>
      <Loader loading={loading} />
      <div className="dashboard-main">
        <TableModal
          closeHandler={() => setShowDetailModal(null)}
          modalOpen={!!showDetailModal}
          HeadAndRow={HeadAndRowPO.filter((f) => {
            if (status === "created") {
              return (
                f.header !== "Receiving Process Qty." &&
                f.header !== "Receiving Date" &&
                f.header !== "Packing Process Qty." &&
                f.header !== "Packing Date" &&
                f.header !== "Shipping Process Qty." &&
                f.header !== "Shipping Date"
              );
            } else if (status === "packed") {
              return (
                f.header !== "Receiving Process Qty." &&
                f.header !== "Receiving Date" &&
                // f.header !== "Packing Process Qty." &&
                // f.header !== "Packing Date" &&
                f.header !== "Shipping Process Qty." &&
                f.header !== "Shipping Date"
              );
            } else if (status === "received") {
              return (
                // f.header !== "Receiving Process Qty." &&
                // f.header !== "Receiving Date" &&
                f.header !== "Packing Process Qty." &&
                f.header !== "Packing Date" &&
                f.header !== "Shipping Process Qty." &&
                f.header !== "Shipping Date"
              );
            } else if (status === "shipped") {
              return (
                f.header !== "Receiving Process Qty." &&
                f.header !== "Receiving Date" &&
                f.header !== "Packing Process Qty." &&
                f.header !== "Packing Date"
                // f.header !== "Shipping Process Qty." &&
                // f.header !== "Shipping Date"
              );
            } else {
              return true;
            }
          })}
          data={showDetailModal}
          links={["Barcode", "PO_Article_GTIN"]}
          tableTitle="Purchase Order Item Details"
        />
        <div
          className="dashboard-top"
          style={{ display: "flex", flexDirection: "column", padding: "10px" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                flex: "1",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p
                className="stock-count"
                style={{ fontSize: "25px", margin: 0 }}
              >
                {" "}
                Purchase Order{" "}
              </p>
            </div>
            <GrDocumentCsv
              onClick={getCSV}
              color="black"
              fill="white"
              fontSize={25}
              style={{ marginRight: 5, cursor: "pointer" }}
            />
          </div>
          <div>
            <h1 style={{ color: "black", fontSize: "15px" }}>
              {new Date().toLocaleString("hi-IN")}
            </h1>
          </div>
        </div>
        <div className="dashboard-bottom" style={{ marginTop: "0.5%" }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              gridColumnGap: "20px",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                gridColumnGap: "10px",
                gridRowGap: "10px",
                flexWrap: "wrap",
              }}
            >
              <DesktopDatePicker
                label="From Date"
                clearable
                showTodayButton
                inputFormat="MM/DD/YYYY"
                size={"small"}
                maxDate={new Date()}
                value={fromDate}
                onChange={(data) => setFromDate(data)}
                componentsProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                renderInput={(params) => (
                  <TextField sx={{ width: 170 }} size={"small"} {...params} />
                )}
              />
              <DesktopDatePicker
                label="To Date"
                clearable
                showTodayButton
                inputFormat="MM/DD/YYYY"
                size={"small"}
                maxDate={new Date()}
                value={toDate}
                onChange={(data) => setToDate(data)}
                componentsProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                renderInput={(params) => (
                  <TextField sx={{ width: 170 }} size={"small"} {...params} />
                )}
              />
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                freeSolo={true}
                size={"small"}
                options={printingItems
                  ?.filter((item) => item?.PO_Number !== "" && item?.PO_Number !== undefined)
                  .map((item) => item?.PO_Number)
                  .reduce((noDupArr, entry) => {
                    if (noDupArr.includes(entry)) {
                      return noDupArr;
                    } else {
                      return [...noDupArr, entry];
                    }
                  }, [])}
                onChange={(e, v) => setPoNumber(v === null ? "" : v)}
                onInputChange={(e) =>
                  setPoNumber(
                    e.target?.value === 0 || e.target?.value === undefined
                      ? ""
                      : e.target?.value
                  )
                }
                sx={{ width: 170 }}
                renderInput={(params) => (
                  <TextField {...params} label="PO Number" />
                )}
              />
            </div>
            <button onClick={() => getPrintedItems()} className="run-btn">
              <p className="run-text">Search</p>
            </button>
          </div>
          <div
            style={{ marginTop: "10px", overflowX: "scroll", height: "600px" }}
          >
            <PaginatedItems
              pageCountNew={paginationData?.next + paginationData?.previous + 1}
              getData={(pageNumber) => getPrintedItems(pageNumber)}
              totalLength={paginationData?.next}
              itemsPerPage={10}
              headers={headers}
              colNames={colNames}
              forcePage={paginationData?.previous || 0}
              data={printingItems.map((item) => ({
                ...formatItem(item),
                createdAt: moment(item.createdAt).format(
                  "MM/DD/YYYY hh:mm:ss A"
                ),
                PO_Number: item.PO_Number ? (
                  <Link
                    to="#"
                    onClick={(e) => onGetData(e, item.PO_Number, null)}
                  >
                    {item.PO_Number}
                  </Link>
                ) : (
                  item.PO_Number || "--"
                ),
                default_items: item.PO_Number ? (
                  <Link
                    to="#"
                    onClick={(e) => onGetData(e, item.PO_Number, "created")}
                  >
                    {item.default_items}
                  </Link>
                ) : (
                  item.default_items || "--"
                ),
                packed_items:
                  item.status !== "created" &&
                  item.status !== "Created" &&
                  item.packed_items ? (
                    <Link
                      to="#"
                      onClick={(e) => onGetData(e, item.PO_Number, "packed")}
                    >
                      {item.packed_items}
                    </Link>
                  ) : (
                    item.packed_items || "--"
                  ),
                shipped_items:
                  item.status !== "created" &&
                  item.status !== "Created" &&
                  item.shipped_items ? (
                    <Link
                      to="#"
                      onClick={(e) => onGetData(e, item.PO_Number, "shipped")}
                    >
                      {item.shipped_items}
                    </Link>
                  ) : (
                    item.shipped_items || "--"
                  ),
                received_items:
                  item.status !== "created" &&
                  item.status !== "Created" &&
                  item.received_items ? (
                    <Link
                      to="#"
                      onClick={(e) => onGetData(e, item.PO_Number, "received")}
                    >
                      {item.received_items}
                    </Link>
                  ) : (
                    item.received_items || "--"
                  ),
                PO_Status: capitalizeFirstLetter(item.PO_Status),
                status: capitalizeFirstLetter(item.status),
              }))}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
              color: "white",
              padding: "10px",
              gap: "10px",
            }}
          >
            <h5>ROWS PER PAGE: 10</h5>
            <h5>{`${startItem}-${endItem} of ${totalDocuments}`}</h5>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PurchaseOrder;
