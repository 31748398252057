import { Autocomplete, TextField } from "@mui/material";
import React, { Component } from "react";
import { BiMenu } from 'react-icons/bi';
import { toast } from "react-toastify";
import { Innovent_Path, PathCustom, getCountedTarget, getTargetedStockCountByDep, reqInstance, targetedStockCountName } from "../Path/Path";
import Loader from "../components/Loader";
import StockSummaryModal from "../components/StockSummaryModal";
import { withRouter } from "../components/withRouter";
import CountDetails2 from "./CountDetails2";
class TargetStockCountDashboard extends Component {
    state = {
        storeId: [],
        destinationData: [],
        status: [],
        CountedItems: {},
        allCounted: [],
        AssetsBySoh: [],
        allSOH: [],
        storeData: {},
        cronStatus: false,
        storeArray: null,
        loading: false,
        stats: {
            totalCountedItems: 0,
            totalSOH: 0,
            itemCountAccuracy: 0,
        },
        store: '',
        csvDataReal: [],
        csvDataRealFake: [],
        unders: {},
        overs: { totalOvers: 0 },
        matched: { totalMatched: 0 },
        sohqty: 0,
        counted: 0,
        criticalOutofStock: {},
        stores: '',
        operationalStats: 0,
        departmentID: [],
        totalSOH: [],
        itemAccuracy: 0,
        open: false,
        zonesData: [],
        undersToggle: false,
        oversToggle: false,
        department: '',
        stockToggle: false,
        onHandToggle: false,
        createdDate: null,
        datesArray: [],
        Color: '',
        Brand: '',
        Size: '',
        ColorList: [],
        BrandList: [],
        SizeList: [],
        highlightedDays: [1, 10, 12, 21]
    }
    gettingStores = async () => {

        let data = null
        await reqInstance.get(`${Innovent_Path}STORES`)
            .then((async res => {

                data = await res?.data?.results
            }))

            .catch((err => {
                console.log(err);
            }))

        await this.setState({ storeArray: data })

    }
    gettingDepartments = async () => {

        let data = null
        await targetedStockCountName()
            .then((async res => {

                await this.setState({ departmentID: res?.data })

            }))

            .catch((err => {
                console.log(err);
                this.setState({loading : false})
                toast.error("Something went wrong")
            }))


    }
    gettingDates = async () => {
      
        let data = null
        await reqInstance.get(`${PathCustom}store/get/soh/dates`)
            .then((async res => {
                if (res?.data?.success === false && res?.data?.message === "A token is required for authentication") {
     
                }
                data = await res?.data?.data
            }))

            .catch((error => {
                let err = error?.response?.data
                if (err?.success === false && err?.message === "Invalid Token") {
            
                }
            }))

        this.setState({ datesArray: data !== null ? data : [] })

    }
    isToday = (date) => {
        const today = this.formatDate(new Date());
        let newDate = date
        // 👇️ Today's date
        console.log(today);

        if (today === newDate) {
            return true;
        }

        return false;
    }
    statsFunction = async () => {
        this.setState({ loading: true })


        try{
        const getCounted = await getCountedTarget(this.state.department)
        const getSoh = await getTargetedStockCountByDep(this.state.department)
        // const getDescripency = await axios.post(`https://virgin-test-backend1.innovent.site/api/v1/store/get/stats/soh?StoreID=${this.state.store}&DepartmentID=${this.state.department}&Color=${this.state.Color}&Brand=${this.state.Brand}&Size=${this.state.Size}&createdDate=${createdData}`)
        const discripencyData = await getCounted?.data
        const discripencyDataHehe = await getSoh?.data?.map((data => { return { ...data, sku: (data?.sku?.startsWith("00000") ? data.sku?.slice(5) : data.sku) } }))
        console.log(discripencyDataHehe, 'discripencyDataHehe');
        console.log(getCounted, 'getDescripency?.data?.data');
        console.log(getSoh, 'getDescripencasday?.data?.data');
        // const counted = await getCountedPerStore({ "StoreID": this.state.store })
        // console.log(counted, 'counted');

        var unders = { totalUnders: 0, undersData: [] }
        var overs = { totalOvers: 0, oversData: [] }
        var matched = { totalMatched: 0, matchedData: [] }

        var zonesData = { salesFloor: 0, groundFloor: 0 }
        var criticalOutofStock = { totalStock: 0, stockData: [] }
        var sohqty = 0
        var totalSOH = []
        var unknownData = []

        totalSOH = discripencyDataHehe
        sohqty = discripencyDataHehe.reduce(function (previousValue, currentValue) {
            return { qty: Number(previousValue.qty) + Number(currentValue.qty), }
        })?.qty;
        console.log(sohqty, 'sohqtysohqty');
        const checkPosCodeUnique = (search) => {
            return discripencyDataHehe.some(item => { return item.sku?.includes(search) });
        };
        for (let index = 0; index < discripencyData.length; index++) {
            const element = discripencyData[index];
            console.log(element?.barcode, 'barcode');
            // console.log(discripencyDataHehe?.map((item => "00000" + item?.barcode)), 'element?.sku.includes(discripencyData?.map((item => item?.barcode)))');
            if (checkPosCodeUnique(element?.barcode)) {
                matched = {
                    totalMatched: matched?.totalMatched + Number(1),
                    matchedData: [...matched?.matchedData, element]
                }
            }

        }
        for (let index = 0; index < discripencyDataHehe.length; index++) {
            const element = discripencyDataHehe[index];
            console.log(element?.barcode, 'barcode');
            // console.log(discripencyDataHehe?.map((item => "00000" + item?.barcode)), 'element?.sku.includes(discripencyData?.map((item => item?.barcode)))');
            if (!checkPosCodeUnique(element?.barcode)) {
                unders = {
                    totalUnders: unders?.totalUnders + Number(element?.qty),
                    undersData: [...unders?.undersData, element]
                }
            }

        }
        let newVAricenceData = discripencyDataHehe
        for (let index = 0; index < newVAricenceData.length; index++) {
            const element = discripencyDataHehe[index];
            element.scanned = 0
            element.QTY_Variances = 0
            element.qty = Number(element.qty)

            for (let index = 0; index < discripencyData.length; index++) {
                const element2 = discripencyData[index];
                if (element?.sku?.includes(element2?.barcode)) {
                    console.log(element?.sku, 'barcodeCOUNTED');
                    element.scanned = element?.scanned + 1
                }
            }
            element.QTY_Variances = Number(element.qty) - element?.scanned
            // if (checkPosCodeUniqueCounted(element?.sku)) {
            //     console.log(element?.sku, 'barcodeCOUNTED');
            //     element.scanned = element?.scanned + 1
            // }

            // console.log(discripencyDataHehe?.map((item => "00000" + item?.barcode)), 'element?.sku.includes(discripencyData?.map((item => item?.barcode)))');


        }

        for (let index = 0; index < discripencyData.length; index++) {
            const element = discripencyData[index];
            if (!element?.barcode?.includes(discripencyDataHehe?.map((item => item?.sku)))) {
                overs = {
                    totalOvers: overs?.totalOvers + Number(element?.qty || 0),
                    oversData: [...overs?.oversData, element]
                }
            }

        }
        // for (let index = 0; index < discripencyDataHehe.length; index++) {
        //     const soh = discripencyDataHehe[index];
        //     for (let index = 0; index < discripencyData.length; index++) {
        //         const counted = discripencyData[index];
        //         if (soh?.sku === counted?.sku) {
        //             matched = {
        //                 totalMatched: { ...matched?.totalMatched + 1 },
        //                 matchedData: [...matched?.matchedData, soh]
        //             }
        //         } else if (soh?.sku !== counted?.sku) {
        //             unders = {
        //                 totalUnders: { ...unders?.totalUnders + 1 },
        //                 undersData: [...unders?.undersData, soh]
        //             }
        //         } else {
        //             overs = {
        //                 totalOvers: { ...overs?.totalOvers + 1 },
        //                 oversData: [...overs?.oversData, counted]
        //             }
        //         }
        //     }
        // }
        // this.setState({ csvDataReal: discripencyData, csvDataRealFake: discripencyData })
        // console.log(matched?.matchedData?.filter((item => item?.DepartmentID === 'BOOKS')), 'BOOKS')
        // console.log(unders, 'unders');
        // console.log(overs, 'overs');
        // console.log(matched, 'matched');
        // console.log(sohqty, 'sohqty');
        // let salesFloor = 0
        // const dadadad = discripencyData.filter((item => item?.zone && item?.zone?.SalesFloor !== 0))
        // for (let index = 0; index < dadadad.length; index++) {
        //     const element = dadadad[index];
        //     salesFloor = element.zone?.SalesFloor + salesFloor

        // }
        const operationalStats = ((matched?.totalMatched / sohqty) * 100).toFixed(2)
        const itemAccuracy = (((matched?.totalMatched + overs?.totalOvers) / sohqty) * 100).toFixed(2)
        this.setState({
            unders: { totalUnders: unders?.totalUnders, undersData: newVAricenceData.map((item => { return { ...item, qty: item?.QTY_Variances } })) },
            overs: overs,
            matched: matched,
            sohqty: sohqty,
            totalSOH: totalSOH,
            counted: discripencyData?.length,
            operationalStats: operationalStats,
            // zonesData: zonesData,
            itemAccuracy: itemAccuracy,
            // criticalOutofStock: criticalOutofStock,
        })
        console.log(discripencyData?.length, 'sdfasdfsadfasdfsdf');
        console.log(unders, 'unders');
        console.log(overs, 'overs');
        console.log(matched, 'matched');
        console.log(sohqty, 'sohqty');
        console.log(totalSOH, 'totalSOH');





        // console.log(matched?.matchedData?.filter((item => item?.DepartmentID === 'BOOKS')), 'BOOKS')
        this.setState({ loading: false })
        } catch (error) {
            this.setState({loading: false, csvDataReal: null})
            toast.error("Something went wrong")
        }
    }

    async componentDidMount() {
        try{
        this.setState({ loading: true })

        await this.gettingDepartments()


        // await this.statsFunction()


        // const getStats = await axios.post(`${localServer}store/get/stats/soh`)
        // console.log(getStats?.data?.data, 'stats');
        // const stats = await getStats?.data?.data
        // for (let index = 0; index < stats.length; index++) {
        //     const element = array[index];

        // }

        this.setState({ loading: false })
         } catch (error) {
            this.setState({loading: false})
            toast.error("Something went wrong")
        }

    }


    departmentHandler = async (data) => {
        await this.setState({ department: data })
    }
    searchFunction = async () => {
        try{
        if (this.state.department === '') {
            toast.error("Please Select Department")
        } else {
            // const cronStatus = await getCronStatus()
            // await this.setState({ cronStatus: cronStatus?.data?.status })
            // console.log(cronStatus?.data?.status, "\cronStatus");
            this.statsFunction()

        }
         } catch (error) {
            this.setState({loading: false})
            toast.error("Something went wrong")
        }
    }


    UndersOpen = () => {
        this.setState({ open: true })
        this.setState({
            undersToggle: true,
            oversToggle: false,
            stockToggle: false,
            onHandToggle: false,
        })
    };
    OversOpen = () => {
        this.setState({ open: true })
        this.setState({
            undersToggle: false,
            oversToggle: true,
            stockToggle: false,
            onHandToggle: false,
        })
    };
    StockOpen = () => {
        this.setState({ open: true })
        this.setState({
            undersToggle: false,
            oversToggle: false,
            stockToggle: true,
            onHandToggle: false,
        })
    };
    handleClickOpen = () => {
        this.setState({ open: true })
    };
    onHandOpen = () => {
        this.setState({ open: true })
        this.setState({
            undersToggle: false,
            oversToggle: false,
            stockToggle: false,
            onHandToggle: true,
        })
    };

    handleClose = () => {
        this.setState({ open: false })
    };
    formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
        // return console.log([year, month, day].join('-'))
    }
    shouldDisableDate = (date) => {
        const formatDate = (date) => {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
            // return console.log([year, month, day].join('-'))
        }
        let datas = this.state.datesArray.map((item => formatDate(item)))

        return !datas.includes(formatDate(new Date(date).toLocaleString()))
        // return console.log();
    }

    render() {
        const { storeId, destinationData, status, data } = this.state;
        console.log(this.state.departmentID, "this.state.departmentID")

        return (
            <React.Fragment>
                <StockSummaryModal
                    closeHandler={() => this.handleClose()}
                    openHandler={() => this.handleClickOpen()}
                    modalOpen={this.state.open}

                >
                    {this.state.undersToggle ?
                        <CountDetails2 data={this.state.unders.undersData} heading={'Unders'} />
                        : null}
                    {this.state.oversToggle ?
                        <CountDetails2 data={this.state.overs.oversData} heading={'Overs'} />
                        : null}
                    {this.state.stockToggle ?
                        <CountDetails2 data={this.state.criticalOutofStock.stockData} heading={'Out Of Stock'} />
                        : null}
                    {this.state.onHandToggle ?
                        <CountDetails2 data={this.state.totalSOH} heading={'On Hand'} />
                        : null}
                </StockSummaryModal>
                <Loader loading={this.state.loading} />
                <div className="dashboard-main">
                    <div className="dashboard-top" style={{ alignItems: 'center' }}>
                        <div> <p className="stock-count" style={{ fontSize: '25px', margin: 0 }}>Target Stock Count Summary</p></div>
                        {/* {this.state.cronStatus ?
                            <div style={{ display: 'flex', alignItems: 'center', gridColumnGap: 20 }}>
                                <span>Result May Vary <br /> Please Wait System is Updating</span>
                                <MoonLoader color='black' size={20} loading={this.state.cronStatus} />
                            </div> : null
                        } */}

                    </div>
                    <div className="dashboard-bottom">
                        {/* <div> <p className="filter-text"> Store ID: {this.state.store === '' ? "No Store Selected" : this.state.store}</p></div> */}
                        <div className="drop-data" style={{ gridRowGap: '20px' }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', gridColumnGap: '20px', gridRowGap: '20px', flexWrap: 'wrap' }}>

                                {/* <DesktopDatePicker
                                    label="Created Date"
                                    inputFormat="DD/MM/YYYY"
                                    clearable
                                    // shouldDisableDate={(date) => this.shouldDisableDate(date)}
                                    renderDay={(day, _value, DayComponentProps) => {
                                        const isSelected =
                                            !DayComponentProps.outsideCurrentMonth &&
                                            this.state.datesArray.map((item => this.formatDate(item))).includes(this.formatDate(day));
                                        return (
                                            <Badge
                                                key={day.toString()}
                                                overlap="circular"
                                                badgeContent={isSelected ? <BiScan size={13} /> : undefined}
                                            >
                                                <PickersDay {...DayComponentProps} />

                                            </Badge>

                                        );
                                    }}
                                    // disablePast
                                    disableFuture
                                    size={'small'}
                                    value={this.state.createdDate}
                                    onChange={(data) => this.setState({ createdDate: data })}
                                    renderInput={(params) => <TextField sx={{ width: 170 }} size={'small'} {...params} />}
                                /> */}
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    size={'small'}

                                    options={this.state.departmentID}
                                    onChange={(e, v) => this.departmentHandler(v === null || v === undefined ? '' : v)}
                                    sx={{ width: 235 }}
                                    renderInput={(params) => <TextField
                                        {...params} label="Reference" />}
                                />
                                {/* <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    size={'small'}

                                    options={this.state.ColorList}
                                    onChange={(e, v) => this.setState({ Color: v === null || v === undefined ? '' : v })}
                                    sx={{ width: 235 }}
                                    renderInput={(params) => <TextField
                                        {...params} label="Color" />}
                                /> */}
                                {/* <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    size={'small'}

                                    options={this.state.BrandList}
                                    onChange={(e, v) => this.setState({ Brand: v === null || v === undefined ? '' : v })}
                                    sx={{ width: 235 }}
                                    renderInput={(params) => <TextField
                                        {...params} label="Brand" />}
                                />
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    size={'small'}

                                    options={this.state.SizeList}
                                    onChange={(e, v) => this.setState({ Size: v === null || v === undefined ? '' : v })}
                                    sx={{ width: 235 }}
                                    renderInput={(params) => <TextField
                                        {...params} label="Size" />}
                                /> */}

                            </div>
                            <button onClick={() => this.searchFunction()} className="run-btn"><p className="run-text">Search</p></button>

                        </div>
                        <div>

                        </div>
                        <div style={{ paddingTop: "2%" }}>
                            <div class="row ml-0 mr-0" id="CountDynamic">
                                <div class="col-md-8">
                                    <div class="row">
                                        <div class="col-md-6 pl-1 pr-1 pr-md-2">
                                            <div class="card-box p-3 top-cards item-accuracy-card">
                                                <h4>Item-Count <span>Accuracy</span>
                                                </h4>
                                                <h2 class="text-center" style={{ width: '100%' }}>

                                                    <span id="item_accuracy" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                        <p>{this.state.itemAccuracy === "NaN" ? "0" : this.state.itemAccuracy} %</p>
                                                    </span>
                                                </h2>
                                            </div>
                                        </div>
                                        <div class="col-md-6 pl-1 pr-1 pl-md-2">
                                            <div class="card-box p-3 top-cards item-accuracy-card">
                                                <h4>Operational <span>Accuracy</span>
                                                </h4>
                                                <h2 class="text-centerOn Hand Matching" style={{ width: '100%' }}>

                                                    <span id="operational_accuracy" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                        <p>{this.state.operationalStats === "NaN" ? "0" : this.state.operationalStats} %</p>
                                                    </span>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-4 pl-1 pr-1 pr-md-2">
                                            <div style={{ height: '220px' }} class="card-box p-4 item-accuracy-count" >
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                                                    <h4 class="d-inline-block mb-0" style={{ marginTop: '0px' }}>On Hand</h4>
                                                    <span onClick={() => this.onHandOpen()} style={{cursor:"pointer"}}><BiMenu fontSize={30} /></span>
                                                </div>
                                                <a href="executiveSummaryOnHandSimple?date=2022-09-19&amp;store_id=0009901&amp;show_over=no" target="_blank" class="OneHandSimple" style={{ cursor: "pointer" }}>
                                                </a>
                                                <h2 class="text-center mb-4 mt-4 lower-font" style={{ width: '100%' }}>

                                                    <span id="onehand" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                        <span class="accu-onhand-vl">{this.state.sohqty === "NaN" || this.state.sohqty === NaN ? "0" : this.state.sohqty}</span>
                                                    </span>
                                                </h2>
                                            </div>
                                        </div>
                                        <div class="col-md-4 pl-1 pr-1 pr-md-2">
                                            <div style={{ height: '220px' }} class="card-box p-4 item-accuracy-count">
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                                                    <h4 class="d-inline-block mb-0" style={{ marginTop: '0px' }}>Count</h4>
                                                    <span style={{ visibility: 'hidden' }} onClick={() => this.onHandOpen()} ><BiMenu fontSize={30} /></span>
                                                </div>
                                                <a href="executiveSummaryOnHandSimple?date=2022-09-19&amp;store_id=0009901&amp;show_over=no" target="_blank" class="OneHandSimple" style={{ cursor: "pointer" }}>
                                                </a>
                                                <h2 class="text-center mb-4 mt-4 lower-font" style={{ width: '100%' }}>
                                                    {/* {console.log(this.state?.counted, 'counted')} */}
                                                    <span id="onehand" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                        <span class="accu-onhand-vl">{this.state?.counted}</span>
                                                    </span>
                                                </h2>
                                            </div>
                                        </div>

                                        {/* <div class="col-md-4 pl-1 pr-1 pl-md-2">
                                            <div class="card-box px-3 py-2 item-accuracy-front-back">
                                                <h4 class="d-inline-block mb-0">Front</h4>
                                                <a href="executiveSummaryFront?date=2022-09-19&amp;store_id=0009901&amp;show_over=no" target="_blank" class="Front">
                                                </a>
                                                <h2 class="text-center mb-1 mt-1 sec-lower-font">

                                                    <span id="frontval">
                                                        <span class="ftbk-vl">{this.state.zonesData?.salesFloor ? this.state.zonesData?.salesFloor : 0}</span>
                                                    </span>
                                                </h2>
                                            </div>
                                            <div class="card-box px-3 py-2 mt-2 item-accuracy-front-back">
                                                <h4 class="d-inline-block mb-0">Back Store</h4>
                                                <a href="executiveSummaryBack?date=2022-09-19&amp;store_id=0009901&amp;show_over=no" target="_blank" class="BackStore">
                                                </a>
                                                <h2 class="text-center mb-1 mt-1 sec-lower-font">

                                                    <span id="counted_sr">
                                                        <span class="ftbk-vl">{this.state.zonesData?.groundFloor ? this.state.zonesData?.groundFloor : 0}</span>
                                                    </span>
                                                </h2>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="card-box-dark p-3 mb-2 black-right-card">
                                        <h4 class="d-inline-block mb-0 text-light" style={{ marginTop: '5px' }}>On Hand Matching</h4>
                                        <div>
                                            <h3 class="mb-0 d-inline-block">
                                                <span class="small-text">Count</span>

                                                <span id="onehand2" style={{ marginLeft: 20 }}>
                                                    <span class="accu-onhand-vl">{this.state.matched?.totalMatched}</span>
                                                </span>
                                            </h3>
                                            <h3 class="mb-0 d-inline-block float-right">
                                                <span></span>

                                                <span id="item_accuracy2" class="mt-2 ml-3" style={{ paddingTop: '10px' }}>

                                                    <p>{(this.state.matched?.totalMatched * 100 / this.state.sohqty).toFixed(2) === 'NaN' ? '0' : (this.state.matched?.totalMatched * 100 / this.state.sohqty).toFixed(2)}%</p>
                                                </span>
                                            </h3>
                                        </div>
                                    </div>
                                    <div class="card-box-dark p-3 mb-2 black-right-card">
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <h4 class="d-inline-block mb-0 text-danger" style={{ marginTop: '5px' }}>Unders</h4>
                                            <span onClick={() => this.UndersOpen()} style={{cursor:"pointer"}}><BiMenu fontSize={30} /></span>

                                        </div>
                                        <a href="underOvers?date=2022-09-19&amp;store_id=0009901&amp;show_over=no" target="_blank" style={{ cursor: "pointer" }} class="Unders">
                                        </a>
                                        <div>
                                            <h3 class="mb-0 d-inline-block">
                                                <span class="small-text">Count</span>

                                                <span id="undernum" style={{ marginLeft: 20 }}>
                                                    <p>{(this.state.sohqty - this.state.matched.totalMatched)}</p>
                                                </span>
                                            </h3>
                                            <h3 class="mb-0 d-inline-block float-right">


                                                <span id="underper" class="d-inline-block ml-3" >
                                                    <p>{((this.state.sohqty - this.state.matched.totalMatched) * 100 / this.state.sohqty).toFixed(2) === 'NaN' ? '0' : ((this.state.sohqty - this.state.matched.totalMatched) * 100 / this.state.sohqty).toFixed(2)}%</p>
                                                </span>
                                            </h3>
                                        </div>
                                    </div>
                                    {/* <div class="card-box-dark p-3 mb-2 black-right-card">
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <h4 class="d-inline-block mb-0 text-success" style={{ marginTop: '5px' }}>Overs</h4>
                                            <span onClick={() => this.OversOpen()} ><BiMenu fontSize={30} /></span>
                                        </div>


                                        <div>
                                            <h3 class="mb-0 d-inline-block">
                                                <span class="small-text">Count</span>

                                                <span id="expectednum" style={{ marginLeft: 20 }}>
                                                    <p>{this.state.overs?.totalOvers}</p>
                                                </span>
                                            </h3>
                                            <h3 class="mb-0 d-inline-block float-right">


                                                <span id="expectedper" class="d-inline-block ml-3"  >
                                                    <p>{(this.state.overs?.totalOvers * 100 / this.state.sohqty).toFixed(2) === "NaN" ? "0" : (this.state.overs?.totalOvers * 100 / this.state.sohqty).toFixed(2)}%</p>
                                                </span>
                                            </h3>
                                        </div>
                                    </div> */}
                                    {/* <div class="card-box-dark p-3 mb-2 black-right-card">
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <h4 class="d-inline-block mb-0 text-danger" style={{ marginTop: '5px' }}>Critical Out Of Stock</h4>
                                            <span onClick={() => this.StockOpen()} ><BiMenu fontSize={30} /></span>
                                        </div>

                                        <a href="CriticalOutOfStock?date=2022-09-19&amp;store_id=0009901&amp;show_over=no" target="_blank" style={{ cursor: "pointer" }} class="CriticalOutOfStock">
                                        </a>
                                        <div>
                                            <h3 class="mb-0 mt-3 d-inline-block">
                                                <span class="small-text">Count</span>
                                                <span id="CriticalStock" style={{ marginLeft: 20 }}>
                                                    <span class="accu-onhand-vl">{this.state.criticalOutofStock?.totalStock === "NaN" || this.state.criticalOutofStock?.totalStock === "NaN" ? "0" : this.state.criticalOutofStock?.totalStock}</span>
                                                </span>
                                            </h3>
                                            <h3 class="mb-0 mt-3 d-inline-block float-right" id="criticalper" >
                                                <span class="accu-onhand-vl ml-3">{(this.state?.criticalOutofStock?.totalStock * 100 / this.state.sohqty).toFixed(2) === "NaN" ? "0" : (this.state?.criticalOutofStock?.totalStock * 100 / this.state.sohqty).toFixed(2)}%</span>
                                            </h3>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment >

        )
    }
}
export default withRouter(TargetStockCountDashboard)