





import { Autocomplete, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import axios from "axios";
import _ from "lodash";
import React, { Component } from "react";
import { BiMenu } from 'react-icons/bi';
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import StockSummaryModal from "../components/StockSummaryModal";
import { withRouter } from "../components/withRouter";
import { getstores, PathCustom, PathCustomCycleCount, reqInstance } from "../Path/Path";
import CountDetails from "./CountDetails";
import { validateDate } from "@mui/x-date-pickers/internals";
class SupplyChain extends Component {
    state = {
        storeId: [],
        destinationData: [],
        status: [],
        CountedItems: {},
        allCounted: [],
        AssetsBySoh: [],
        allSOH: [],
        storeData: {},
        cronStatus: false,
        storeArray: null,
        loading: false,
        onZeroToggle: false,
        uniqueCount: [],
        stats: {
            totalCountedItems: 0,
            totalSOH: 0,
            itemCountAccuracy: 0,
        },
        store: '',
        csvDataReal: [],
        csvDataRealFake: [],
        unders: {},
        overs: { totalOvers: 0 },
        matched: { totalMatched: 0 },
        sohqty: 0,
        counted: {},
        criticalOutofStock: {},
        stores: '',
        operationalStats: 0,
        departmentID: [],
        totalSOH: [],
        itemAccuracy: 0,
        open: false,
        zonesData: [],
        undersToggle: false,
        oversToggle: false,
        department: '',
        stockToggle: false,
        onHandToggle: false,
        createdDate: null,
        datesArray: [],
        extraSOH: [],
        Color: '',
        Brand: '',
        Size: '',
        ColorList: [],
        BrandList: [],
        SizeList: [],
        highlightedDays: [1, 10, 12, 21]
    }
    gettingStores = async () => {

        const stores = await getstores();
        await this.setState({ storeArray: stores })

    }
    gettingDates = async () => {
    let data = null;
    try {
        const res = await reqInstance.get(`${PathCustom}store/get/soh/dates`);
        data = res?.data?.data;

        this.setState({ datesArray: data !== null ? data : [] }, () => {
        });
    } catch (err) {
        console.log(err);
    }
}

    isToday = (date) => {
        const today = this.formatDate(new Date());
        let newDate = date
        // 👇️ Today's date
        if (today === newDate) {
            return true;
        }

        return false;
    }
    statsFunction = async () => {
        this.setState({ loading: true })
        const createdData = this.formatDate(this.state.createdDate) === "1970/01/01" ? '' : this.formatDate(this.state.createdDate)

        const rawBody =
        {
            "StoreID": this.state.store,
            "date": createdData,
            // 'isToday': this.isToday(createdData)
        }

        const getDescripency = await axios.post(`${PathCustomCycleCount}store/get/soh/stats?StoreID=${this.state.store}&DepartmentID=${this.state.department}&Color=${this.state.Color}&Brand=${this.state.Brand}&Size=${this.state.Size}`, rawBody)
        // const getDescripency = await axios.post(`https://virgin-backend1.innovent.site/api/v1/store/get/stats/soh?StoreID=${this.state.store}&DepartmentID=${this.state.department}&Color=${this.state.Color}&Brand=${this.state.Brand}&Size=${this.state.Size}&createdDate=${createdData}`)
        const discripencyData = await getDescripency?.data?.data?.SOHs
        const extraSOH = await getDescripency?.data?.data?.extraSOH
        // const counted = await getCountedPerStore({ "StoreID": this.state.store })
        // console.log(counted, 'counted');
        var unders = { totalUnders: 0, undersData: [] }
        var overs = { totalOvers: 0, oversData: [] }
        var matched = { totalMatched: 0, matchedData: [] }
        var zonesData = { salesFloor: 0, groundFloor: 0 }
        var criticalOutofStock = { totalStock: 0, stockData: [] }
        var sohqty = 0
        var totalSOH = []
        var unknownData = []
        let extra = []
        this.setState({ csvDataReal: discripencyData, csvDataRealFake: discripencyData, extraSOH: extraSOH })
        for (let index = 0; index < discripencyData.length; index++) {
            const element = discripencyData[index];
            if (element?.matched !== 0) {
                matched = { totalMatched: matched.totalMatched + element?.matched, matchedData: [...matched.matchedData, element] }
            }
            if (element?.noExtra === 1) {
                extra = [...extra, element]
            }
            if (element?.overs !== 0) {
                overs = { totalOvers: overs?.totalOvers + element?.overs, oversData: [...overs.oversData, element] }
            }
            if (element?.SOHQTY !== 0) {
                if (element?.SOHQTY - element.matched) {
                    unders = { totalUnders: (element?.SOHQTY - element.matched) + unders.totalUnders, undersData: [...unders.undersData, element] }
                }
            }

            if (element?.SOHQTY !== 0) {
                sohqty = sohqty + element?.SOHQTY
                totalSOH = [...totalSOH, element]

            }

            if (element?.zone)
                if (typeof element?.zone?.SalesFloor === "undefined") {
                    unknownData.push(element)
                }
            zonesData = {
                salesFloor:
                    typeof element?.zone?.SalesFloor !== "undefined" ?
                        (element?.zone?.SalesFloor + zonesData.salesFloor)
                        : (0 + zonesData.salesFloor),
                groundFloor:
                    typeof element?.zone?.StockRoom !== "undefined" ?
                        (element?.zone?.StockRoom + zonesData.groundFloor)
                        : (0 + zonesData.groundFloor)
            }
            if (element?.SOHQTY !== 0 && element.matched === 0) {
                criticalOutofStock = { totalStock: element?.SOHQTY + criticalOutofStock.totalStock, stockData: [...criticalOutofStock.stockData, element] }
            }

        }

        console.log(matched?.matchedData?.filter((item => item?.DepartmentID === 'BOOKS')), 'BOOKS')
        // console.log(unders, 'unders');
        // console.log(overs, 'overs');
        // console.log(matched, 'matched');
        // console.log(sohqty, 'sohqty');
        // let salesFloor = 0
        // const dadadad = discripencyData.filter((item => item?.zone && item?.zone?.SalesFloor !== 0))
        // for (let index = 0; index < dadadad.length; index++) {
        //     const element = dadadad[index];
        //     salesFloor = element.zone?.SalesFloor + salesFloor

        // }
        const operationalStats = ((matched?.totalMatched / sohqty) * 100).toFixed(2)
        const itemAccuracy = (((matched?.totalMatched + overs?.totalOvers + extraSOH?.length) / sohqty) * 100).toFixed(2)
        this.setState({
            unders: unders,
            overs: overs,
            matched: matched,
            sohqty: sohqty,
            totalSOH: totalSOH,
            // counted: counted,
            operationalStats: operationalStats,
            zonesData: zonesData,
            itemAccuracy: itemAccuracy,
            criticalOutofStock: criticalOutofStock,


        })
        console.log(extra, 'extraextra');
        this.setState({ loading: false })
    }

    async componentDidMount() {
        this.setState({ loading: true })
        await this.gettingStores()
        await this.gettingDates()
        await this.storeHandler();

        // await this.statsFunction()


        // const getStats = await axios.post(`${localServer}store/get/stats/soh`)
        // console.log(getStats?.data?.data, 'stats');
        // const stats = await getStats?.data?.data
        // for (let index = 0; index < stats.length; index++) {
        //     const element = array[index];

        // }

        this.setState({ loading: false })

    }

    storeHandler = async (data) => {
        this.setState({ store: data })
        this.setState({ storeData: data });
        var raw = { "StoreID": data };
        const filters = await axios.get(`${PathCustom}store/get/soh/filters`)
        const newFilters = filters?.data?.data
        let newFiltersDta = []
        let mangageFilter = []
        let brand = []
        let color = []
        let size = []
        let style = []

        var department = _.uniq(newFilters?.departments?.filter((item => item)));
        var brandList = _.uniq(newFilters?.brands?.filter((item => item)));
        var colorList = _.uniq(newFilters?.colors?.filter((item => item)));
        var sizeList = _.uniq(newFilters?.sizes?.filter((item => item)));
        var styleList = _.uniq(newFilters?.syles?.filter((item => item)));
        // for (let index = 0; index < newFilters.length; index++) {
        //     const element = newFilters[index];
        //     for (let index = 0; index < element.length; index++) {
        //         const elementTwo = element[index];
        //         console.log(elementTwo, 'elementTwo');
        //         newFiltersDta.push(elementTwo)

        //     }

        // }

        // var uniq = [...new Set(newFiltersDta)];
        console.log(
            department,
            brandList,
            colorList,
            sizeList, 'zdfgfdgz');
        await this.setState({
            departmentID: department,
            BrandList: brandList,
            ColorList: colorList,
            SizeList: sizeList,
        })
        // console.log(un   iq, 'asd');
        this.setState({ loading: false })

    }
    departmentHandler = async (data) => {
        await this.setState({ department: data})
    }

    searchFunction = async () => {
        if (this.state.store === '' || this.state.store === null || this.state.store === undefined || this.state.createdDate === null) {
            toast.error("Please select Store and Date")
        } else {
            // const cronStatus = await getCronStatus()
            // await this.setState({ cronStatus: cronStatus?.data?.status })
            // console.log(cronStatus?.data?.status, "\cronStatus");
            this.statsFunction()

        }
    }


    UndersOpen = () => {
        this.setState({ open: true })
        this.setState({
            undersToggle: true,
            oversToggle: false,
            stockToggle: false,
            onHandToggle: false,
            onZeroToggle: false,
        })
    };
    OversOpen = () => {
        this.setState({ open: true })
        this.setState({
            undersToggle: false,
            oversToggle: true,
            stockToggle: false,
            onHandToggle: false,
            onZeroToggle: false,
        })
    };
    StockOpen = () => {
        this.setState({ open: true })
        this.setState({
            undersToggle: false,
            oversToggle: false,
            stockToggle: true,
            onHandToggle: false,
            onZeroToggle: false,
        })
    };
    ZeroSOHOpen = async () => {
        let finalResult = []
        if (this.state.extraSOH.length !== 0) {
            let uniqueCount = this.state.extraSOH?.map((item => item?.SKU));
            var count = {};
            uniqueCount.forEach(function (i) { count[i] = (count[i] || 0) + 1; });
            finalResult = await Object.entries(count).map((item => { return { sku: item[0], qty: item[1] } }))
        }
        this.setState({ uniqueCount: finalResult })
        this.setState({ open: true })
        this.setState({
            undersToggle: false,
            oversToggle: false,
            stockToggle: false,
            onHandToggle: false,
            onZeroToggle: true,
        })
    };
    handleClickOpen = () => {
        this.setState({ open: true })
    };
    onHandOpen = () => {
        this.setState({ open: true })
        this.setState({
            undersToggle: false,
            oversToggle: false,
            stockToggle: false,
            onHandToggle: true,
            onZeroToggle: false,
        })
    };

    handleClose = () => {
        this.setState({ open: false })
    };
    formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
        // return console.log([year, month, day].join('-'))
    }
    // shouldDisableDate = (date) => {
    //     const formatDate = (date) => {
    //         console.log("date in formate",date)
    //         var d = new Date(date),
    //             month = '' + (d.getMonth() + 1),
    //             day = '' + d.getDate(),
    //             year = d.getFullYear();

    //         if (month.length < 2)
    //             month = '0' + month;
    //         if (day.length < 2)
    //             day = '0' + day;

    //         return [year, month, day].join('-');
    //         // return console.log([year, month, day].join('-'))
    //     }
    //     let datas = this.state.datesArray.map((item => formatDate(item)))

    //     return !datas.includes(formatDate(new Date(date).toLocaleString()))
    //     // return console.log();
    // }

    render() {
        // const { storeId, destinationData, status, data } = this.state;

        return (
            <React.Fragment>
                <StockSummaryModal
                    closeHandler={() => this.handleClose()}
                    openHandler={() => this.handleClickOpen()}
                    modalOpen={this.state.open}

                >
                    {this.state.undersToggle ?
                        <CountDetails data={this.state.unders.undersData} heading={'Unders'} />
                        : null}
                    {this.state.oversToggle ?
                        <CountDetails data={this.state.overs.oversData} heading={'Overs'} />
                        : null}
                    {this.state.stockToggle ?
                        <CountDetails data={this.state.criticalOutofStock.stockData} heading={'Out Of Stock'} />
                        : null}
                    {this.state.onHandToggle ?
                        <CountDetails data={this.state.totalSOH} heading={'On Hand'} />
                        : null}
                    {this.state.onZeroToggle ?
                        <CountDetails data={this.state.uniqueCount} heading={'Zero Stock / No Soh'} />
                        : null}
                </StockSummaryModal>
                <Loader loading={this.state.loading} />
                <div className="dashboard-main">
                    <div className="dashboard-top" style={{ alignItems: 'center' }}>
                        <div> <p className="stock-count" style={{ fontSize: '25px', margin: 0 }}>Stock Count Summary</p></div>
                        {/* {this.state.cronStatus ?
                            <div style={{ display: 'flex', alignItems: 'center', gridColumnGap: 20 }}>
                                <span>Result May Vary <br /> Please Wait System is Updating</span>
                                <MoonLoader color='black' size={20} loading={this.state.cronStatus} />
                            </div> : null
                        } */}

                    </div>
                    <div className="dashboard-bottom">
                        <div> <p className="filter-text"> Store ID: {this.state.store === '' ? "No Store Selected" : this.state.store}</p></div>
                        <div className="drop-data" style={{ gridRowGap: '20px' }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', gridColumnGap: '20px', gridRowGap: '20px', flexWrap: 'wrap' }}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    size={'small'}
                                    // getOptionLabel={(option) => option?.Warehouse}
                                    // options={[{ StoreID: 'R0126' }]}
                                    options={this.state.storeArray?.map((item => item?.Warehouse)).reduce((noDupArr, entry) => {
                                        if (noDupArr?.includes(entry)) {
                                            return noDupArr;
                                        } else {
                                            return [...noDupArr, entry];
                                        }
                                    }, []).filter((item => item !== null && item !==undefined))}
                                    onChange={(e, v) => this.storeHandler(v === null || v === undefined ? '' : v)}
                                    sx={{ width: 235 }}
                                    renderInput={(params) => <TextField
                                        {...params} label="Store Code" />}
                                />
                                <DesktopDatePicker
                                    label="Created Date"
                                    inputFormat="DD/MM/YYYY"
                                    clearable
                                    // shouldDisableDate={(date) => this.shouldDisableDate(date)}
                                    // renderDay={(day, _value, DayComponentProps) => {
                                    //     const isSelected =
                                    //         !DayComponentProps.outsideCurrentMonth &&
                                    //         this.state.datesArray.map((item => this.formatDate(item))).includes(this.formatDate(day));
                                    //     return (
                                    //         <Badge
                                    //             key={day.toString()}
                                    //             overlap="circular"
                                    //             badgeContent={isSelected ? <BiScan size={13} /> : undefined}
                                    //         >
                                    //             <PickersDay {...DayComponentProps} />

                                    //         </Badge>

                                    //     );
                                    // }}
                                    // // disablePast
                                    // disableFuture
                                    size={'small'}
                                    value={this.state.createdDate}
                                    onChange={(data) => this.setState({ createdDate: data })}
                                    componentsProps={{
                                    actionBar: {
                                        actions: ["clear"],
                                    },
                                    }}
                                    renderInput={(params) => <TextField sx={{ width: 170 }} size={'small'} {...params} />}
                                />
                                {/* <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    size={'small'}

                                    options={this.state.departmentID}
                                    onChange={(e, v) => this.departmentHandler(v === null || v === undefined ? '' : v)}
                                    sx={{ width: 235 }}
                                    renderInput={(params) => <TextField
                                        {...params} label="Department" />}
                                /> */}
                                {/* <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    size={'small'}

                                    options={this.state.ColorList}
                                    onChange={(e, v) => this.setState({ Color: v === null || v === undefined ? '' : v })}
                                    sx={{ width: 235 }}
                                    renderInput={(params) => <TextField
                                        {...params} label="Color" />}
                                /> */}
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    size={'small'}

                                    options={this.state.BrandList}
                                    onChange={(e, v) => this.setState({ Brand: v === null || v === undefined ? '' : v })}
                                    sx={{ width: 235 }}
                                    renderInput={(params) => <TextField
                                        {...params} label="Brand" />}
                                />
                                {/* <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    size={'small'}

                                    options={this.state.SizeList}
                                    onChange={(e, v) => this.setState({ Size: v === null || v === undefined ? '' : v })}
                                    sx={{ width: 235 }}
                                    renderInput={(params) => <TextField
                                        {...params} label="Size" />}
                                /> */}

                            </div>
                            <button onClick={() => this.searchFunction()} className="run-btn"><p className="run-text">Search</p></button>

                        </div>
                        <div>

                        </div>
                        <div style={{ paddingTop: "2%" }}>
                            <div class="row ml-0 mr-0" id="CountDynamic">
                                <div class="col-md-8">
                                    <div class="row">
                                        <div class="col-md-6 pl-1 pr-1 pr-md-2">
                                            <div class="card-box p-3 top-cards item-accuracy-card">
                                                <h4>Item-Count <span>Accuracy</span>
                                                </h4>
                                                <h2 class="text-center" style={{ width: '100%' }}>

                                                    <span id="item_accuracy" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                        <p>{this.state.itemAccuracy === "NaN" ? "0" : this.state.itemAccuracy} %</p>
                                                    </span>
                                                </h2>
                                            </div>
                                        </div>
                                        <div class="col-md-6 pl-1 pr-1 pl-md-2">
                                            <div class="card-box p-3 top-cards item-accuracy-card">
                                                <h4>Operational <span>Accuracy</span>
                                                </h4>
                                                <h2 class="text-centerOn Hand Matching" style={{ width: '100%' }}>

                                                    <span id="operational_accuracy" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                        <p>{this.state.operationalStats === "NaN" ? "0" : this.state.operationalStats} %</p>
                                                    </span>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-4 pl-1 pr-1 pr-md-2">
                                            <div style={{ height: '220px' }} class="card-box p-4 item-accuracy-count" >
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                                                    <h4 class="d-inline-block mb-0" style={{ marginTop: '0px' }}>On Hand</h4>
                                                    <span onClick={() => this.onHandOpen()} style={{cursor:"pointer"}}><BiMenu fontSize={30} /></span>
                                                </div>
                                                <a href="executiveSummaryOnHandSimple?date=2022-09-19&amp;store_id=0009901&amp;show_over=no" target="_blank" class="OneHandSimple" style={{ cursor: "pointer" }}>
                                                </a>
                                                <h2 class="text-center mb-4 mt-4 lower-font" style={{ width: '100%' }}>

                                                    <span id="onehand" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                        <span class="accu-onhand-vl">{this.state.sohqty === "NaN" || this.state.sohqty === NaN ? "0" : this.state.sohqty}</span>
                                                    </span>
                                                </h2>
                                            </div>
                                        </div>
                                        <div class="col-md-4 pl-1 pr-1 pr-md-2">
                                            <div style={{ height: '220px' }} class="card-box p-4 item-accuracy-count">
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                                                    <h4 class="d-inline-block mb-0" style={{ marginTop: '0px' }}>Count</h4>
                                                    <span style={{ visibility: 'hidden' }} onClick={() => this.onHandOpen()} ><BiMenu fontSize={30} /></span>
                                                </div>
                                                <a href="executiveSummaryOnHandSimple?date=2022-09-19&amp;store_id=0009901&amp;show_over=no" target="_blank" class="OneHandSimple" style={{ cursor: "pointer" }}>
                                                </a>
                                                <h2 class="text-center mb-4 mt-4 lower-font" style={{ width: '100%' }}>

                                                    <span id="onehand" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                        <span class="accu-onhand-vl">{(this.state.matched.totalMatched + this.state.overs.totalOvers) === NaN ? '0' : (this.state.matched.totalMatched + this.state.overs.totalOvers)}</span>
                                                    </span>
                                                </h2>
                                            </div>
                                        </div>

                                        <div class="col-md-4 pl-1 pr-1 pl-md-2">
                                            <div class="card-box px-3 py-2 item-accuracy-front-back">
                                                <h4 class="d-inline-block mb-0">Front</h4>
                                                <a href="executiveSummaryFront?date=2022-09-19&amp;store_id=0009901&amp;show_over=no" target="_blank" class="Front">
                                                </a>
                                                <h2 class="text-center mb-1 mt-1 sec-lower-font">

                                                    <span id="frontval">
                                                        <span class="ftbk-vl">{this.state.zonesData?.salesFloor ? this.state.zonesData?.salesFloor : 0}</span>
                                                    </span>
                                                </h2>
                                            </div>
                                            <div class="card-box px-3 py-2 mt-2 item-accuracy-front-back">
                                                <h4 class="d-inline-block mb-0">Back Store</h4>
                                                <a href="executiveSummaryBack?date=2022-09-19&amp;store_id=0009901&amp;show_over=no" target="_blank" class="BackStore">
                                                </a>
                                                <h2 class="text-center mb-1 mt-1 sec-lower-font">

                                                    <span id="counted_sr">
                                                        <span class="ftbk-vl">{this.state.zonesData?.groundFloor ? this.state.zonesData?.groundFloor : 0}</span>
                                                    </span>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="card-box-dark p-3 mb-2 black-right-card">
                                        <h4 class="d-inline-block mb-0 text-light" style={{ marginTop: '5px' }}>On Hand Matching</h4>
                                        <div>
                                            <h3 class="mb-0 d-inline-block">
                                                <span class="small-text">Count</span>

                                                <span id="onehand2" style={{ marginLeft: 20 }}>
                                                    <span class="accu-onhand-vl">{this.state.matched?.totalMatched}</span>
                                                </span>
                                            </h3>
                                            <h3 class="mb-0 d-inline-block float-right">
                                                <span></span>

                                                <span id="item_accuracy2" class="mt-2 ml-3" style={{ paddingTop: '10px' }}>

                                                    <p>{(this.state.matched?.totalMatched * 100 / this.state.sohqty).toFixed(2) === 'NaN' ? '0' : (this.state.matched?.totalMatched * 100 / this.state.sohqty).toFixed(2)}%</p>
                                                </span>
                                            </h3>
                                        </div>
                                    </div>
                                    <div class="card-box-dark p-3 mb-2 black-right-card">
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <h4 class="d-inline-block mb-0 text-danger" style={{ marginTop: '5px' }}>Unders</h4>
                                            <span onClick={() => this.UndersOpen()} style={{cursor:"pointer"}}><BiMenu fontSize={30} /></span>

                                        </div>
                                        <a href="underOvers?date=2022-09-19&amp;store_id=0009901&amp;show_over=no" target="_blank" style={{ cursor: "pointer" }} class="Unders">
                                        </a>
                                        <div>
                                            <h3 class="mb-0 d-inline-block">
                                                <span class="small-text">Count</span>

                                                <span id="undernum" style={{ marginLeft: 20 }}>
                                                    <p>{this.state.unders?.totalUnders}</p>
                                                </span>
                                            </h3>
                                            <h3 class="mb-0 d-inline-block float-right">


                                                <span id="underper" class="d-inline-block ml-3" >
                                                    <p>{(this.state.unders?.totalUnders * 100 / this.state.sohqty).toFixed(2) === 'NaN' ? '0' : (this.state.unders?.totalUnders * 100 / this.state.sohqty).toFixed(2)}%</p>
                                                </span>
                                            </h3>
                                        </div>
                                    </div>
                                    <div class="card-box-dark p-3 mb-2 black-right-card">
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <h4 class="d-inline-block mb-0 text-success" style={{ marginTop: '5px' }}>Overs</h4>
                                            <span onClick={() => this.OversOpen()} style={{cursor:"pointer"}}><BiMenu fontSize={30} /></span>
                                        </div>


                                        <div>
                                            <h3 class="mb-0 d-inline-block">
                                                <span class="small-text">Count</span>

                                                <span id="expectednum" style={{ marginLeft: 20 }}>
                                                    <p>{this.state.overs?.totalOvers}</p>
                                                </span>
                                            </h3>
                                            <h3 class="mb-0 d-inline-block float-right">


                                                <span id="expectedper" class="d-inline-block ml-3"  >
                                                    <p>{(this.state.overs?.totalOvers * 100 / this.state.sohqty).toFixed(2) === "NaN" ? "0" : (this.state.overs?.totalOvers * 100 / this.state.sohqty).toFixed(2)}%</p>
                                                </span>
                                            </h3>
                                        </div>
                                    </div>
                                    <div class="card-box-dark p-3 mb-2 black-right-card">
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <h4 class="d-inline-block mb-0 text-danger" style={{ marginTop: '5px' }}>Critical Out Of Stock</h4>
                                            <span onClick={() => this.StockOpen()} style={{cursor:"pointer"}}><BiMenu fontSize={30} /></span>
                                        </div>

                                        <a href="CriticalOutOfStock?date=2022-09-19&amp;store_id=0009901&amp;show_over=no" target="_blank" style={{ cursor: "pointer" }} class="CriticalOutOfStock">
                                        </a>
                                        <div>
                                            <h3 class="mb-0 mt-3 d-inline-block">
                                                <span class="small-text">Count</span>
                                                <span id="CriticalStock" style={{ marginLeft: 20 }}>
                                                    <span class="accu-onhand-vl">{this.state.criticalOutofStock?.totalStock === "NaN" || this.state.criticalOutofStock?.totalStock === "NaN" ? "0" : this.state.criticalOutofStock?.totalStock}</span>
                                                </span>
                                            </h3>
                                            <h3 class="mb-0 mt-3 d-inline-block float-right" id="criticalper" >
                                                <span class="accu-onhand-vl ml-3">{(this.state?.criticalOutofStock?.totalStock * 100 / this.state.sohqty).toFixed(2) === "NaN" ? "0" : (this.state?.criticalOutofStock?.totalStock * 100 / this.state.sohqty).toFixed(2)}%</span>
                                            </h3>
                                        </div>
                                    </div>

                                    <div class="card-box-dark p-3 mb-2 black-right-card">
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <h4 class="d-inline-block mb-0 text-danger" style={{ marginTop: '5px' }}>Zero Stock / No Soh</h4>
                                            <span onClick={() => this.ZeroSOHOpen()} style={{cursor:"pointer"}}><BiMenu fontSize={30} /></span>
                                        </div>

                                        <a href="CriticalOutOfStock?date=2022-09-19&amp;store_id=0009901&amp;show_over=no" target="_blank" style={{ cursor: "pointer" }} class="CriticalOutOfStock">
                                        </a>
                                        <div>
                                            <h3 class="mb-0 mt-3 d-inline-block">
                                                <span class="small-text">Count</span>
                                                <span id="CriticalStock" style={{ marginLeft: 20 }}>
                                                    <span class="accu-onhand-vl">{this.state.extraSOH?.length}</span>
                                                </span>
                                            </h3>
                                            <h3 class="mb-0 mt-3 d-inline-block float-right" id="criticalper" >
                                                <span class="accu-onhand-vl ml-3">{(this.state?.extraSOH?.length * 100 / this.state.sohqty).toFixed(2) === "NaN" ? "0" : (this.state?.extraSOH?.length * 100 / this.state.sohqty).toFixed(2)}%</span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment >

        )
    }
}
export default withRouter(SupplyChain)