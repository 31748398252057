import React, { Component } from "react";

import print from "../../assets/print.png"
import zoom from "../../assets/zoom.png"
import download from "../../assets/download.png"
import MyInput from "../../components/MyInput";
import Dropdown from "../../components/Dropdown";
import { addstorepost, adduserPost, editUser, getRoles, getStores } from "../../Path/Path";
import { withRouter } from "../../components/withRouter";
import { toast } from "react-toastify"
import Loader from "../../components/Loader";
import { Autocomplete, TextField } from '@mui/material'
class AddUser extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: "",
            userName: "",
            password: "",
            status: "",
            role: [],
            selectRole: "",
            storeName: [],
            storeNameList: [],
            dropStore: "",
            dropRole: "",
            data: [],
            allStores: [],
            showStatus: false,
            showRole: false,
            showStoreId: false,
            loading: false,
        }
    }

    async componentDidMount() {
        this.setState({ loading: true })
        const data = await getRoles();
        const store = await getStores();
        const storesName = store.map((elem) => elem.storename)
        const roleName = data.map((elem) => elem.role_name)
        this.setState({ storeNameList: storesName, data: data, role: roleName, allStores: store })
        // const fil = data.find((i) => i._id === this.props.location.state.item.roleId)

        // this.setState({ slectrole: fil?.role_name })
        // this.setState({ storeName: storesName })
        this.setState({ loading: false })
        if (this.props.location.state?.item) {

            const item = this.props.location.state.item
            const multiple_stores = JSON.parse(item?.status)
            this.setState({
                name: item?.name,
                userName: item?.userName,
                password: item?.password,
                selectRole: item?.roleId?.role_name,
                storeName: multiple_stores === 1 ? [] : (multiple_stores || []),
            })
        }

    }

    sendHandler = async () => {

        try {
            const roleId = this.state.data.find(i => i.role_name === this.state.selectRole)
            const storeId = this.state.allStores.find(i => i.storename === this.state.storeName[0])
            const body = {
                "name": this.state.name,
                "userName": this.state.userName,
                "password": this.state.password,
                "siteId": storeId?._id,
                "status": JSON.stringify(this.state.storeName),
                "last_login": JSON.stringify(this.state.storeName),
                "roleId": roleId?._id
            }
            if (this.state.name === "") {
                toast.warn("Enter Name")
            }
            else if (this.state.userName === "") {
                toast.warn("Enter User Name")
            }
            else if (this.state.password === "") {
                toast.warn("Enter Password")
            }
            else if (this.state.selectRole === "") {
                toast.warn("Select Role")
            }
            else if (this.state.storeName?.length === 0) {
                toast.warn("Kindly assign a store to the user")
            }
            else if (this.props.location?.state?.item) {


                await editUser(body, this.props.location.state.item._id)
                this.props.history("/users");
                toast.success("User edit successfully")
            }

            else {
                this.setState({ loading: true })
                const res = await adduserPost(body)
                console.log(res, "resresresresresres")
                this.setState({ loading: false })
                if (res?.success === false) {
                    toast.warn(res?.error)
                    return
                }
                if (res?.error) {
                    toast.warn("Something went wrong")
                } else {
                    toast.success("User added succesfully")
                    this.props.history("/users");
                }
            }

        } catch (error) {
            console.log(error, "eorrtjkfgnekrgrig")
            toast(error?.response?.data?.error || "Something went wrong")
            this.setState({ loading: false })
        }





    }

    render() {

        const { name, userName, password, allStores = [] } = this.state
        const editMode = !!this.props.location?.state?.item

        return (
            <>
                <Loader loading={this.state.loading} />
                <div className="dashboard-main">
                    <div className="dashboard-top">
                        <div> <p className="stock-count">{editMode ? "Edit User" : "Add User"}</p></div>

                    </div>
                    <div style={{ paddingInline: 20, marginTop: 20, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                        {/* <div> <p className="filter-text">Filters</p></div> */}

                        <TextField
                            sx={{ width: 235 }}
                            defaultValue={this.state.name}
                            value={name}
                            placeholder="Name"
                            size={'small'}
                            onChange={(data) => this.setState({ name: data.target.value })} id="outlined-basic" label="Name" variant="outlined" />
                        <TextField
                            sx={{ width: 235 }}
                            defaultValue={this.state.userName}
                            value={userName}
                            placeholder="User Name"
                            size={'small'}
                            onChange={(data) => this.setState({ userName: data.target.value })} id="outlined-basic" label="User Name" variant="outlined" />
                        {
                            !editMode &&
                            <TextField
                                sx={{ width: 235 }}
                                defaultValue={this.state.password}
                                value={password}
                                placeholder="Password"
                                size={'small'}
                                onChange={(data) => this.setState({ password: data.target.value })} id="outlined-basic" label="Password" variant="outlined" />
                        }

                    </div>

                    <div style={{ paddingInline: 20, marginTop: 15, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            size={'small'}
                            value={this.state.selectRole}
                            options={this.state.data?.map((item => item?.role_name)).reduce((noDupArr, entry) => {
                                if (noDupArr?.includes(entry)) {
                                    return noDupArr;
                                } else {
                                    return [...noDupArr, entry];
                                }
                            }, [])?.filter((item => item !== null && item !== undefined))}
                            onChange={(e, v) => this.setState({ selectRole: v === null || v === undefined ? '' : v })}
                            sx={{ width: 235 }}
                            renderInput={(params) => <TextField
                                {...params} label="Roles" />}
                        />
                        <Autocomplete
                            multiple={true}
                            disablePortal
                            disableCloseOnSelect={true}
                            id="combo-box-demo"
                            size={'small'}
                            value={this.state?.storeName || null}
                            getOptionLabel={(option) => option ? option : this.state?.storeName}
                            options={this.state.allStores?.map((item => item?.Name)).reduce((noDupArr, entry) => {
                                if (noDupArr?.includes(entry)) {
                                    return noDupArr;
                                } else {
                                    return [...noDupArr, entry];
                                }
                            }, [])?.filter((item => item !== null && item !== undefined))}
                            onChange={(e, v) => this.setState({ storeName: v === null || v === undefined ? '' : v })}
                            sx={{ width: 235 }}
                            renderInput={(params) => <TextField
                                {...params} label="Stores" />}
                        />

                    </div>
                    <div style={{ paddingLeft: 20, marginTop: 20, marginBottom: 20 }} >
                        <button className="run-btn"><p className="run-text" onClick={() => this.sendHandler()}>{editMode ? "Edit User" : "Add User"}</p></button>
                    </div>
                </div>

            </>
        )
    }
}

export default withRouter(AddUser);