import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core';


const ZplModal = ({ open, onClick, Heading, children, width, height }) => {
    return (
        <Dialog onClose={onClick} aria-labelledby='dialog-title' aria-describedby='dialog-description' open={open}>
            <DialogTitle id="dialog-title">{Heading}</DialogTitle>
            <DialogContent>
                <div style={{ minHeight: height || '400px', width: width || '400px' }}>
                    {children}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClick}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ZplModal
