import * as React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { IoMdClose } from "react-icons/io";
import Slide from "@mui/material/Slide";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { capitalizeFirstLetter } from "../screens/common/capitalizeFirstLetter";
import { Autocomplete, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import DrillDetailModal from "./DrillDetailModal";
import { HeadAndRowDrillDownItems } from "../components/Helper";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DrillDownModal({
  heading,
  modalOpen,
  data,
  closeHandler,
  tableTitle,
  HeadAndRow,
  links,
}) {
  const [visible, setVisible] = React.useState(false);
  const [selectedCarton, setSelectedCarton] = React.useState([]);
  const [itemsArray, setItemsArray] = React.useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const [isRadioCart, setIsRadioCart] = useState(false);
  const [isRadioEAN, setIsRadioEAN] = useState(false);
  const [isNone, setIsNone] = useState(false);
  const [cartonNo, setCartonNo] = useState("");
  const [EAN, setEAN] = useState("");

  const onCartonClick = (e, cartonNo, EAN) => {
    e.preventDefault();
    try {
      const filterCarton = data.filter(
        (item) =>{
            if(isRadioCart && cartonNo){
                return item.cartonNo === cartonNo
            }
            return item.EAN === EAN
        }       
      );

      setSelectedCarton(filterCarton);
      setVisible(true);
    } catch (error) {
      console.error("Error", error);
    }
  };

  const filteredHeadAndRow = HeadAndRow.filter((item) => {
    if (isRadioCart) {
      return (
        item.column !== "state" &&
        item.column !== "process_status" &&
        item.column !== "EAN"
      );
    } else if (isRadioEAN) {
      return item.column !== "cartonNo";
    }
    return true; 
  });

  useEffect(() => {
    setItemsArray(data || []);
    handleRadioCLick();
  }, [data]);

  useEffect(() => {
    if (cartonNo === "" && EAN === "") {
      setFilteredItems(itemsArray);
      setIsSearched(false);
    }
  }, [cartonNo, EAN, itemsArray]);

  const searchArticleCode = () => {
    if (cartonNo === "" && EAN === "") {
      toast.error("Enter value to search");
    } else {
      const result = itemsArray.filter(
        (item) =>
          (cartonNo !== "" && item.cartonNo === cartonNo) ||
          (EAN !== "" && item.EAN === EAN)
      );
      setFilteredItems(result);
      setIsSearched(true);
    }
  };

  const handleRadioCLick = (val) => {
    if (val === "EAN") {
      setIsRadioEAN(true);
      setIsRadioCart(false);
      setIsNone(false);
    } else if (val === "cart") {
      setIsRadioCart(true);
      setIsRadioEAN(false);
      setIsNone(false);
    } else {
      setIsNone(true);
      setIsRadioEAN(false);
      setIsRadioCart(false);
    }
  };

  const groupByHandler = () => {
    if (!isRadioCart && !isRadioEAN) {
      return itemsArray;
    }

    const groupByKey = isRadioCart ? "cartonNo" : "EAN";
    return itemsArray.reduce((acc, item) => {
      const key = item[groupByKey];
      const existingItem = acc.find((i) => i[groupByKey] === key);

      if (existingItem) {
        existingItem.receivingProcessedQuantity +=
          item.receivingProcessedQuantity;
        existingItem.packedQty += item.packedQty;
      } else {
        acc.push({ ...item });
      }
      return acc;
    }, []);
  };

  const displayedItems = groupByHandler();

  return (
    <div>
      <Dialog
        fullScreen
        open={modalOpen}
        onClose={closeHandler}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeHandler}
              aria-label="close"
            >
              <IoMdClose />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {heading}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="dashboard-main" style={{ margin: "10px" }}>
          <div className="dashboard-top">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <p
                className="stock-count"
                style={{ fontSize: "25px", margin: 0 }}
              >
                {tableTitle}
              </p>
            </div>
          </div>
          <p style={{ margin: "10px 0px 0px 20px" }}>Filters</p>
          <div
            style={{
              display: "flex",
              width: "100%",
              gridColumnGap: "20px",
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "10px",
                flexWrap: "wrap",
                marginLeft: "15px",
                marginTop: "10px",
                width: "calc(80% - 280px)",
              }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                freeSolo={true}
                size={"small"}
                options={itemsArray
                  .filter((item) => item?.cartonNo !== "")
                  .map((item) => item.cartonNo)
                  .reduce((noDupArr, entry) => {
                    if (noDupArr.includes(entry)) {
                      return noDupArr;
                    } else {
                      return [...noDupArr, entry];
                    }
                  }, [])}
                onChange={(e, v) => setCartonNo(v === null ? "" : v)}
                onInputChange={(e) =>
                  setCartonNo(
                    e.target?.value === 0 || e.target?.value === undefined
                      ? ""
                      : e.target?.value
                  )
                }
                sx={{ width: 200 }}
                renderInput={(params) => (
                  <TextField {...params} label="Carton Number" />
                )}
              />
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                freeSolo={true}
                size={"small"}
                options={itemsArray
                  .filter((item) => item?.EAN !== "")
                  .map((item) => item.EAN)
                  .reduce((noDupArr, entry) => {
                    if (noDupArr.includes(entry)) {
                      return noDupArr;
                    } else {
                      return [...noDupArr, entry];
                    }
                  }, [])}
                onChange={(e, v) => setEAN(v === null ? "" : v)}
                onInputChange={(e) =>
                  setEAN(
                    e.target?.value === 0 || e.target?.value === undefined
                      ? ""
                      : e.target?.value
                  )
                }
                sx={{ width: 200 }}
                renderInput={(params) => <TextField {...params} label="EAN" />}
              />
            </div>

            <div>
              <div style={{ marginTop: "10px", marginRight: "15px" }}>
                <Button
                  variant="contained"
                  onClick={searchArticleCode}
                  style={{
                    background: "#0069D9",
                    padding: "10px",
                    width: "160px",
                    alignItems: "center",
                    height: "35px",
                    borderRadius: "10px",
                    textTransform: "none",
                  }}
                >
                  <p className="run-text">Search</p>
                </Button>
              </div>
            </div>
          </div>

          <div
            style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
          >
            <p
              className="drill-text"
              style={{
                margin: "10px 20px 0px 20px",
                fontWeight: 700,
                fontSize: 20,
                color: "#0069D9",
              }}
            >
              Group By:
            </p>
            <div className="form-group" style={{ margin: "10px 20px 0px 0px" }}>
              <input
                className="bulkIconsStyle"
                type="radio"
                checked={isNone}
                onChange={() => handleRadioCLick("none")}
              />
              <p className="drill-text">None</p>
            </div>

            <div className="form-group" style={{ margin: "10px 20px 0px 0px" }}>
              <input
                className="bulkIconsStyle"
                type="radio"
                checked={isRadioCart}
                onChange={() => handleRadioCLick("cart")}
              />
              <p className="drill-text">Carton</p>
            </div>

            <div className="form-group" style={{ margin: "10px 20px 0px 0px" }}>
              <input
                className="bulkIconsStyle"
                type="radio"
                checked={isRadioEAN}
                onChange={() => handleRadioCLick("EAN")}
              />
              <p className="drill-text">EAN</p>
            </div>
          </div>

          <div
            style={{ marginTop: "10px", overflowX: "scroll", height: "600px" }}
          >
            <table data-table-theme="dark zebra" style={{ marginTop: "20px" }}>
              <thead>
                <tr style={{ background: "#373E43" }}>
                  {filteredHeadAndRow?.length &&
                    filteredHeadAndRow?.map((item, index) => (
                      <th key={index} style={{ background: "#000" }}>
                        {item ? item?.header : "--"}
                      </th>
                    ))}
                </tr>
              </thead>
              {(isSearched ? filteredItems : displayedItems)?.map(
                (item, index) => (
                  <tbody key={index}>
                    <tr>
                      {filteredHeadAndRow?.map((name, idx) =>
                        links?.includes(name?.column) &&
                        (isRadioCart || isRadioEAN) ? (
                          <td key={idx}>
                            <Link
                              to="#"
                              onClick={(e) =>
                                onCartonClick(e, item.cartonNo, item.EAN)
                              }
                            >
                              {item[name?.column] || "--"}
                            </Link>
                          </td>
                        ) : (
                          <td key={idx}>
                            {name?.column === "process_status"
                              ? capitalizeFirstLetter(item[name?.column])
                              : item[name?.column] || "--"}
                          </td>
                        )
                      )}
                    </tr>
                  </tbody>
                )
              )}
            </table>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "10px",
              color: "white",
            }}
          >
            <h3>List Length: {displayedItems?.length}</h3>
          </div>
        </div>
        {visible && (
          <DrillDetailModal
            data={selectedCarton}
            modalOpen={!!selectedCarton}
            closeHandler={() => setSelectedCarton(null)}
            HeadAndRow={HeadAndRowDrillDownItems}
            tableTitle={"Drill Down Item Detail"}
          />
        )}
      </Dialog>
    </div>
  );
}