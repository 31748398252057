import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { GrDocumentCsv } from "react-icons/gr";
import Loader from "../components/Loader";
import { PaginatedItems } from "../components/Paginated";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { getSOH, localServer } from "../Path/Path";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { formatItem } from "./common/FormatItems";

const StockOnHand = () => {
  const [printingItems, setPrintingItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [toDate, setToDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [articleCode, setArticleCode] = useState("");
  const [storageLoc, setStorageLoc] = useState("");
  const [articleGtin, setArticleGtin] = useState("");
  const [paginationData, setPaginationData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    getPrintedItems();
  }, []);
  const validateDate = (date) => {
    const dateFormat = "MM/DD/YYYY";
    if (!date) {
      return true;
    }
    if (!moment(date, dateFormat, true).isValid()) {
      // toast.error("Invalid date format.");
      return false;
    }
    if (moment(date).isAfter(moment())) {
      return false;
    }
    return true;
  };

  const getPrintedItems = async (pageNumber = 1) => {
    const newFromDate = validateDate(fromDate);
    const newToDate = validateDate(toDate);
    if (!newFromDate || !newToDate) {
      toast.error("Please enter valid date ranges.");
      return;
    }
    setLoading(true);
    try {
      const params = {
        pageNo: pageNumber,
        pageSize : 10,
        Article_Code: articleCode,
        Storage_Loc: storageLoc,
        Article_GTIN: articleGtin,
        startDate: fromDate
          ? moment(fromDate).startOf("day").format("YYYY-MM-DD")
          : "",
        endDate: toDate
          ? moment(toDate).endOf("day").format("YYYY-MM-DD")
          : "",
      };
      const data = await getSOH(params);

      if (data && Array.isArray(data.data.data)) {
        const filtering = data?.data?.data;
        setPrintingItems(filtering);
        setPaginationData(data?.data);
        setCurrentPage(data?.page);
        setTotalDocuments(data.data.total);
        setItemsPerPage(data?.data?.size);
        setLoading(false);
        if (data?.data?.data?.length === 0) {
          toast.error("No data found.");
        } else {
          setCurrentPage(data.data.page);
          setTotalDocuments(data.data.total);
          setItemsPerPage(10);
        }
      } else {
        console.error("Unexpected API response structure:", data);
      }
    } catch (err) {
      console.error("Error fetching printed items:", err);
    } finally {
      setLoading(false);
    }
  };

  const getCSV = async () => {
    const newFromDate = validateDate(fromDate);
    const newToDate = validateDate(toDate);
    if (!newFromDate || !newToDate) {
      toast.error("Please enter valid date ranges.");
      return;
    }

    setLoading(true);
    try {
      const params = {
        Article_Code: articleCode,
        Storage_Loc: storageLoc,
        Article_GTIN: articleGtin,
        startDate: fromDate
          ? moment(fromDate).startOf("day").toISOString()
          : "",
        endDate: toDate ? moment(toDate).endOf("day").toISOString() : "",
      };
      const response = await axios.get(`${localServer}store/SOH/csv`, {
        params,
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "SOH_Report.csv");
      document.body.appendChild(link);

      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
    setLoading(false);
  };

  const headers = [
    "Created Time",
    "Article Code",
    "Stock Uom",
    "Storage Location",
    "Site",
    "Article GTIN",
    "Article",
    "Site Description",
    "Stock on Hand",
    "Stock Buom",
    "Article Conv Factor",
    "Stock on Date",
  ];
  const colNames = [
    "createdAt",
    "Article_Code",
    "Stock_Uom",
    "Storage_Loc",
    "Site",
    "Article_GTIN",
    "Article",
    "Site_Desc",
    "Stock_on_Hand",
    "Stock_Buom",
    "Article_Conv_Factor",
    "Stock_on_Date",
  ];
  const startItem = currentPage ? (currentPage - 1) * itemsPerPage + 1 : 0;
  const endItem = currentPage
    ? Math.min(currentPage * itemsPerPage, totalDocuments)
    : 0;

  return (
    <React.Fragment>
      <Loader loading={loading} />
      <div className="dashboard-main">
        <div
          className="dashboard-top"
          style={{ display: "flex", flexDirection: "column", padding: "10px" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                flex: "1",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p
                className="stock-count"
                style={{ fontSize: "25px", margin: 0 }}
              >
                {" "}
                Stock On Hand{" "}
              </p>
            </div>
            <GrDocumentCsv
              onClick={getCSV}
              color="black"
              fill="white"
              fontSize={25}
              style={{ marginRight: 5, cursor: "pointer" }}
            />
          </div>
          <div>
            <h1 style={{ color: "black", fontSize: "15px" }}>
              {new Date().toLocaleString("hi-IN")}
            </h1>
          </div>
        </div>
        <div className="dashboard-bottom" style={{ marginTop: "0.5%" }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              gridColumnGap: "20px",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                gridColumnGap: "10px",
                gridRowGap: "10px",
                flexWrap: "wrap",
              }}
            >
              <DesktopDatePicker
                label="From Date"
                clearable
                showTodayButton
                inputFormat="MM/DD/YYYY"
                size={"small"}
                maxDate={new Date()}
                value={fromDate}
                onChange={(data) => setFromDate(data)}
                componentsProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                renderInput={(params) => (
                  <TextField sx={{ width: 170 }} size={"small"} {...params} />
                )}
              />
              <DesktopDatePicker
                label="To Date"
                clearable
                showTodayButton
                inputFormat="MM/DD/YYYY"
                size={"small"}
                maxDate={new Date()}
                value={toDate}
                onChange={(data) => setToDate(data)}
                componentsProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                renderInput={(params) => (
                  <TextField sx={{ width: 170 }} size={"small"} {...params} />
                )}
              />
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                freeSolo={true}
                size={"small"}
                options={printingItems
                  ?.filter((item) => item?.Article_Code !== "" || item?.article_Code !== undefined)
                  .map((item) => item?.Article_Code)
                  .reduce((noDupArr, entry) => {
                    if (noDupArr.includes(entry)) {
                      return noDupArr;
                    } else {
                      return [...noDupArr, entry];
                    }
                  }, [])}
                onChange={(e, v) => setArticleCode(v === null ? "" : v)}
                onInputChange={(e) =>
                  setArticleCode(
                    e.target?.value === 0 || e.target?.value === undefined
                      ? ""
                      : e.target?.value
                  )
                }
                sx={{ width: 200 }}
                renderInput={(params) => (
                  <TextField {...params} label="Article Code" />
                )}
              />
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                freeSolo={true}
                size={"small"}
                options={printingItems
                  ?.filter((item) => item?.Storage_Loc !== "" && item?.Storage_Loc !== undefined)
                  .map((item) => item?.Storage_Loc)
                  .reduce((noDupArr, entry) => {
                    if (noDupArr.includes(entry)) {
                      return noDupArr;
                    } else {
                      return [...noDupArr, entry];
                    }
                  }, [])}
                onChange={(e, v) => setStorageLoc((v === null || v === undefined) ? "" : v)}
                onInputChange={(e) =>
                  setStorageLoc(
                    e.target?.value === 0 || e.target?.value === undefined
                      ? ""
                      : e.target?.value
                  )
                }
                sx={{ width: 170 }}
                renderInput={(params) => (
                  <TextField {...params} label="Storage Location" />
                )}
              />
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                freeSolo={true}
                size={"small"}
                options={printingItems
                  ?.filter((item) => item?.Article_GTIN !== "" && item?.Article_GTIN !== undefined)
                  .map((item) => item?.Article_GTIN)
                  .reduce((noDupArr, entry) => {
                    if (noDupArr.includes(entry)) {
                      return noDupArr;
                    } else {
                      return [...noDupArr, entry];
                    }
                  }, [])}
                onChange={(e, v) => setArticleGtin(v === null ? "" : v)}
                onInputChange={(e) =>
                  setArticleGtin(
                    e.target?.value === 0 || e.target?.value === undefined
                      ? ""
                      : e.target?.value
                  )
                }
                sx={{ width: 170 }}
                renderInput={(params) => (
                  <TextField {...params} label="Article GTIN" />
                )}
              />
            </div>
            <button onClick={() => getPrintedItems()} className="run-btn">
              <p className="run-text">Search</p>
            </button>
          </div>
          <div
            style={{ marginTop: "10px", overflowX: "scroll", height: "600px" }}
          >
            <PaginatedItems
              pageCountNew={paginationData?.next + paginationData?.previous + 1}
              getData={(pageNumber) => getPrintedItems(pageNumber)}
              totalLength={paginationData?.next}
              itemsPerPage={10}
              headers={headers}
              colNames={colNames}
              forcePage={paginationData?.previous || 0}
              data={printingItems.map((item) => ({
                ...formatItem(item),
                createdAt: moment(item.createdAt).format(
                  "MM/DD/YYYY hh:mm:ss A"
                ),
              }))}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
              color: "white",
              padding: "10px",
              gap: "10px",
            }}
          >
            <h5>ROWS PER PAGE: 10</h5>
            <h5>{`${startItem}-${endItem} of ${totalDocuments}`}</h5>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StockOnHand;
