import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import Loader from "../components/Loader";
import { PaginatedItems } from "../components/Paginated";
import { Link, useLocation } from "react-router-dom";
import { getPackingDetail, localServer } from "../Path/Path";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { formatItem } from "./common/FormatItems";
import PermissionsModal from "../components/PermissionsModal";
import { FaArrowLeft } from "react-icons/fa"
import { CSVLink } from "react-csv";
import { BsDownload } from "react-icons/bs"

const PackingListDetail = () => {
  const [printingItems, setPrintingItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({});
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState("")
  const [selectedFile, setSelectedFile] = useState(null)
  const [fileResult, setFileResult] = useState(null)
  const [tags, setTags] = useState(null)
  const [reload, setReload] = useState(false)

  const fileInputRef = useRef(null);
  const location = useLocation()
  const {po_number} =  location.state || ''

    // const { handleFileUpload, parsedData, summary, filePreview } = FileValidator();

    const onFileChange = (e) => {
    const file = e.target.files[0];
    // if (file) {
    //   handleFileUpload(file);
    // }
    setSelectedFile(file)
    if (fileResult) {
        e.target.value = null;
        e.target.file = null
          }  
        }

    const onLinkCLick = (e, ean) => {
      e.preventDefault()
      const item = printingItems.find((item) => item.EAN === ean)
      if(item && item.tagIDs){
        setTags(item.tagIDs)
      }
    }

  const handleFileSend = async () => {
  try {
    if(!selectedFile){
        toast.error('File not selected')
        return
    }
    setLoading(true)
          const file = selectedFile; 
          const blob = new Blob([file], { type: file.type });
          const formdata = new FormData();
          formdata.append("excel", blob, selectedFile);

          const response = await fetch(`${localServer}store/packing-list`, {
              method: "POST",
              body: formdata,
              redirect: "follow"
          });
          setLoading(false)
          if (fileInputRef.current) {
            fileInputRef.current.value = ''; 
            }
          const result = await response.text();
          const parsedResult = JSON.parse(result);
          setFileResult(parsedResult);
          if(parsedResult?.success === true){
            setReload(true)
          }
      } catch (error) {
        setLoading(false)
        setReload(false)
          console.error("Error while sending file:", error);
      }
  };

  useEffect(() => {
    getPrintedItems();
    setSelectedFile(null)
  }, [reload]);

  const getPrintedItems = async (pageNumber = 1) => {
    setLoading(true);
    try {
      const params = {
        page: pageNumber,
        poNumber: po_number,
      };
       const data = await getPackingDetail(params);

      if (data && Array.isArray(data.data.data)) {
        const filtering = data?.data.data;
        setPrintingItems(filtering);
        setPageCount(data.data)
        setLoading(false);
        if (data?.data?.data?.length === 0) {
          toast.error("No data found.");
          return
        } 
      } else {
        console.error("Unexpected API response structure:", data);
      }
    } catch (err) {
      console.error("Error fetching printed items:", err);
    } finally {
      setLoading(false);
      setReload(false);
    }
  };

  const headers = [
    "Created Time",
    "PO Number",
    "Carton No",
    "Article No",
    "Article Desc",
    "EAN",
    "MC Code",
    "State",
    "Extra",
    "Packed Qty.",
    "Received Qty.",
    "Vendor Code",
    "Vendor Name",
    "NW",
  ];
  const colNames = [
    "createdAt",
    "poNumber",
    "cartonNo",
    "articleNo",
    "articleDesc",
    "EAN",
    "mcCode",
    "state",
    "extra",
    "packedQty",
    "receivingProcessedQuantity",
    "vendorCode",
    "vendorName",
    "NW",
  ];

  return (
    <React.Fragment>
      <Loader loading={loading} />
      <div className="dashboard-main">

        <div
          className="dashboard-top"
          style={{ display: "flex", flexDirection: "column", padding: "10px" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                flex: "1",
                display: "flex",
                alignItems: "center",
              }}
            >
                <button onClick={() => navigate(-1)} className="back-btn" style={{ marginRight: "10px", background: "none", border: "none" }}>
                <FaArrowLeft size={20} color="black" />
              </button>
              <p
                className="stock-count"
                style={{ fontSize: "25px", margin: 0 }}
              >
                {" "}
                Packing List Details{" "}
              </p>
            </div>
            <CSVLink data={[{ 'PO No': '4500006982', 'Carton No': '1007','Article No': '000000000000702199', 
                              'Article Description': 'SG Twill Cargo Pant' , 'EAN': '9100000042787', 'MC Code':'027001001', 
                              'Packed Qty': '100', 'Vendor code': '0000220181','Vendor Name': 'Nesto Distribution FZCO(8250)', 'NW': '10'}]} headers={[
                            { label: 'PO No', key: 'PO No' },
                            { label: 'Carton No', key: 'Carton No' },
                            { label: 'Article No', key: 'Article No' },
                            { label: 'Article Description', key: 'Article Description' },
                            { label: 'EAN', key: 'EAN' },
                            { label: 'MC Code', key: 'MC Code' },
                            { label: 'Packed Qty', key: 'Packed Qty' },
                            { label: 'Vendor code', key: 'Vendor code' },
                            { label: 'Vendor Name', key: 'Vendor Name' },
                            { label: 'NW', key: 'NW' },
                          ]}
                          filename="packingListFormat">
                            <div style={{ width: "190px", display: "flex", justifyContent: "space-between",marginTop:'14px' }}>
                                <div>
                                    <p style={{ color: "black", fontSize: "16px" }}>Packing List File Format</p>
                                </div>
                                <div>
                                    <BsDownload color="black" size={16} />
                                </div>
                            </div>
                        </CSVLink>
          </div>
          <div>
            <h1 style={{ color: "black", fontSize: "15px" , marginLeft: '30px'}}>
              {new Date().toLocaleString("hi-IN")}
            </h1>
          </div>
        </div>
        <div className="dashboard-bottom" style={{ marginTop: "0.5%" }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              gridColumnGap: "20px",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                gridColumnGap: "10px",
                gridRowGap: "10px",
                flexWrap: "wrap",
              }}
            >

              <input 
                type="file" 
                accept=".xlsx, .xls, .csv" 
                onChange={onFileChange}
                ref={fileInputRef}
            />
            <button onClick={() => handleFileSend()} className="view-btn">
              <p className="run-text">Send</p>
            </button>
            </div>
            <button onClick={() => getPrintedItems()} className="run-btn">
              <p className="run-text">Search</p>
            </button>
          </div>
          <div  
            style={{ marginTop: "10px", overflowX: "scroll", height: "600px" }}
          >
            <PaginatedItems
              pageCountNew={pageCount?.next + pageCount?.previous + 1}
              getData={(pageNumber) => getPrintedItems(pageNumber)}
              totalLength={paginationData?.next}
              itemsPerPage={10}
              headers={headers}
              colNames={colNames}
              forcePage={paginationData?.previous || 0}
              data={printingItems.map((item) => ({
                ...formatItem(item),
                createdAt: moment(item.createdAt).format(
                  "MM/DD/YYYY hh:mm:ss A"
                ),               
               EAN: item.EAN ? (
                  item.tagIDs.length !== 0 ? (
                    <Link to="#"
                      onClick={(e) => onLinkCLick(e, item.EAN)}
                    > 
                      {item.EAN}
                    </Link>
                  ) : (
                    item.EAN
                  )
                ) : "--"
              }))}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
              color: "white",
              padding: "10px",
              gap: "10px",
            }}
          >
            <h5>ROWS PER PAGE: 10</h5>
          </div>
        </div>
        <PermissionsModal
        permissions={tags}
        responseData={fileResult}
        open={!!fileResult || !!tags}
        onClose={() => (setFileResult(null), setSelectedFile(null), setTags(null))}
        onClick={() => (setFileResult(null), setSelectedFile(null), setTags(null))}
        />
      </div>
    </React.Fragment>
  );
};

export default PackingListDetail;
