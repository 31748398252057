import * as React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { IoMdClose } from "react-icons/io";
import Slide from "@mui/material/Slide";
import moment from "moment";
import { capitalizeFirstLetter } from "../screens/common/capitalizeFirstLetter";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DrillDetailModal({
  heading,
  modalOpen,
  data,
  closeHandler,
  tableTitle,
  HeadAndRow,
}) {

  return (
    <div>
      <Dialog
        fullScreen
        open={modalOpen}
        onClose={closeHandler}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeHandler}
              aria-label="close"
            >
              <IoMdClose />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {heading}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="dashboard-main" style={{ margin: "10px" }}>
          <div className="dashboard-top">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <p
                className="stock-count"
                style={{ fontSize: "25px", margin: 0 }}
              >
                {tableTitle}
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              gridColumnGap: "20px",
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "10px",
                flexWrap: "wrap",
                marginLeft: "15px",
                marginTop: "10px",
                width: "calc(80% - 280px)",
              }}
            ></div>
          </div>

          <div
            style={{ marginTop: "10px", overflowX: "scroll", height: "600px" }}
          >
            <table data-table-theme="dark zebra" style={{ marginTop: "20px" }}>
              <thead>
                <tr style={{ background: "#373E43" }}>
                  {HeadAndRow?.length &&
                    HeadAndRow?.map((item, index) => (
                      <th key={index} style={{ background: "#000" }}>
                        {item ? item?.header : "--"}
                      </th>
                    ))}
                </tr>
              </thead>
              {data?.map((item, index) => (
                <tbody key={index}>
                  <tr>
                    {HeadAndRow?.map((name, idx) =>
                       (
                        <td key={idx}>
                          {name?.column === "process_status"
                            ? capitalizeFirstLetter(item[name?.column])
                            : name.column.includes("date") &&
                              moment(item[name?.receiving_date]).isValid()
                            ? moment(item[name?.receiving_date]).format(
                                "MM/DD/YYYY hh:mm:ss A"
                              )
                            : item[name?.column] || "--"}
                        </td>
                      )
                    )}
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "10px",
              color: "white",
            }}
          >
            <h3>List Length: {data?.length}</h3>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
