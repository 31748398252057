import axios from "axios";
import React, { Component } from "react";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import MyInput from "../../components/MyInput";
import { withRouter } from "../../components/withRouter";
import { deletedevice, localServer, Path, zplDelete } from "../../Path/Path";
import { Autocomplete, TextField } from "@mui/material";
import AlertDialog from "../../components/AlertDialog";
class Zpl extends Component {
    state = {
        zpl: [],
        zplFilter: [],
        searchVal: '',
        laoding: false,
        deleteModal: null,
    }
    async componentDidMount() {
        this.setState({ loading: true })
        const zpl = await axios.get(`${localServer}store/zpl/get`)
        console.log(zpl);
        this.setState({ zpl: zpl.data, zplFilter: zpl.data })
        this.setState({ loading: false })
    }
    deleteHandler = async (id) => {
        this.setState({ loading: true })
        await zplDelete(id)
            .then((async res => {
                toast.success("Zpl Deleted")
                this.setState({deleteModal : null})
                const zpl = await axios.get(`${localServer}store/zpl/get`)
                console.log(zpl);
                this.setState({ zpl: zpl.data, zplFilter: zpl.data })
                this.setState({ loading: false })
            }))
            .catch((err => {
                toast.error("Something Went Wrong")
            }))


    }
    onSearchChange = (value) => {
        console.log(this.state.searchVal);
        const newArray = this.state.zplFilter.filter((item => item?.zplName.toLowerCase()?.includes(this.state.searchVal?.toLowerCase())))
        console.log(newArray);
        this.setState({ zpl: newArray })
    }
    render() {
        return (
            <>
            <AlertDialog
                    deleteThis="User"
                    onDeletePress={() => this.deleteHandler(this.state?.deleteModal)}
                    open={this.state.deleteModal ? true : false}
                    handleClose={() => this.setState({ deleteModal: null })}
                />
                <Loader loading={this.state.loading} />
                <div className="dashboard-main">
                    <div className="dashboard-top">
                        <div> <p className="stock-count" style={{ fontSize: '25px', margin: 0 }}>Zpl Report</p>
                            <h1 style={{ color: 'black', fontSize: '15px' }}>{new Date().toLocaleString('hi-IN')} </h1>
                        </div>
                        <button onClick={() => window.location.href = '/addZpl'} className="run-btn"><p className="run-text">Add Zpl</p></button>

                    </div>
                    <div className="dashboard-bottom">
                        <div className="drop-data">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", width: "280px" }}>
                                {/* <p className="filter-text">Filters</p> */}
                                <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                size={'small'}
                                options={this.state.zplFilter?.map((item => item?.zplName)).reduce((noDupArr, entry) => {
                                    if (noDupArr?.includes(entry)) {
                                        return noDupArr;
                                    } else {
                                        return [...noDupArr, entry];
                                    }
                                }, []).filter((item => item !== null && item !== undefined))}
                                onChange={(e, v) => this.setState({ searchVal: v === null ? '' : v })}
                                sx={{ width: 235 }}
                                renderInput={(params) => <TextField
                                    {...params} label="ZPL Name" />}
                            />
                            </div>


                            <div>
                                <button onClick={() => this.onSearchChange()} className="run-btn"><p className="run-text">Search</p></button>
                            </div>


                        </div>

                        <div style={{ paddingTop: "2%" }}>
                            <table data-table-theme="dark zebra">

                                <thead>

                                    <tr style={{ background: "#373E43", textAlign: 'left' }}>
                                        <th style={{ textAlign: 'left' }}  >Zpl Name</th>
                                        <th style={{ textAlign: 'left' }}  >Actions</th>
                                    </tr>

                                </thead>
                                {
                                    this.state?.zpl?.map((item, index) =>
                                        <tbody key={index}>
                                            <tr style={{ textAlign: 'left' }} >
                                                <td>{item?.zplName}</td>
                                                <td style={{ width: "200px" }}>
                                                    {
                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                            <div onClick={() => this.props.history("/addZpl", { state: { item } })}>
                                                                <button className="action-btn" style={{ backgroundColor: "#0069D9", border: "none", borderRadius: "10px" }}    >
                                                                    <p className="action-txt">Edit</p>
                                                                </button>
                                                            </div>
                                                            <div>
                                                                <button className="action-btn" style={{ backgroundColor: "#E41119", border: "none", borderRadius: "10px" }}
                                                                    onClick={() => this.setState({deleteModal: item._id})}
                                                                >
                                                                    <p className="action-txt">Delete</p>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }
                                                </td>
                                            </tr>





                                        </tbody>)
                                }
                            </table>
                        </div>

                    </div>
                </div>
            </>

        )
    }
}
export default withRouter(Zpl)