import { Autocomplete, TextField } from "@mui/material";
import _ from "lodash";
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { GrDocumentCsv } from "react-icons/gr";
import { toast } from "react-toastify";
import BarGraph from "../Graphs/BarGraph";
import { Innovent_Path, PathCustom, getCountedTarget, getStores, getTargetedStockCountByDep, reqInstance, targetedStockCountName } from "../Path/Path";
import Loader from "../components/Loader";
import { PaginatedItems } from "../components/Paginated";
export default class TargetStockCountOperation extends Component {
    state = {
        data1: [],
        item: [],
        operationItems: [{
            Store: '00125',
            Department: "750",
            Date: '22-09-13',
            Accuracy: "0.00",
            UIA: "0.00",
            Expected: "39",
            Counted: "0",
            Overs: "0",
            Unders: "-0.39",
            ExpectedSF: "0",
            ExpectedSR: "0",
            Department: "perfume",
            Brand: "136-DG Men",
            Diff: "13"
        }],
        loading: false,
        datesArray: [],
        SKU_Over: '',
        SKU_Real: '',
        SKU_Stock: '',
        data: "",
        storeName: [],
        dropdata: "",
        cronStatus: false,
        filter: [],
        mydate: "",
        counted: {},
        sohqty: 0,
        visible: false,
        itemsPerPage: 10,
        csvDataReal: [],
        csvDataOvers: [],
        departmentID: [],
        department: '',
        matched: {},
        csvDataStock: [],
        csvDataRealFake: [],
        csvDataOversFake: [],
        unders: { totalUnders: 0, undersData: [] },
        overs: { totalOvers: 0, oversData: [] },
        csvDataStockFake: [],
        createdDate: null,
        Color: '',
        Brand: '',
        store: '',
        Size: '',
        ColorList: [],
        BrandList: [],
        storeArray: [],
        SizeList: [],
        stats: {
            groupedSKU: [],
            dataOrderWise: [],
            uniqueArray: [],
        }
    }
    searchDataReal = () => {
        const filterArray = this.state.csvDataRealFake.filter((item => item?.SOHSKU?.includes(this.state.SKU_Real)))
        this.setState({ csvDataReal: filterArray })
    }
    searchDataOver = () => {
        const filterArray = this.state.csvDataOversFake.filter((item => item?.SOHSKU?.includes(this.state.SKU_Over)))
        this.setState({ csvDataOvers: filterArray })
    }
    searchDataStock = () => {
        const filterArray = this.state.csvDataStockFake.filter((item => item?.SOHSKU?.includes(this.state.SKU_Stock)))
        this.setState({ csvDataStock: filterArray })
    }
    isToday = (date) => {
        const today = this.formatDate(new Date());
        let newDate = date
        // 👇️ Today's date

        if (today === newDate) {
            return true;
        }

        return false;
    }
    statsFunction = async () => {
        this.setState({ loading: true })
        try{
        const createdData = this.formatDate(this.state.createdDate) === "01/01/1970" ? '' : this.formatDate(this.state.createdDate)

        const rawBody =
        {
            "StoreID": this.state.store,
            "date": createdData,
            'isToday': this.isToday(createdData)
        }
        const getCounted = await getCountedTarget(this.state.department)
        const getSoh = await getTargetedStockCountByDep(this.state.department)

        const discripencyData = await getCounted?.data
        // const discripencyData = await getSoh?.data?.data.map((data => { return { ...data, sku: (data?.sku?.startsWith("00000") ? data.sku?.slice(5) : data.sku) } }))
        const discripencyDataHehe = await getSoh?.data.map((data => { return { ...data, sku: (data?.sku?.startsWith("00000") ? data.sku?.slice(5) : data.sku) } }))

        var counted = { totalCounted: 0, countedData: [] }

        var unders = { totalUnders: 0, undersData: [] }
        var overs = { totalOvers: 0, oversData: [] }
        var sohqty = 0
        var matched = { totalMatched: 0, matchedData: [] }
        // const counted = await getCountedPerStore({ "StoreID": this.state.store })

        var unders = { totalUnders: 0, undersData: [] }
        var overs = { totalOvers: 0, oversData: [] }
        var matched = { totalMatched: 0, matchedData: [] }

        var zonesData = { salesFloor: 0, groundFloor: 0 }
        var criticalOutofStock = { totalStock: 0, stockData: [] }
        var sohqty = 0
        var totalSOH = []
        var unknownData = []

        totalSOH = discripencyDataHehe
        sohqty = discripencyDataHehe.reduce(function (previousValue, currentValue) {
            return { qty: Number(previousValue.qty) + Number(currentValue.qty), }
        })?.qty;
        const checkPosCodeUnique = (search) => {
            return discripencyDataHehe.some(item => { return item.sku?.includes(search) });
        };
        const checkPosCodeUniqueCounted = (search) => {
            return discripencyData.some(item => { return item.barcode?.includes(search) });
        };
        for (let index = 0; index < discripencyData.length; index++) {
            const element = discripencyData[index];
            // console.log(discripencyDataHehe?.map((item => "00000" + item?.barcode)), 'element?.sku.includes(discripencyData?.map((item => item?.barcode)))');
            if (checkPosCodeUnique(element?.barcode)) {
                matched = {
                    totalMatched: matched?.totalMatched + Number(1),
                    matchedData: [...matched?.matchedData, element]
                }
            }

        }
        for (let index = 0; index < discripencyDataHehe.length; index++) {
            const element = discripencyDataHehe[index];
            // console.log(discripencyDataHehe?.map((item => "00000" + item?.barcode)), 'element?.sku.includes(discripencyData?.map((item => item?.barcode)))');
            if (!checkPosCodeUnique(element?.barcode)) {
                unders = {
                    totalUnders: unders?.totalUnders + Number(element?.qty),
                    undersData: [...unders?.undersData, element]
                }
            }

        }
        let newVAricenceData = discripencyDataHehe
        for (let index = 0; index < newVAricenceData.length; index++) {
            const element = discripencyDataHehe[index];
            element.scanned = 0
            element.QTY_Variances = 0
            element.qty = Number(element.qty)

            for (let index = 0; index < discripencyData.length; index++) {
                const element2 = discripencyData[index];
                if (element?.sku?.includes(element2?.barcode)) {
                    element.scanned = element?.scanned + 1
                }
            }
            element.QTY_Variances = Number(element.scanned) - Number(element?.qty)
            // if (checkPosCodeUniqueCounted(element?.sku)) {
            //     element.scanned = element?.scanned + 1
            // }

            // console.log(discripencyDataHehe?.map((item => "00000" + item?.barcode)), 'element?.sku.includes(discripencyData?.map((item => item?.barcode)))');


        }
        this.setState({ csvDataReal: newVAricenceData, csvDataRealFake: newVAricenceData })
        for (let index = 0; index < discripencyData.length; index++) {
            const element = discripencyData[index];
            if (!element?.barcode?.includes(discripencyDataHehe?.map((item => item?.sku)))) {
                overs = {
                    totalOvers: overs?.totalOvers + Number(element?.qty || 0),
                    oversData: [...overs?.oversData, element]
                }
            }

        }
        let emmprt = []
        counted = await { totalCounted: matched.totalMatched + overs.totalOvers, countedData: emmprt.concat(matched.matchedData, overs.oversData) }
        // this.setState({ counted: counted, sohqty: sohqty, matched: matched })
        this.setState({
            unders: unders,
            overs: overs,
            matched: matched,
            sohqty: sohqty,
            totalSOH: totalSOH,
            counted: discripencyData?.length,
            // criticalOutofStock: criticalOutofStock,
        })
       
        var values = await overs.oversData
            .map(({ overs }) => overs)
            .sort((a, b) => b - a)
        // .slice(0, 20)
        var top20Overs = await overs.oversData.filter(({ overs }) => values.includes(overs));
        const mixedData = _.orderBy(discripencyData.map((item => {
            return { ...item, unders: item.SOHQTY - item?.matched, Scanned: item?.SOHQTY + item?.overs, barcodeOriginal: item?.SKU?.replaceAll(";", '-') }
        })), ['unders'], ['desc']).splice(0, 20)

        var valuesUnders = await mixedData
            .map(({ unders }) => unders)
            .sort((a, b) => b - a)
        // .slice(0, 20)
        var top20Unders = await mixedData.filter(({ unders }) => valuesUnders.includes(unders));
        this.setState({ csvDataOvers: top20Overs, csvDataOvers: top20Overs })
        this.setState({ csvDataStock: top20Unders, csvDataStockFake: top20Unders })
        this.setState({ loading: false })
        } catch (error) {
            this.setState({loading: false, csvDataReal: null})
            toast.error("Something went wrong")
        }
    }
    gettingStores = async () => {
        const stores = await getStores();
        await this.setState({ storeArray: stores });
    }

    formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    async componentDidMount() {
      try{
        this.setState({ loading: true })
        await this.gettingStores()
        // await this.gettingDates()
        await this.gettingDepartments()
        // this.statsFunction()
        this.setState({ loading: false })
         } catch (error) {
            this.setState({loading: false})
            toast.error("Something went wrong")
        }
    }
    // search = () => {
    //     const res = this.state.data.filter(i => i.storename === this.state.dropdata)
    //     this.setState({ filter: res })
    //     this.setState({ visible: true })

    // }
    replaceAll = (str, find, replace) => {
        var escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return str.replace(new RegExp(escapedFind, 'g'), replace);
    }
    storeHandler = async (data) => {
        this.setState({ store: data?.StoreID })
        this.setState({ storeData: data });
        var raw = { "StoreID": data?.StoreID };
        const filters = await reqInstance.post(`${Innovent_Path}FILTERSPERSTORE`, raw)

        const newFilters = filters?.data?.headersY
        let newFiltersDta = []
        let mangageFilter = []
        let brand = []
        let color = []
        let size = []
        let style = []
        for (let i = 0; i < newFilters.length; i++) {
            let values = newFilters[i];
            for (var a = 0; a < values.value.length; a++) {
                mangageFilter.push(values.value[0]);
                brand.push(values.value[1]);
                color.push(values.value[2]);
                size.push(values.value[3]);
                style.push(values.value[4]);
            }
        }
        var department = _.uniq(mangageFilter);
        var brandList = _.uniq(brand);
        var colorList = _.uniq(color);
        var sizeList = _.uniq(size);
        var styleList = _.uniq(style);
        // for (let index = 0; index < newFilters.length; index++) {
        //     const element = newFilters[index];
        //     for (let index = 0; index < element.length; index++) {
        //         const elementTwo = element[index];
        //         console.log(elementTwo, 'elementTwo');
        //         newFiltersDta.push(elementTwo)

        //     }

        // }

        // var uniq = [...new Set(newFiltersDta)];
        await this.setState({
            departmentID: department,
            BrandList: brandList,
            ColorList: colorList,
            SizeList: sizeList,
        })
        this.setState({ loading: false })

    }
    departmentHandler = async (data) => {
        await this.setState({ department: data })


    }
    searchFunction = async () => {
      try{
        if (this.state.department === '') {
            toast.error("Please Select Store Code")
        } else {
            // const cronStatus = await getCronStatus()
            // await this.setState({ cronStatus: cronStatus?.data?.status })
            this.statsFunction()

        }
         } catch (error) {
            this.setState({loading: false})
            toast.error("Something went wrong")
        }
    }
    gettingDepartments = async () => {

        let data = null
        await targetedStockCountName()
            .then((async res => {

                await this.setState({ departmentID: res?.data })

            }))

            .catch((err => {
                console.log(err);
            }))


    }
    gettingDates = async () => {
 
        let data = null
        await reqInstance.get(`${PathCustom}store/get/soh/dates`)
            .then((async res => {
                if (res?.data?.success === false && res?.data?.message === "A token is required for authentication") {
                
                }
                data = await res?.data?.data
            }))

            .catch((error => {
                let err = error?.response?.data
                if (err?.success === false && err?.message === "Invalid Token") {
          
                }
            }))

        this.setState({ datesArray: data !== null ? data : [] })

    }
    shouldDisableDate = (date) => {
        const formatDate = (date) => {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('/');
        }
        let datas = this.state.datesArray.map((item => formatDate(item)))

        return !datas.includes(formatDate(new Date(date).toLocaleString()))
    }
    render() {
        const headers = [
            "Product Number",
            "Barcode",
            "Department Id",
            "Description",
            "Expected",
            'Overs',
            "Unders",
            'Matched',
            "Scanned"
        ]
        const headersReal = [
            "SKU",
            "Reference Name ",
            "Qty.",
            "Scanned",
            "Qty. Variences",
            "Barcode",
            "Type",
        ]
        const headersRealOvers = [
            "SkU",
            "Reference Name ",
            "Qty.",
            "Barcode",
            "Type",
        ]
        const headersRealUnder = [
            "Barcode",
            "Reference Name",
            "EPC"
        ]
        const headersReaCSV = [
            { label: 'SkU', key: 'barcode' },
            { label: 'Reference Name ', key: 'name' },
            { label: 'Qty.', key: 'qty' },
            { label: 'Scanned', key: 'Scanned' },
            { label: 'Qty. Variances', key: 'QTY_Variances' },
            { label: 'Barcode', key: 'sku' },
            { label: 'Type', key: 'type' },
        ]
        const headersReaCSVOvers = [
            { label: 'Barcode', key: 'barcode' },
            { label: 'Reference Name ', key: 'name' },
            { label: 'Qty.', key: 'qty' },
            { label: 'SKU', key: 'sku' },
            { label: 'Type', key: 'type' },
        ]
        const headersReaUnderCSV = [
            { label: 'Barcode', key: 'barcode' },
            { label: 'Reference Name ', key: 'name' },
            { label: 'EPC', key: 'TagID' },
        ]
        // const headers2 = [

        //     "Department",
        //     "Brand",
        //     "SKU",
        //     "Expected",
        //     "Diff"

        // ]
        // const headers3 = [

        //     "SKU",
        //     "Dept.Name",
        //     "Brand",
        //     "Size",
        //     "Color",
        //     "Delta", ,


        // ]
        const colNames = [
            "SOHSKU",
            "barcodeOriginal",
            "DepartmentID",
            "Description",
            "SOHQTY",
            'overs',
            "unders",
            'matched',
            "scanned"
        ]
        const csvColNames = [
            { label: 'Product Number', key: 'SOHSKU' },
            { label: 'Barcode', key: 'barcodeOriginal' },
            { label: 'Department Id', key: 'DepartmentID' },
            { label: 'Description', key: 'Description' },
            { label: 'Expected', key: 'SOHQTY' },
            { label: 'Overs', key: 'overs' },
            { label: 'Unders', key: 'unders' },
            { label: 'Matched', key: 'matched' },
            { label: 'Scanned', key: 'scanned' },
        ]
        const colNamesReal = [
            'barcode',
            'name',
            'qty',
            "Scanned",
            "QTY_Variances",
            'sku',
            'type',
        ]
        const colNamesRealOvers = [
            'barcode',
            'name',
            'qty',
            'sku',
            'type',
        ]
        const colNamesRealUnder = [
            'barcode',
            'name',
            "TagID"

        ]
        // const colNames2 = [
        //     "SOHSKU",
        //     "SKU",
        //     "DepartmentID",
        //     "Description",
        //     "SOHQTY",
        //     'matched',
        //     'overs',
        //     "unders",
        //     'matched',
        //     "Scanned"
        // ]
        const csvDataReal = this.state.csvDataReal?.map((item => {
            return {
                ...item,
                unders: item.SOHQTY - item?.matched,
                Scanned: item?.scanned,
                barcodeOriginal: item?.SKU?.replaceAll(";", '-'),
                stockRoom: item?.zone?.StockRoom ? item?.zone?.StockRoom : 0,
                salesFloor: item?.zone?.SalesFloor ? item?.zone?.SalesFloor : 0,
                Unit_Cost_Price: item?.latest_cost_price,
                On_Hand_QTY: item?.SOHQTY,
                QTY_Variances: item?.QTY_Variances,
                withOutColor: item?.SOHSKU?.split('-')[0],
                On_Hand_Cost: item?.SOHQTY * item?.latest_cost_price,
                Counted_Cost: item?.scanned * item?.latest_cost_price,
                Counted_Variances: (item?.scanned - item?.SOHQTY) * item?.latest_cost_price
            }
        })) || []
        const csvDataOvers =
            _.orderBy(this.state.csvDataOvers, ['overs'], ['desc']).splice(0, 20)

        const csvDataStock = this.state.csvDataStock
        return (
          <>
            <Loader loading={this.state.loading} />
            <div style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gridColumnGap: "20px",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  marginTop: 20,
                  marginBottom: 20,
                  padding: "15px",
                }}
              >
                <div className="dashboard-top" style={{ alignItems: "center" }}>
                  <div>
                    {" "}
                    <p
                      className="stock-count"
                      style={{ fontSize: "25px", margin: 0 }}
                    >
                      Target Stock Operation
                    </p>
                  </div>
                </div>
                <div className="dashboard-bottom" style={{ width: "100%" }}>
                  {/* <div> <p className="filter-text"> Store ID: {this.state.store === '' ? "No Store Selected" : this.state.store}</p></div> */}
                  <div
                    className="drop-data"
                    style={{
                      gridRowGap: "20px",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gridColumnGap: "20px",
                        gridRowGap: "20px",
                        flexWrap: "wrap",
                      }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        size={"small"}
                        options={this.state.departmentID}
                        onChange={(e, v) =>
                          this.departmentHandler(
                            v === null || v === undefined ? "" : v
                          )
                        }
                        sx={{ width: 235 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Reference" />
                        )}
                      />
                    </div>
                    <button
                      onClick={() => this.searchFunction()}
                      className="run-btn"
                    >
                      <p className="run-text">Search</p>
                    </button>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                {console.log(
                  this.state?.matched?.matchedData?.filter(
                    (item) => item?.name === "PH"
                  ),
                  "PH"
                )}
                <BarGraph
                  data={_.groupBy(this.state.matched?.matchedData, "name")}
                  totalLength={Number(this.state.sohqty)}
                />
              </div>

              <div style={{ display: "flex", width: "100%" }}>
                <div className="dashboard-main">
                  <div className="dashboard-top2">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <p
                        className="stock-count"
                        style={{ fontSize: "25px", margin: 0 }}
                      >
                        Top 20 Over
                      </p>

                      <CSVLink
                        filename="top-20-over"
                        data={csvDataOvers}
                        headers={headersReaUnderCSV}
                      >
                        <GrDocumentCsv
                          color="white"
                          fill="white"
                          fontSize={25}
                          style={{ marginRight: 5, cursor: "pointer" }}
                        />
                      </CSVLink>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      gridColumnGap: "20px",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  ></div>

                  <PaginatedItems
                    noAction
                    headers={headersRealUnder}
                    colNames={colNamesRealUnder}
                    itemsPerPage={this.state.itemsPerPage}
                    data={_.orderBy(
                      this.state.csvDataOvers,
                      ["overs"],
                      ["desc"]
                    ).splice(0, 20)}
                  />
                </div>
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                <div className="dashboard-main">
                  <div className="dashboard-top2">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <p
                        className="stock-count"
                        style={{ fontSize: "25px", margin: 0 }}
                      >
                        Top 20 Under
                      </p>

                      <CSVLink
                        filename="top-20-under"
                        data={csvDataStock}
                        headers={headersReaUnderCSV}
                      >
                        <GrDocumentCsv
                          color="white"
                          fill="white"
                          fontSize={25}
                          style={{ marginRight: 5, cursor: "pointer" }}
                        />
                      </CSVLink>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      gridColumnGap: "20px",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  ></div>
                  <PaginatedItems
                    noAction
                    headers={headersRealUnder}
                    colNames={colNamesRealUnder}
                    itemsPerPage={this.state.itemsPerPage}
                    data={this.state.csvDataStock}
                  />
                </div>
              </div>
              <div className="dashboard-main">
                <div className="dashboard-top">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <p
                      className="stock-count"
                      style={{ fontSize: "25px", margin: 0 }}
                    >
                      Real Time Discrepency
                    </p>

                    <CSVLink
                      filename="real-time-discrepency"
                      data={csvDataReal}
                      headers={headersReaCSV}
                    >
                      <GrDocumentCsv
                        color="white"
                        fill="white"
                        fontSize={25}
                        style={{ marginRight: 5, cursor: "pointer" }}
                      />
                    </CSVLink>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    gridColumnGap: "20px",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    marginTop: 20,
                    marginBottom: 20,
                    padding: "15px",
                  }}
                >
                  <div
                    className="dashboard-bottom"
                    style={{ width: "100%" }}
                  ></div>
                </div>

                <div style={{ overflow: "auto" }}>
                  <PaginatedItems
                    noAction
                    headers={headersReal}
                    colNames={colNamesReal}
                    itemsPerPage={20}
                    data={this.state.csvDataReal?.map((item) => {
                      return {
                        ...item,
                        unders: item.SOHQTY - item?.matched,
                        Scanned: item?.scanned,
                        barcodeOriginal: item?.SKU?.replaceAll(";", "-"),
                        stockRoom: item?.zone?.StockRoom
                          ? item?.zone?.StockRoom
                          : 0,
                        salesFloor: item?.zone?.SalesFloor
                          ? item?.zone?.SalesFloor
                          : 0,
                        Unit_Cost_Price: item?.latest_cost_price,
                        On_Hand_QTY: item?.SOHQTY,
                        QTY_Variances: item?.QTY_Variances,
                        withOutColor: item?.SOHSKU?.split("-")[0],
                        On_Hand_Cost: item?.SOHQTY * item?.latest_cost_price,
                        Counted_Cost: item?.scanned * item?.latest_cost_price,
                        Counted_Variances:
                          (item?.scanned - item?.SOHQTY) *
                          item?.latest_cost_price,
                      };
                    })}
                  />
                </div>
              </div>
            </div>
          </>
        );
    }
}