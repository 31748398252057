import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { GrDocumentCsv } from "react-icons/gr";
import Loader from "../components/Loader";
import { PaginatedItems } from "../components/Paginated";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getPicking, localServer } from "../Path/Path";
import moment from "moment/moment";
import { withRouter } from "../components/withRouter";
import { formatItem } from "./common/FormatItems";
import { capitalizeFirstLetter} from "./common/capitalizeFirstLetter";
import { capitalizeAllWords } from "./common/capitalizeAllWords";
import { HeadAndRowPicking } from "../components/Helper";
import TableModal from "../components/TableModal";

const Picking = () => {
  const [responseData, setResponseData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [toDate, setToDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [purchasingDocument, setPurchasingDocument] = useState("");
  const [source, setSource] = useState("");
  const [destination, setDestination] = useState("");
  const [paginationData, setPaginationData] = useState({});
  const [csvData, setCsvData] = useState([]);
  const navigate = useNavigate();
  const [showDetailModal, setShowDetailModal] = useState(null);


  const validateDate = (date) => {
    const dateFormat = "MM/DD/YYYY";
    if (!date) {
      return true;
    }
    if (!moment(date, dateFormat, true).isValid()) {
      // toast.error("Invalid date format.");
      return false;
    }
    if (moment(date).isAfter(moment())) {
      return false;
    }
    return true;
  };

  const getPrintedItems = async (pageNumber = 1) => {
    const newFromDate = validateDate(fromDate);
    const newToDate = validateDate(toDate);
    if (!newFromDate || !newToDate) {
      toast.error("Please enter valid date ranges.");
      return;
    }

    setLoading(true);
    try {
      const params = {
        pageNo: pageNumber,
        purchasingDocument: purchasingDocument,
        startDate: fromDate
          ? moment(fromDate).startOf("day").format("YYYY-MM-DD")
          : "",
        endDate: toDate ? moment(toDate).endOf("day").format("YYYY-MM-DD") : "",
      };
      const data = await getPicking(params);

      if (data && Array.isArray(data.data.data)) {
        setResponseData(data.data.data);
        setPaginationData(data?.data);
        setTotalDocuments(data.total);
        if (data?.data?.data?.length === 0) {
          toast.error("No data found.");
        } else {
          setCurrentPage(data.data.page);
          setTotalDocuments(data.data.total);
          setItemsPerPage(10);
        }
      } else {
        console.error("Unexpected API response structure:", data);
      }
    } catch (err) {
      console.error("Error fetching printed items:", err);
    } finally {
      setLoading(false);
    }
  };
  const getCSV = async () => {
    const newFromDate = validateDate(fromDate);
    const newToDate = validateDate(toDate);
    if (!newFromDate || !newToDate) {
      toast.error("Please enter valid date ranges.");
      return;
    }

    setLoading(true);
    try {
      const params = {
        purchasingDocument: purchasingDocument,
        startDate: fromDate
          ? moment(fromDate).startOf("day").toISOString()
          : "",
        endDate: toDate ? moment(toDate).endOf("day").toISOString() : "",
      };
      const response = await axios.get(`${localServer}store/picking/csv`, {
        params,
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "PickingReport.csv");
      document.body.appendChild(link);

      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getPrintedItems();
  }, []);

const onGetData = (e, value) => {
  e.preventDefault();
  setLoading(true);
  try {
    const response = responseData;
    const document = response.find(
      (doc) => doc.purchasingDocument === value
    );
    if (document) {
      const items = document.items;
      if (items.length > 0) {
        // navigate("/pickingItemsDetail", {
        //   state: { item: items },
        // });
        setShowDetailModal(items)
      } else {
        toast.error("No items available for this purchasing document.");
      }
    } else {
      toast.error("No matching purchasing document found.");
    }
  } catch (error) {
    console.error("Error in onGetData:", error);
    toast.error("An error occurred while fetching data.");
  } finally {
    setLoading(false);
  }
};

  const headers = [
    "Created Time",
    "Purchasing Document",
    "Purchasing Doc Type",
    "Purchasing Doc Category",
    "Purchasing Group",
    "Document Date",
    "Supplier",
    "Qty.",
    "Received Qty.",
    "Plant",
    "Storage Location",
    "Status",
  ];
  const colNames = [
    "createdAt",
    "purchasingDocument",
    "purchasingDocType",
    "purchasingDocCategory",
    "purchasingGroup",
    "documentDate",
    "supplier",
    "defaultItems",
    "receivedItems",
    "plant",
    "storageLocation",
    "status",
  ];

  	const startItem = currentPage ? (currentPage - 1) * itemsPerPage + 1 : 0;
    const endItem = currentPage
      ? Math.min(currentPage * itemsPerPage, totalDocuments)
      : 0;

  return (
    <React.Fragment>
      <Loader loading={loading} />

      <div className="dashboard-main">
        <TableModal
          closeHandler={() => setShowDetailModal(null)}
          modalOpen={!!showDetailModal}
          HeadAndRow={HeadAndRowPicking}
          data={showDetailModal}
          links={["Barcode"]}
          tableTitle="Picking Item Details"
        />
        <div
          className="dashboard-top"
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                flex: "1",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p
                className="stock-count"
                style={{ fontSize: "25px", margin: 0 }}
              >
                Picking
              </p>
            </div>
            <GrDocumentCsv
              onClick={getCSV}
              color="black"
              fontSize={25}
              style={{ marginRight: 5, cursor: "pointer" }}
            />
          </div>
          <div>
            <h1 style={{ color: "black", fontSize: "15px" }}>
              {new Date().toLocaleString("hi-IN")}
            </h1>
          </div>
        </div>
        <div className="dashboard-bottom" style={{ marginTop: "0.5%" }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              gridColumnGap: "20px",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                gridColumnGap: "10px",
                gridRowGap: "10px",
                flexWrap: "wrap",
              }}
            >
              <DesktopDatePicker
                label="From Date"
                clearable
                showTodayButton
                inputFormat="MM/DD/YYYY"
                size={"small"}
                maxDate={new Date()}
                value={fromDate}
                onChange={(data) => setFromDate(data)}
                componentsProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                renderInput={(params) => (
                  <TextField sx={{ width: 170 }} size={"small"} {...params} />
                )}
              />
              <DesktopDatePicker
                label="To Date"
                clearable
                showTodayButton
                inputFormat="MM/DD/YYYY"
                size={"small"}
                maxDate={new Date()}
                value={toDate}
                onChange={(data) => setToDate(data)}
                componentsProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                renderInput={(params) => (
                  <TextField sx={{ width: 170 }} size={"small"} {...params} />
                )}
              />
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                freeSolo={true}
                size={"small"}
                options={responseData
                  ?.filter((item) => item?.purchasingDocument !== "" && item?.purchasingDocument !== undefined)
                  .map((item) => item?.purchasingDocument)
                  .reduce((noDupArr, entry) => {
                    if (noDupArr.includes(entry)) {
                      return noDupArr;
                    } else {
                      return [...noDupArr, entry];
                    }
                  }, [])}
                onChange={(e, v) => setPurchasingDocument(v === null ? "" : v)}
                onInputChange={(e) =>
                  setPurchasingDocument(
                    e.target?.value === 0 || e.target?.value === undefined
                      ? ""
                      : e.target?.value
                  )
                }
                sx={{ width: 170 }}
                renderInput={(params) => (
                  <TextField {...params} label="Purchasing Document" />
                )}
              />
            </div>
            <button onClick={() => getPrintedItems()} className="run-btn">
              <p className="run-text">Search</p>
            </button>
          </div>

          <div
            style={{
              marginTop: "10px",
              overflowX: "scroll",
              height: "600px",
            }}
          >
            <PaginatedItems
              pageCountNew={paginationData?.next + paginationData?.previous + 1}
              getData={(pageNumber) => getPrintedItems(pageNumber)}
              totalLength={totalDocuments}
              headers={headers}
              colNames={colNames}
              forcePage={paginationData?.previous || 0}
              itemsPerPage={itemsPerPage}
              data={responseData.map((item) => ({
                ...formatItem(item),
                createdAt: moment(item.createdAt).format(
                  "MM/DD/YYYY hh:mm:ss A"
                ),
                purchasingDocument: (
                  <Link
                    to="#"
                    onClick={(e) => onGetData(e, item.purchasingDocument)}
                  >
                    {item.purchasingDocument}
                  </Link>
                ),
                status: capitalizeFirstLetter(item.status),
              }))}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
              color: "white",
              padding: "10px",
              gap: "10px",
            }}
          >
            <h5>ROWS PER PAGE: {itemsPerPage}</h5>
            <h5>{`${startItem}-${endItem} of ${totalDocuments}`}</h5>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Picking);
