import React, { Component } from "react";
import { withRouter } from "../../components/withRouter";
import MyInput from "../../components/MyInput";
import { addRole, editRoles } from "../../Path/Path";
import { toast } from "react-toastify";
import { Autocomplete, TextField } from "@mui/material";
import { USER_ROLES } from "../../components/Helper";



class AddRole extends Component {
    constructor(props) {
        super(props)
        this.state = {
            permission: [],
            name: "",
        }
    }

    checkEvent = (e) => {
        const { checked, value } = e.target;
        if (!this.state.permission.includes(value) && checked === true) {
            this.setState({ permission: [...this.state.permission, value] })
        } else {
            this.setState({
                permission: this.state.permission.filter((elem) => {
                    return elem !== value;
                })
            })

        }
    }

    sendHandler = async () => {


        let body = {
            role_name: this.state.name,
            permissions: this.state.permission
        }

        if (this.state.name === "") {
            toast.warn("Enter role name")
        } else if (this.state.permission.length === 0) {
            toast.warn("Select permissions")
        } else if (this.props.location?.state?.item) {
            await editRoles(body, this.props.location?.state?.item._id)
            this.props.history("/roles")
            toast.success("Role edit successfully")
        } else {

            await addRole(body)
                .then((res => {
                    toast.success("Role Added")
                    this.props.history("/roles")
                })).catch((err => {
                    toast.error("Something Went Wrong")
                }))
        }
    }

    async componentDidMount() {

        if (this.props.location.state?.item) {
            const item = this.props.location.state.item
            this.setState({
                name: item.role_name,
                permission: item.permissions
            })
        }
    }



    render() {
        console.log(this.state.permission);
        return (
            <div className="dashboard-main">
                <div className="dashboard-top">
                    <div> <p className="stock-count">Add Roles</p></div>
                </div>
                <div className="role-bottom">
                    <TextField
                        sx={{ width: 235 }}
                        defaultValue={this.state.name}
                        value={this.state.name}
                        placeholder="Role Name"
                        size={'small'}
                        onChange={(data) => this.setState({ name: data.target.value })} id="outlined-basic" label="Role Name" variant="outlined" />
                </div>
                <div className="check-bottom">
                    <p className="check-para">User Permissions *</p>
                    <p className="all-para" style={{ marginTop: "10px" }}> Home</p>
                    <div className="all-checks" style={{ marginBottom: "20px" }}>
                        <div className='check'>
                            <input type="checkbox" checked={this.state.permission.includes("Home Dashboard")} name="permission" id="Home Dashboard" value="Home Dashboard" onChange={(e) => this.checkEvent(e)} />
                            <label htmlFor="Home Dashboard" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Dashboard</label>
                        </div>
                    </div>
                    <div className="flex-role">
                        <div className="admin-per">
                            <p className="all-para" style={{ marginTop: "10px" }}> Admin</p>
                            <div className="all-checks">
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Admin")} name="permission" id="Admin" value="Admin" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Admin" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Admin</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Admin Dashboard")} name="permission" id="Admin Dashboard" value="Admin Dashboard" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Admin Dashboard" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Admin Dashboard</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Users")} name="permission" id="Users" value="Users" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Users" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Users</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Devices")} name="permission" id="Devices" value="Devices" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Devices" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Devices</label>
                                </div>
                                {/* <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Transfer Cancellation")} name="permission" id="Transfer Cancellation" value="Transfer Cancellation" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Transfer Cancellation" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Transfer Cancellation</label>
                                </div> */}
                                {/* <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Gi Cancellation")} name="permission" id="Gi Cancellation" value="Gi Cancellation" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Gi Cancellation" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Gi Cancellation</label>
                                </div> */}
                                {/* <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Manual Receiving Ibt")} name="permission" id="Manual Receiving Ibt" value="Manual Receiving Ibt" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Manual Receiving Ibt" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Manual Receiving Ibt</label>
                                </div> */}
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Stores")} name="permission" id="Stores" value="Stores" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Stores" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Stores</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("AsnCancelation")} name="permission" id="AsnCancelation" value="AsnCancelation" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="AsnCancelation" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>AsnCancelation</label>
                                </div>
                                {/* <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Printer")} name="permission" id="Printer" value="Printer" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Printer" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Printer</label>
                                </div> */}
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("ZPL")} name="permission" id="ZPL" value="ZPL" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="ZPL" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>ZPL</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Zones")} name="permission" id="Zones" value="Zones" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Zones" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Zones</label>
                                </div>
                                {/* <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Audit")} name="permission" id="Audit" value="Audit" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Audit" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Audit</label>
                                </div> */}
                                {/* <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("IBT Differences")} name="permission" id="IBT Differences" value="IBT Differences" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="IBT Differences" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>IBT Differences</label>
                                </div> */}
                                {/* <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Admin Menu")} name="permission" id="Admin Menu" value="Admin Menu" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Admin Menu" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Admin Menu</label>
                                </div> */}
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Roles")} name="permission" id="Roles" value="Roles" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Roles" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Roles</label>
                                </div>
                            </div>
                        </div>
                        <div className="count-per">
                            <p className="all-para" style={{ marginTop: "10px" }}> Count</p>
                            <div className="all-checks">
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Count Dashboard")} name="permission" id="Count Dashboard" value="Count Dashboard" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Count Dashboard" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Dashboard</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Operation")} name="permission" id="Operation" value="Operation" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Operation" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Operation</label>
                                </div>
                                {/* <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Operation 0")} name="permission" id="Operation 0" value="Operation 0" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Operation 0" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Operation 0</label>
                                </div> */}
                                {/* <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Inventory (EPC)")} name="permission" id="Inventory (EPC)" value="Inventory (EPC)" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Inventory (EPC)" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Inventory (EPC)</label>
                                </div> */}
                            </div>
                        </div>
                        {/* <div className="supply-per">
                            <p className="all-para" style={{ marginTop: "10px" }}> Supply Chain</p>
                            <div className="all-checks">
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Transfers")} name="permission" id="Transfers" value="Transfers" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Transfers" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Transfers</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("IN-Store GI")} name="permission" id="IN-Store GI" value="IN-Store GI" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="IN-Store GI" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>IN-Store GI</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Gi Summary")} name="permission" id="Gi Summary" value="Gi Summary" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Gi Summary" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Gi Summary</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Warehouse GI")} name="permission" id="Warehouse GI" value="Warehouse GI" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Warehouse GI" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Warehouse GI</label>
                                </div>
                            </div>
                        </div> */}
                        <div className="Tagit-per">
                            <p className="all-para" style={{ marginTop: "10px" }}> TagIT</p>
                            <div className="all-checks">
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("ZPL Printer")} name="permission" id="ZPL Printer" value="ZPL Printer" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="ZPL Printer" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>ZPL Printer</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("ZPL Report")} name="permission" id="ZPL Report" value="ZPL Report" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="ZPL Report" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>ZPL Report</label>
                                </div>
                                {/* <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("ZPL Report(SKU)")} name="permission" id="ZPL Report(SKU)" value="ZPL Report(SKU)" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="ZPL Report(SKU)" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>ZPL Report(SKU)</label>
                                </div> */}
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Tag Printing")} name="permission" id="Tag Printing" value="Tag Printing" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Tag Printing" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Tag Printing</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("TO Printing")} name="permission" id="TO Printing" value="TO Printing" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="TO Printing" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>TO Printing</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Bulk Printing")} name="permission" id="Bulk Printing" value="Bulk Printing" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Bulk Printing" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Bulk Printing</label>
                                </div>
                            </div>
                        </div>

                        {/* <div className="production-per">
                            <p className="all-para" style={{ marginTop: "10px" }}> Productionzation</p>
                            <div className="all-checks">
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("QR Code")} name="permission" id="QR Code" value="QR Code" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="QR Code" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>QR Code</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Login")} name="permission" id="Login" value="Login" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Login" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Login</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Main Screen")} name="permission" id="Main Screen" value="Main Screen" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Main Screen" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Main Screen</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Target Stock")} name="permission" id="Target Stock" value="Target Stock" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Target Stock" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Target Stock</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("More Filters (Cycle Count)")} name="permission" id="More Filters (Cycle Count)" value="More Filters (Cycle Count)" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="More Filters (Cycle Count)" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>More Filters (Cycle Count)</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Receive Items")} name="permission" id="Receive Items" value="Receive Items" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Receive Items" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Receive Items</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Receive Items Details")} name="permission" id="Receive Items Details" value="Receive Items Details" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Receive Items Details" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Receive Items Details</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Transfer Items Details")} name="permission" id="Transfer Items Details" value="Transfer Items Details" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Transfer Items Details" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Transfer Items Details</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Packing Items Details")} name="permission" id="Packing Items Details" value="Packing Items Details" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Packing Items Details" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Packing Items Details</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Packing Items")} name="permission" id="Packing Items" value="Packing Items" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Packing Items" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Packing Items</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Transfer Out")} name="permission" id="Transfer Out" value="Transfer Out" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Transfer Out" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Transfer Out</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Encode Tag")} name="permission" id="Encode Tag" value="Encode Tag" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Encode Tag" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Encode Tag</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Manage Tag")} name="permission" id="Manage Tag" value="Manage Tag" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Manage Tag" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Manage Tag</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Device Setup Step 1")} name="permission" id="Device Setup Step 1" value="Device Setup Step 1" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Device Setup Step 1" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Device Setup Step 1</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Cycle Count 1")} name="permission" id="Cycle Count 1" value="Cycle Count 1" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Cycle Count 1" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Cycle Count 1</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Cycle Count 2")} name="permission" id="Cycle Count 2" value="Cycle Count 2" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Cycle Count 2" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Cycle Count 2</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Locate Item")} name="permission" id="Locate Item" value="Locate Item" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Locate Item" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Locate Item</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Device Setup")} name="permission" id="Device Setup" value="Device Setup" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Device Setup" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Device Setup</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Change Status")} name="permission" id="Change Status" value="Change Status" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Change Status" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Change Status</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Gi Status")} name="permission" id="Gi Status" value="Gi Status" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Gi Status" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Gi Status</label>
                                </div>
                            </div>
                        </div> */}
                        <div className="rtv-per">
                            <p className="all-para" style={{ marginTop: "10px" }}>Supply Chain</p>
                            <div className="all-checks">
                                {/* <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("RTV Report")} name="permission" id="RTV Report" value="RTV Report" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="RTV Report" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>RTV Report</label>
                                </div> */}
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("Consignment")} name="permission" id="Consignment" value="Consignment" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="Consignment" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>Consignment</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("PO Report")} name="permission" id="PO Report" value="PO Report" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="PO Report" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>PO Report</label>
                                </div>
                                <div className='check'>
                                    <input type="checkbox" checked={this.state.permission.includes("To Report")} name="permission" id="To Report" value="To Report" onChange={(e) => this.checkEvent(e)} />
                                    <label htmlFor="To Report" style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>To Report</label>
                                </div>
                            </div>
                        </div>
                        <div className="rtv-per">
                            <p className="all-para" style={{ marginTop: "10px" }}>Mobile</p>
                            <div className="all-checks">

                                {
                                    USER_ROLES.MOBILE?.map((item, index) =>
                                        <div key={index} className='check'>
                                            <input type="checkbox" checked={this.state.permission.includes(item)} name="permission" id={item} value={item} onChange={(e) => this.checkEvent(e)} />
                                            <label htmlFor={item} style={{ color: "#fff", fontWeight: "400", marginTop: "8px", marginLeft: "5px" }}>{item}</label>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="role-btn" style={{ marginBottom: "20px", display: "flex", justifyContent: "center" }}>
                        <button className="run-btn" onClick={() => this.sendHandler()}><p className="run-text">Add Role</p></button>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(AddRole);