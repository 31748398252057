import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core';


const PermissionsModal = ({ open, onClose, onClick, roles_name, permissions = [], responseData }) => {
    const validPermissions = Array.isArray(permissions) ? permissions : [];
    const validResponse = responseData && typeof responseData === 'object' ? responseData : {};
    return (
      <Dialog
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        open={open}
        onClose={onClose}
      >
        <DialogTitle id="dialog-title">{roles_name}</DialogTitle>
        <DialogContent>
          <div style={{ minHeight: "400px", width: "400px" }}>
            {validPermissions?.map((elem) => (
              <p style={{ color: "#000" }}>{elem}</p>
            ))}

            {/* <p style={{ color: "#000" }}>{`EPC : ${data?.epc}`}</p>
                    <p style={{ color: "#000" }}>{`Item_Code : ${data?.item_code}`}</p>
                    <p style={{ color: "#000" }}>{`Store : ${data?.store_id}`}</p>
                    <p style={{ color: "#000" }}>{`Brand Name : ${data?.brand_name}`}</p>
                    <p style={{ color: "#000" }}>{`Color : ${data?.color}`}</p>
                    <p style={{ color: "#000" }}>{`Size : ${data?.size}`}</p>
                    <p style={{ color: "#000" }}>{`Item Dispostion : ${data?.ItemDispostion}`}</p> */}

            {validResponse.message && (
              <div>
                <p style={{ color: validResponse.success === true || validResponse.message=== "Packing List processed and data saved" ? "green" : "red", fontWeight: "bold" }}>{validResponse.message}</p>

                {validResponse.errors?.map((error, index) => (
                  <div key={index} style={{ marginBottom: '10px' }}>
                    <p style={{ color: "red", fontWeight: "bold" }}>{`Row ${error.row}:`}</p>
                    {error.message ? (
                      <p style={{ color: "black" }}>{error.message}</p>
                    ) : (
                      <p style={{ color: "black" }}>
                        {`Missing Fields: ${error.missingFields?.join(', ')}`}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button style={{background:'#0069D9', color:'white'}} onClick={onClick}>Close</Button>
        </DialogActions>
      </Dialog>
    );
}

export default PermissionsModal
