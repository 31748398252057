import React, { useState } from "react";
import { RiAdminLine } from "react-icons/ri";
import { MdOutlineCalculate } from "react-icons/md";
import { MdOutlineTransferWithinAStation } from "react-icons/md";
import { AiTwotoneTag } from "react-icons/ai";
import { MdDashboard } from "react-icons/md";
import { IoIosFlower } from "react-icons/io";
import { MdDevices } from "react-icons/md";
import { TbUsers } from "react-icons/tb";
import { FaTshirt } from "react-icons/fa";
import { RiPrinterFill } from "react-icons/ri";
import { FaStoreAlt } from "react-icons/fa";
import { CgArrowAlignH } from "react-icons/cg";
import { withRouter } from "./withRouter";
import { useEffect } from "react";
import jwtDecode from "jwt-decode";
import { Navigate, useNavigate } from "react-router-dom";

const Header = (props) => {
  const navigate = useNavigate();
  const [Productionzation, SetProductionzation] = useState(false);
  const [Admin, SetAdmin] = useState(false);
  const [TagIT, SetTagIT] = useState(false);
  const [Count, SetCount] = useState(false);
  const [Report, SetReport] = useState(false);
  const [SupplyChain, SetSupplyChain] = useState(false);
  const [permissions, setPermissions] = useState([]);
    const [activeTab, setActiveTab] = useState(null);
    const [activeSubTab, setActiveSubTab] = useState("/");

  useEffect(() => {
    async function getLoginData(params) {
      const data = await localStorage.getItem("loginData");
      const parseData = JSON.parse(data);
      const token = parseData?.token;
      if (token) {
        const decodetoken = jwtDecode(token);
        console.log(decodetoken?.payload);
        setPermissions(
          decodetoken?.payload?.roleId?.permissions
            ? decodetoken?.payload?.roleId?.permissions
            : []
        );
      }
    }
    getLoginData();

  const path = window.location.pathname;
		if (
      path.includes("/users") ||
      path.includes("/roles") ||
      path.includes("/devices") ||
      path.includes("/store") ||
      path.includes("/BinLocations") ||
      path === ("/zpl") ||
      path === ("/manualReceiving") 
    ) {
      SetAdmin(true);
      setActiveTab("ADMIN");
    } else if (
      path.includes("/supplychain") ||
      path.includes("/operation") ||
      path.includes("/targetedstockcount") ||
      path.includes("/targetDashboard") ||
      path.includes("/TargetStockCountOperation") 
    ) {
      SetCount(true);
      setActiveTab("COUNT");
    } else if (
      path.includes("/tagit") ||
      path === "/zplreportsku" ||
      path === "/bulk-printing"
    ) {
      SetTagIT(true);
      setActiveTab("TAGIT");
    } else if (
      path.includes("/itemMaster") ||
      path.includes("/soh") ||
      path.includes("/purchaseOrder") ||
      path === "/trasnsferOrder" ||
      path.includes("/pickingreport") ||
      path.includes("/po-packing-list") ||
      path.includes("/drill-down")
    ) {
      SetReport(true);
      setActiveTab("REPORT");
    } else if (
      path === "/transfers" ||
      path === "/PLCreated" ||
      path === "/PLNotCreated"
    ) {
      SetSupplyChain(true);
      setActiveTab("SUPPLYCHAIN");
    }
  }, []);

  const onAdminPress = () => {
    SetAdmin(true);
    SetProductionzation(false);
    SetTagIT(false);
    SetCount(false);
    SetSupplyChain(false);
    // props.onSupplyToggle(false)
    props.onToggle(true);
    navigate("/users")
  };
  const onCountPress = () => {
    SetAdmin(false);
    SetProductionzation(false);
    SetTagIT(false);
    SetCount(true);
    SetReport(false)
    props.onCountToggle(true);
    props.onToggle(false);
    SetSupplyChain(false);
    // props.onSupplyToggle(false)
    props.onTagToggle(false);
    navigate("/supplychain")
  };
  const onTagitPress = () => {
    SetAdmin(false);
    SetProductionzation(false);
    SetTagIT(true);
    SetCount(false);
    props.onToggle(false);
    props.onCountToggle(false);
    props.onTagToggle(true);
    SetSupplyChain(false);
    // props.onSupplyToggle(false)
    navigate("/tagit")
  };
  const onReportPress = () => {
    SetReport(true);
    SetAdmin(false);
    SetProductionzation(false);
    SetTagIT(false);
    SetCount(false);
    props.onToggle(false);
    props.onCountToggle(false);
    props.onTagToggle(true);
    SetSupplyChain(false);
    // props.onSupplyToggle(false)
    navigate("/itemMaster")
  };
  const onSupplyChainPress = () => {
    SetAdmin(false);
    SetProductionzation(false);
    SetTagIT(false);
    SetCount(false);
    SetReport(false)
    SetSupplyChain(true);
    props.onToggle(false);
    props.onCountToggle(false);
    props.onTagToggle(false);
    // props.onSupplyToggle(true)
    navigate("transfers")
  };

  const onTabPress = (tab, navigatePath, onPressFunction) => {
    setActiveTab(tab)
    navigate(navigatePath)
    onPressFunction()
  }

  const handleTabClick =(path) =>{
    setActiveSubTab(path);
    props.history(path);
  }

  const changeColor = (pathname) => {
    return window.location?.pathname === pathname ? "#808080" : "transparent";
  };

  const getTextColor = (pathname) => {
    return window.location?.pathname === pathname ? "white" : "white";
  };

  const getIconColor = (pathname) => {
    return window.location?.pathname === pathname ? "white" : "white";
  };

  return (
    <div className="header">
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            backgroundColor: "black",
            flexWrap: "wrap",
          }}
        >
          <div
            className="ino-admin"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {permissions.includes("Admin Dashboard") ||
            permissions.includes("Roles") ||
            permissions.includes("Admin") ||
            permissions.includes("Users") ||
            permissions.includes("Stores") ||
            permissions.includes("AsnCancelation") ||
            permissions.includes("Zones") ||
            permissions.includes("ZPL") ||
            permissions.includes("Zones") ? (
              <div
                onClick={() => onTabPress("ADMIN", "/user", onAdminPress)}
                className={`ino-admin ${
                  activeTab === "ADMIN" ? "active-tab" : ""
                }`}
                style={{
                  width: "200px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "200px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <RiAdminLine
                    size={20}
                    color={activeTab === "ADMIN" ? "white" : "white"}
                  ></RiAdminLine>
                  <p
                    className="header-text"
                    style={{ color: activeTab === "ADMIN" ? "white" : "white" }}
                  >
                    Admin
                  </p>
                </div>
              </div>
            ) : null}
            <div className="line"></div>
          </div>
          {/* <div
            className="ino-admin"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              onClick={() =>
                onTabPress("SUPPLYCHAIN", "/transfers", onSupplyChainPress)
              }
              className={`ino-admin ${
                activeTab === "SUPPLYCHAIN" ? "active-tab" : ""
              }`}
              style={{
                width: "200px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MdOutlineTransferWithinAStation
                size={20}
                color={activeTab === "SUPPLYCHAIN" ? "white" : "white"}
              ></MdOutlineTransferWithinAStation>
              <p
                className="header-text"
                style={{
                  color: activeTab === "SUPPLYCHAIN" ? "white" : "white",
                }}
              >
                Supply Chain
              </p>
            </div>
            <div className="line"></div>
          </div> */}
          {/* {

                        permissions.includes('Count Dashboard')
                            ||
                            permissions.includes('Operation')
                            ? */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              background: "black",
              flexWrap: "wrap",
            }}
          >
            <div
              onClick={() => onTabPress("COUNT", "/supplychain", onCountPress)}
              className={`ino-admin ${
                activeTab === "COUNT" ? "active-tab" : ""
              }`}
              style={{
                width: "200px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MdOutlineCalculate
                size={20}
                color={activeTab === "COUNT" ? "white" : "white"}
              ></MdOutlineCalculate>
              <p
                className="header-text"
                style={{ color: activeTab === "COUNT" ? "white" : "white" }}
              >
                Count
              </p>
            </div>
            <div className="line"></div>
          </div>
          {/* : null} */}
          {/* {

                        permissions.includes('PO Report')
                            ||
                            permissions.includes('To Report')
                            ||
                            permissions.includes('Consignment')
                            ? */}
          <div
            className="ino-admin"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              onClick={() => onTabPress("REPORT", "/itemMaster", onReportPress)}
              className={`ino-admin ${
                activeTab === "REPORT" ? "active-tab" : ""
              }`}
              style={{
                width: "200px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MdOutlineTransferWithinAStation
                size={20}
                color={activeTab === "REPORT" ? "white" : "white"}
              ></MdOutlineTransferWithinAStation>
              <p
                className="header-text"
                style={{ color: activeTab === "REPORT" ? "white" : "white" }}
              >
                Reports
              </p>
            </div>
            <div className="line"></div>
          </div>

          {/* : null} */}
          {/* {

                        permissions.includes('ZPL Report')
                            ||
                            permissions.includes('ZPL Printer')
                            ||
                            permissions.includes('TO Printing')
                            ||
                            permissions.includes('Tag Printing')
                            ? */}
          <div
            className="ino-admin"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
                onClick={() => onTabPress("TAGIT", "/tagit", onTagitPress)}
                className={`ino-admin ${activeTab === "TAGIT" ? "active-tab" : ""}`}
                style={{
                  width: "200px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: activeTab === "TAGIT" ? "active-tab" : "transparent",
                }}
              >
                <AiTwotoneTag
                  size={20}
                  color={activeTab === "TAGIT" ? "white" : "white"}
                ></AiTwotoneTag>
                <p
                  className="header-text"
                  style={{ color: activeTab === "TAGIT" ? "white" : "white" }}
                >
                  TagIT
                </p>
              </div>
            <div className="line"></div>
          </div>
          {/* : null} */}
          {/* <div className='ino-admin' style={{ display: "flex", justifyContent: "center" }}>
                        <div onClick={() => { SetProductionzation(true); SetAdmin(false); SetSupplyChain(false); SetTagIT(false) }} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <AiTwotoneTag size={20} color="white"></AiTwotoneTag>
                            <p className='header-text'>Productionzation</p>
                        </div>
                        <div className='line'></div>
                    </div> */}
        </div>
        {SupplyChain ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              backgroundColor: "#373E43",
              width: "100%",
              overflow: "auto",
            }}
          >
            <div
              onClick={() => props.history("/transfers")}
              style={{
                width: "200px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: changeColor("/transfers"),
              }}
            >
              <MdDashboard
                size={20}
                color={getIconColor("/transfers")}
              ></MdDashboard>
              <p
                className="header-text"
                style={{ color: getTextColor("/transfers") }}
              >
                INBOUND
              </p>
            </div>
            <div className="line"></div>
            {/* <div
              onClick={() => props.history("/POCreated")}
              style={{
                width: "200px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MdDashboard size={20} color="white"></MdDashboard>
              <p className="header-text">PO (Created)</p>
            </div>
            <div className="line"></div> */}
            {/* <div
              onClick={() => props.history("/TOCreated")}
              style={{
                width: "200px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MdDashboard size={20} color="white"></MdDashboard>
              <p className="header-text">TO (Created)</p>
            </div>
            <div className="line"></div> */}
            <div
              onClick={() => props.history("/PLCreated")}
              style={{
                width: "200px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: changeColor("/PLCreated"),
              }}
            >
              <MdDashboard
                size={20}
                color={getIconColor("/PLCreated")}
              ></MdDashboard>
              <p
                className="header-text"
                style={{ color: getTextColor("/PLCreated") }}
              >
                PL (Created)
              </p>
            </div>
            <div className="line"></div>
            <div
              onClick={() => props.history("/PLNotCreated")}
              style={{
                width: "200px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: changeColor("/PLNotCreated"),
              }}
            >
              <MdDashboard
                size={20}
                color={getIconColor("/PLNotCreated")}
              ></MdDashboard>
              <p
                className="header-text"
                style={{ color: getTextColor("/PLNotCreated") }}
              >
                PL (Not Created)
              </p>
            </div>
            <div className="line"></div>
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                            <div onClick={() => props.history('/production/qrcode')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MdDashboard size={20} color="white"></MdDashboard>
                                <p className='header-text'>Qr Code</p>
                            </div>
                            <div className='line'></div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div onClick={() => props.history('/production/loginProduction')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MdDashboard size={20} color="white"></MdDashboard>
                                <p className='header-text'>Login</p>
                            </div>
                            <div className='line'></div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div onClick={() => props.history('/production/mainscreen')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <AiTwotoneTag size={20} color="white"></AiTwotoneTag>
                                <p className='header-text'>Main Screen</p>
                            </div>
                            <div className='line'></div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div onClick={() => props.history('/production/targetstock')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MdDashboard size={20} color="white"></MdDashboard>
                                <p className='header-text'>Target Stock</p>
                            </div>
                            <div className='line'></div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div onClick={() => props.history('/production/moreFilters')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MdDashboard size={20} color="white"></MdDashboard>
                                <p className='header-text'>More Filters (Cycle Count)</p>
                            </div>
                            <div className='line'></div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div onClick={() => props.history('/production/receivingtags')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <AiTwotoneTag size={20} color="white"></AiTwotoneTag>
                                <p className='header-text'>Receiving Items</p>
                            </div>
                            <div className='line'></div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div onClick={() => props.history('/production/receivingdetailTag')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <AiTwotoneTag size={20} color="white"></AiTwotoneTag>
                                <p className='header-text'>Receiving Items Details</p>
                            </div>
                            <div className='line'></div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div onClick={() => props.history('/production/transferdetailTag')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <AiTwotoneTag size={20} color="white"></AiTwotoneTag>
                                <p className='header-text'>Transfer Items Details</p>
                            </div>
                            <div className='line'></div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div onClick={() => props.history('/production/packingdetailTag')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <AiTwotoneTag size={20} color="white"></AiTwotoneTag>
                                <p className='header-text'>Packing Items Details</p>
                            </div>
                            <div className='line'></div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div onClick={() => props.history('/production/packingtags')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <AiTwotoneTag size={20} color="white"></AiTwotoneTag>
                                <p className='header-text'>Packing Items</p>
                            </div>
                            <div className='line'></div>
                            <div onClick={() => props.history('/production/transfertags')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <AiTwotoneTag size={20} color="white"></AiTwotoneTag>
                                <p className='header-text'>Transfer Out</p>
                            </div>
                            <div className='line'></div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div onClick={() => props.history('/production/encodetag')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MdDashboard size={20} color="white"></MdDashboard>
                                <p className='header-text'>Encode Tag</p>
                            </div>
                            <div className='line'></div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div onClick={() => props.history('/production/managetags')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MdDashboard size={20} color="white"></MdDashboard>
                                <p className='header-text'>Manage Tags</p>
                            </div>
                            <div className='line'></div>
                        </div> */}
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                            <div onClick={() => props.history('/production/lookup')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MdDashboard size={20} color="white"></MdDashboard>
                                <p className='header-text'> Look Up </p>
                            </div>
                            <div className='line'></div>
                        </div> */}
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                            <div onClick={() => props.history('/production/devicesetup1')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MdDashboard size={20} color="white"></MdDashboard>
                                <p className='header-text'> Device Setup Step 1</p>
                            </div>
                            <div className='line'></div>
                        </div> */}
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                            <div onClick={() => props.history('/production/destination')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MdDashboard size={20} color="white"></MdDashboard>
                                <p className='header-text'> Destination</p>
                            </div>
                            <div className='line'></div>
                        </div> */}
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                            <div onClick={() => props.history('/production/cyclecount1')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MdDashboard size={20} color="white"></MdDashboard>
                                <p className='header-text'> Cycle Count 1 </p>
                            </div>
                            <div className='line'></div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div onClick={() => props.history('/production/cyclecount2')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MdDashboard size={20} color="white"></MdDashboard>
                                <p className='header-text'> Cycle Count 2 </p>
                            </div>
                            <div className='line'></div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div onClick={() => props.history('/production/locateitem')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MdDashboard size={20} color="white"></MdDashboard>
                                <p className='header-text'> Locate Item   </p>
                            </div>
                            <div className='line'></div>
                        </div> */}
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                            <div onClick={() => props.history('/production/locateitem1')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MdDashboard size={20} color="white"></MdDashboard>
                                <p className='header-text'> Locate Item 2  </p>
                            </div>
                            <div className='line'></div>
                        </div> */}
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                            <div onClick={() => props.history('/production/devicesetup2')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MdDashboard size={20} color="white"></MdDashboard>
                                <p className='header-text'> Device Setup   </p>
                            </div>
                            <div className='line'></div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div onClick={() => props.history('/production/changestatus')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MdDashboard size={20} color="white"></MdDashboard>
                                <p className='header-text'> Change Status   </p>
                            </div>
                            <div className='line'></div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div onClick={() => props.history('/production/gistatus')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MdDashboard size={20} color="white"></MdDashboard>
                                <p className='header-text'> Gi Status  </p>
                            </div>
                            <div className='line'></div>
                        </div> */}
          </div>
        ) : Admin ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              backgroundColor: "#373E43",
              width: "100%",
              overflow: "auto",
            }}
          >
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                <div onClick={() => props.history('/admin')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <MdDashboard size={20} color="white"></MdDashboard>
                                    <p className='header-text'>Dashboard</p>
                                </div>
                                <div className='line'></div>
                            </div> */}
            {/* {permissions.includes("Users") ? */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                onClick={() => props.history("/users")}
                style={{
                  width: "200px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: changeColor("/users"),
                }}
              >
                <TbUsers size={20} color={getIconColor("/users")}></TbUsers>
                <p
                  className="header-text"
                  style={{ color: getTextColor("/users") }}
                >
                  Users
                </p>
              </div>
              <div className="line"></div>
            </div>
            {/* : null} */}

            {/* {permissions.includes("Devices") ? */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                onClick={() => props.history("/devices")}
                style={{
                  width: "200px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: changeColor("/devices"),
                }}
              >
                <MdDevices
                  size={20}
                  color={getIconColor("/devices")}
                ></MdDevices>
                <p
                  className="header-text"
                  style={{ color: getTextColor("/devices") }}
                >
                  Devices
                </p>
              </div>
              <div className="line"></div>
            </div>
            {/* : null} */}

            <div style={{ display: "flex", justifyContent: "center" }}>
              {/* <div onClick={() => props.history('/manualrecivingibt')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <AiTwotoneTag size={20} color="white"></AiTwotoneTag>
                                    <p className='header-text'>Manual Receiving IBT</p>
                                </div>
                                <div className='line'></div> */}
              {/* {permissions.includes("Stores") ? */}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  onClick={() => props.history("/store")}
                  style={{
                    width: "200px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: changeColor("/store"),
                  }}
                >
                  <FaStoreAlt
                    size={20}
                    color={getIconColor("/store")}
                  ></FaStoreAlt>
                  <p
                    className="header-text"
                    style={{ color: getTextColor("/store") }}
                  >
                    Store
                  </p>
                </div>
                <div className="line"></div>
              </div>
              {/* : null} */}

              {permissions.includes("ZPL") ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    onClick={() => props.history("/zpl")}
                    style={{
                      width: "200px",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: changeColor("/zpl"),
                    }}
                  >
                    <CgArrowAlignH
                      size={20}
                      color={getIconColor("/zpl")}
                    ></CgArrowAlignH>
                    <p
                      className="header-text"
                      style={{ color: getTextColor("/zpl") }}
                    >
                      ZPL
                    </p>
                  </div>
                  <div className="line"></div>
                </div>
              ) : null}

              {/* {permissions.includes("Roles") ? */}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  onClick={() => props.history("/roles")}
                  style={{
                    width: "200px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: changeColor("/roles"),
                  }}
                >
                  {/* <AiTwotoneTag size={20} color="white"></AiTwotoneTag> */}
                  <p
                    className="header-text"
                    style={{ color: getTextColor("/roles") }}
                  >
                    Roles
                  </p>
                </div>
                <div className="line"></div>
              </div>
              {/* <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  onClick={() => props.history("/manualReceiving")}
                  style={{
                    width: "200px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: changeColor("/manualReceiving"),
                  }}
                >
                  <p
                    className="header-text"
                    style={{ color: getTextColor("/manualReceiving") }}
                  >
                    Manual Receiving
                  </p>
                </div>
                <div className="line"></div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  onClick={() => props.history("/BinLocations")}
                  style={{
                    width: "200px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: changeColor("/BinLocations"),
                  }}
                >
                  <p
                    className="header-text"
                    style={{ color: getTextColor("/BinLocations") }}
                  >
                    Bin Location
                  </p>
                </div>
                <div className="line"></div>
              </div> */}
              {/* : null} */}
            </div>
          </div>
        ) : TagIT ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              backgroundColor: "#373E43",
              width: "100%",
              overflow: "auto",
            }}
          >
            {permissions.includes("ZPL Printer") ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  onClick={() => props.history("/tagit")}
                  style={{
                    width: "200px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: changeColor("/tagit"),
                  }}
                >
                  <MdDashboard
                    size={20}
                    color={getIconColor("/tagit")}
                  ></MdDashboard>
                  <p
                    className="header-text"
                    style={{ color: getTextColor("/tagit") }}
                  >
                    Tag IT
                  </p>
                </div>
                <div className="line"></div>
              </div>
            ) : null}
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                    <div onClick={() => props.history('/zplreport')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <MdDashboard size={20} color="white"></MdDashboard>
                                        <p className='header-text'>ZPL Report</p>
                                    </div>
                                    <div className='line'></div>
                                </div> */}
            {permissions.includes("ZPL Report") ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  onClick={() => props.history("/zplreportsku")}
                  style={{
                    width: "200px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: changeColor("/zplreportsku"),
                  }}
                >
                  <MdDashboard
                    size={20}
                    color={getIconColor("/zplreportsku")}
                  ></MdDashboard>
                  <p
                    className="header-text"
                    style={{ color: getTextColor("/zplreportsku") }}
                  >
                    Printed Items
                  </p>
                </div>
                <div className="line"></div>
              </div>
            ) : null}
            {permissions.includes("Bulk Printing") ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  onClick={() => props.history("/bulk-printing")}
                  style={{
                    width: "200px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: changeColor("/bulk-printing"),
                  }}
                >
                  <MdDashboard
                    size={20}
                    color={getIconColor("/bulk-printing")}
                  ></MdDashboard>
                  <p
                    className="header-text"
                    style={{ color: getTextColor("/bulk-printing") }}
                  >
                    Bulk Printing
                  </p>
                </div>
                <div className="line"></div>
              </div>
            ) : null}
            {/* {permissions.includes("Tag Printing") ?
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <div onClick={() => props.history('/tagprinting')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <FaTshirt size={20} color="white"></FaTshirt>
                                            <p className='header-text'>PO Printing</p>
                                        </div>
                                        <div className='line'></div>
                                    </div>
                                    : null} */}
            {/* {permissions.includes("TO Printing") ?
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <div onClick={() => props.history('/tagprintingRtv')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <FaTshirt size={20} color="white"></FaTshirt>
                                            <p className='header-text'>TO Number Printing</p>
                                        </div>
                                        <div className='line'></div>
                                    </div>
                                    : null} */}
          </div>
        ) : Report ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              backgroundColor: "#373E43",
              width: "100%",
              overflow: "auto",
            }}
          >
            {/* {permissions.includes("PO Report") ? */}
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                        <div onClick={() => props.history('/putAway')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <MdDashboard size={20} color="white"></MdDashboard>
                                            <p className='header-text'>Put Away</p>
                                        </div>
                                        <div className='line'></div>
                                    </div> */}
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                        <div onClick={() => props.history('/bintobin')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <MdDashboard size={20} color="white"></MdDashboard>
                                            <p className='header-text'>Bin To Bin</p>
                                        </div>
                                        <div className='line'></div>
                                    </div> */}
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                        <div onClick={() => props.history('/perchasereturn')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <MdDashboard size={20} color="white"></MdDashboard>
                                            <p className='header-text'>Purchase Return</p>
                                        </div>
                                        <div className='line'></div>
                                    </div> */}
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                        <div onClick={() => props.history('/tranferreport')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <MdDashboard size={20} color="white"></MdDashboard>
                                            <p className='header-text'>Transfer</p>
                                        </div>
                                        <div className='line'></div>
                                    </div> */}
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                        <div onClick={() => props.history('/pickingreport')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <MdDashboard size={20} color="white"></MdDashboard>
                                            <p className='header-text'>Picking</p>
                                        </div>
                                        <div className='line'></div>
                                    </div> */}
            {/* : null} */}

            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                        <div onClick={() => props.history('/goodsIn')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <MdDashboard size={20} color="white"></MdDashboard>
                                            <p className='header-text'>Goods In</p>
                                        </div>
                                        <div className='line'></div>
                                    </div> */}

            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                        <div onClick={() => props.history('/binMaster')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <MdDashboard size={20} color="white"></MdDashboard>
                                            <p className='header-text'>Bin Master</p>
                                        </div>
                                        <div className='line'></div>
                                    </div> */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                onClick={() => props.history("/itemMaster")}
                style={{
                  width: "200px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: changeColor("/itemMaster"),
                }}
              >
                <MdDashboard
                  size={20}
                  color={getIconColor("/itemMaster")}
                ></MdDashboard>
                <p
                  className="header-text"
                  style={{ color: getTextColor("/itemMaster") }}
                >
                  Item Master
                </p>
              </div>
              <div className="line"></div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                onClick={() => props.history("/purchaseOrder")}
                style={{
                  width: "200px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: changeColor("/purchaseOrder"),
                }}
              >
                <MdDashboard
                  size={20}
                  color={getIconColor("/purchaseOrder")}
                ></MdDashboard>
                <p
                  className="header-text"
                  style={{ color: getTextColor("/purchaseOrder") }}
                >
                  Purchase Order
                </p>
              </div>
              <div className="line"></div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                onClick={() => props.history("/po-packing-list")}
                style={{
                  width: "200px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: changeColor("/po-packing-list"),
                }}
              >
                <MdDashboard
                  size={20}
                  color={getIconColor("/po-packing-list")}
                ></MdDashboard>
                <p
                  className="header-text"
                  style={{ color: getTextColor("/po-packing-list") }}
                >
                  PO Packing List
                </p>
              </div>
              <div className="line"></div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                onClick={() => props.history("/drill-down")}
                style={{
                  width: "200px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: changeColor("/drill-down"),
                }}
              >
                <MdDashboard
                  size={20}
                  color={getIconColor("/drill-down")}
                ></MdDashboard>
                <p
                  className="header-text"
                  style={{ color: getTextColor("/drill-down") }}
                >
                  PO Drill Down
                </p>
              </div>
              <div className="line"></div>
            </div>
            <div
              onClick={() => props.history("/trasnsferOrder")}
              style={{
                width: "200px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: changeColor("/trasnsferOrder"),
              }}
            >
              <MdDashboard
                size={20}
                color={getIconColor("/trasnsferOrder")}
              ></MdDashboard>
              <p
                className="header-text"
                style={{ color: getTextColor("/trasnsferOrder") }}
              >
                Transfer Order
              </p>
            </div>
            <div className="line"></div>
            <div
              onClick={() => props.history("/soh")}
              style={{
                width: "200px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: changeColor("/soh"),
              }}
            >
              <MdDashboard size={20} color={getIconColor("/soh")}></MdDashboard>
              <p
                className="header-text"
                style={{ color: getTextColor("/soh") }}
              >
                SOH
              </p>
            </div>
            <div className="line"></div>
            <div
              onClick={() => props.history("/pickingreport")}
              style={{
                width: "200px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: changeColor("/pickingreport"),
              }}
            >
              <MdDashboard
                size={20}
                color={getIconColor("/pickingreport")}
              ></MdDashboard>
              <p
                className="header-text"
                style={{ color: getTextColor("/pickingreport") }}
              >
                Picking
              </p>
            </div>
            <div className="line"></div>

            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                            <div onClick={() => props.history('/shipping')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <MdDashboard size={20} color="white"></MdDashboard>
                                                <p className='header-text'>Shipping</p>
                                            </div>
                                            <div className='line'></div>
                                        </div> */}

            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                        <div onClick={() => props.history('/instoregi')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <MdDashboard size={20} color="white"></MdDashboard>
                                            <p className='header-text'>IN-Store GI</p>
                                        </div>
                                        <div className='line'></div>
                                    </div> */}
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                        <div onClick={() => props.history('/gisummary')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <MdDashboard size={20} color="white"></MdDashboard>
                                            <p className='header-text'>Gi Summary</p>
                                        </div>
                                        <div className='line'></div>
                                    </div> */}
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                        <div onClick={() => props.history('/warehouse')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <FaTshirt size={20} color="white"></FaTshirt>
                                            <p className='header-text'>Warehouse GI</p>
                                        </div>
                                        <div className='line'></div>
                                    </div> */}
          </div>
        ) : Count ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              backgroundColor: "#373E43",
              width: "100%",
              overflowX: "scroll",
            }}
          >
            {/* {permissions.includes("Count Dashboard") ? */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                onClick={() => props.history("/supplychain")}
                style={{
                  width: "200px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: changeColor("/supplychain"),
                }}
              >
                <MdDashboard
                  size={20}
                  color={getIconColor("/supplychain")}
                ></MdDashboard>
                <p
                  className="header-text"
                  style={{ color: getTextColor("/supplychain") }}
                >
                  Dashboard
                </p>
              </div>
              <div className="line"></div>
            </div>
            {/* : null} */}
            {/* {permissions.includes("Operation") ? */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                onClick={() => props.history("/operation")}
                style={{
                  width: "200px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: changeColor("/operation"),
                }}
              >
                <IoIosFlower
                  size={20}
                  color={getIconColor("/operation")}
                ></IoIosFlower>
                <p
                  className="header-text"
                  style={{ color: getTextColor("/operation") }}
                >
                  Operation
                </p>
              </div>
              <div className="line"></div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                onClick={() => props.history("/targetedstockcount")}
                style={{
                  width: "200px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: changeColor("/targetedstockcount"),
                }}
              >
                <IoIosFlower
                  size={20}
                  color={getIconColor("/targetedstockcount")}
                ></IoIosFlower>
                <p
                  className="header-text"
                  style={{ color: getTextColor("/targetedstockcount") }}
                >
                  Targeted Stock Count
                </p>
              </div>
              <div className="line"></div>
              <div
                onClick={() => props.history("/targetDashboard")}
                style={{
                  width: "200px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: changeColor("/targetDashboard"),
                }}
              >
                <IoIosFlower
                  size={20}
                  color={getIconColor("/targetDashboard")}
                ></IoIosFlower>
                <p
                  className="header-text"
                  style={{ color: getTextColor("/targetDashboard") }}
                >
                  Targeted Stock Summary
                </p>
              </div>
              <div className="line"></div>
              <div
                onClick={() => props.history("/TargetStockCountOperation")}
                style={{
                  width: "200px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: changeColor("/TargetStockCountOperation"),
                }}
              >
                <IoIosFlower
                  size={20}
                  color={getIconColor("/TargetStockCountOperation")}
                ></IoIosFlower>
                <p
                  className="header-text"
                  style={{ color: getTextColor("/TargetStockCountOperation") }}
                >
                  Targeted Stock Operation
                </p>
              </div>
            </div>
            {/* : null} */}

            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                            <div onClick={() => props.history('/operation')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <IoIosFlower size={20} color="white"></IoIosFlower>
                                                <p className='header-text'>Operation 0</p>
                                            </div>
                                            <div className='line'></div>
                                        </div> */}
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                            <div onClick={() => props.history('/varience')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <IoIosFlower size={20} color="white"></IoIosFlower>
                                                <p className='header-text'>Varience Report</p>
                                            </div>
                                            <div className='line'></div>
                                        </div> */}
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                            <div onClick={() => props.history('/inventory')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <FaTshirt size={20} color="white"></FaTshirt>
                                                <p className='header-text'>Inventory(EPC)</p>
                                            </div>
                                            <div className='line'></div>
                                        </div> */}
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                            <div onClick={() => props.history('/dailystockcount')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <AiTwotoneTag size={20} color="white"></AiTwotoneTag>
                                                <p className='header-text'>Daily Stock Count Report</p>
                                            </div>
                                            <div className='line'></div>
                                        </div> */}
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                            <div style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <AiTwotoneTag size={20} color="white"></AiTwotoneTag>
                                                <p className='header-text'>Overall Under Over</p>
                                            </div>
                                            <div className='line'></div>
                                        </div> */}
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default withRouter(Header);
