import React, { Component } from "react";
import { Calendar } from "react-calendar";
import Dropdown from "../../components/Dropdown";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import print from "../../assets/print.png"
import zoom from "../../assets/zoom.png"
import download from "../../assets/download.png"
import { deletestore, getstores } from "../../Path/Path";
import { withRouter } from "../../components/withRouter";
import MyInput from "../../components/MyInput";
import { toast } from 'react-toastify';
import { PaginatedItems } from "../../components/Paginated";
import { Autocomplete, TextField } from "@mui/material";
import Loader from "../../components/Loader";
import { PaginatedItemsOffline } from "../../components/PaginatedOffline";
import AlertDialog from "../../components/AlertDialog";
class Store extends Component {
    state = {

        storedata: [],
        storename: "",
        storecountry: "",
        storecompany: "",
        tableFilter: [],
        itemsPerPage: 10,
        loading: false,
        deleteModal: null,
    }
    componentDidMount() {
        this.saveNewUsersToState()
    }
    handler = () => {
        this.props.history("/addstore")
    }


    saveNewUsersToState = async () => {
        try{
        this.setState({ loading: true })
        const userinfo = await getstores();
        this.setState({ storedata: userinfo, tableFilter: userinfo })
        this.setState({ loading: false })
         } 
         catch (error) {
            this.setState({loading: false})
            toast.error("Something went wrong")
        }
    }
    deleteHandler = async (myID) => {

        try {

            await deletestore(myID);
            this.saveNewUsersToState();
            toast.success("Store delete successfully")
            this.setState({deleteModal: null})
        } catch (err) {
            toast.warn(err.message)

        }
    }
    editHandler = (item) => {

        this.props.history("/addstore", { state: { item } })
    }
    searchHandler = () => {

        if (!this.state.storename && !this.state.storecompany && !this.state.storecountry) {
            this.setState({ tableFilter: this.state.storedata })
            return
        }

        let results = [...this.state.storedata]
        if (this.state.storename) {

            results = results.filter(i => i.Name.toLowerCase().includes(this.state.storename.toLowerCase()))
        }
        if (this.state.storecountry) {
            results = results.filter(i => i.City.toLowerCase().includes(this.state.storecountry.toLowerCase()))

        }
        if (this.state.storecompany) {

            results = results.filter(i => i.Mall.toLowerCase().includes(this.state.storecompany.toLowerCase()))
        }
        console.log('results: ', results.length);


        this.setState({ tableFilter: results })
    }
    render() {
        const headers = [

            "Store Name",
            "City",
            "Warehouse",
            "Mall",
            "Entity",

        ]

        const colNames = [

            "Name",
            "City",
            "Warehouse",
            "Mall",
            "Entity",


        ]

        return (
            <>
            <AlertDialog
                    deleteThis="User"
                    onDeletePress={() => this.deleteHandler(this.state?.deleteModal)}
                    open={this.state.deleteModal ? true : false}
                    handleClose={() => this.setState({ deleteModal: null })}
                />
                <Loader loading={this.state.loading} />
                <div className="dashboard-main">
                    <div className="dashboard-top">
                        <div> <p className="stock-count" style={{ fontSize: '25px', margin: 0 }}>Store Info</p>
                            <h1 style={{ color: 'black', fontSize: '15px' }}>{new Date().toLocaleString('hi-IN')} </h1>
                        </div>
                        <button className="run-btn"><p className="add-text" onClick={() => this.handler()}>Add Store</p></button>
                    </div>
                    <div className="store-bottom">
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", width: "720px" }}>
                            {/* <p className="filter-text">Filters</p> */}
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                size={'small'}
                                options={this.state.tableFilter?.map((item => item?.Name)).reduce((noDupArr, entry) => {
                                    if (noDupArr?.includes(entry)) {
                                        return noDupArr;
                                    } else {
                                        return [...noDupArr, entry];
                                    }
                                }, []).filter((item => item !== null && item !== undefined))}
                                onChange={(e, v) => this.setState({ storename: v === null ? '' : v })}
                                sx={{ width: 235 }}
                                renderInput={(params) => <TextField
                                    {...params} label="Store Name" />}
                            />
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                size={'small'}
                                options={this.state.tableFilter?.map((item => item?.City)).reduce((noDupArr, entry) => {
                                    if (noDupArr?.includes(entry)) {
                                        return noDupArr;
                                    } else {
                                        return [...noDupArr, entry];
                                    }
                                }, []).filter((item => item !== null && item !== undefined))}
                                onChange={(e, v) => this.setState({ storecountry: v === null ? '' : v })}
                                sx={{ width: 235 }}
                                renderInput={(params) => <TextField
                                    {...params} label="Store City" />}
                            />
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                size={'small'}
                                options={this.state.tableFilter?.map((item => item?.Mall)).reduce((noDupArr, entry) => {
                                    if (noDupArr?.includes(entry)) {
                                        return noDupArr;
                                    } else {
                                        return [...noDupArr, entry];
                                    }
                                }, []).filter((item => item !== null && item !== undefined))}
                                onChange={(e, v) => this.setState({ storecompany: v === null ? '' : v })}
                                sx={{ width: 235 }}
                                renderInput={(params) => <TextField
                                    {...params} label="Store Mall" />}
                            />
                        </div>



                        <button className="run-btn" onClick={() => this.searchHandler()}><p className="run-text">Run</p></button>








                    </div>
                    <div style={{ paddingTop: "2%" }}>
                        <PaginatedItemsOffline headers={headers} editHandler={(item) => this.editHandler(item)} deleteHandler={(item) => this.setState({deleteModal: item._id})} Action colNames={colNames} itemsPerPage={this.state.itemsPerPage} data={this.state.tableFilter} />
                    </div>
                </div>
            </>

        )
    }
}
export default withRouter(Store);
