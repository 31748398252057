import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
import moment from "moment-timezone";

// Example items, to simulate fetching from another resources.
const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

function Items({ currentItems, headers, tableBody, colNames, Action, Permissions, editHandler, deleteHandler, QRCode, modalopen, showPermissionHandler }) {
    const getNested = function (theObject, path, separator) {
        try {
            separator = separator || '.';

            return path.
                replace(/\[/gi, separator).replace(/\]/gi, '').
                split(separator).
                reduce(
                    function (obj, property) {
                        return obj[property];
                    }, theObject
                );
        } catch (err) {
            return undefined;
        }
    }
    return (
        <>
            <table data-table-theme="dark zebra">
                <thead>
                    <tr style={{ background: "#000" }}>
                        {headers.map((item =>
                            <th style={{ background: "#000" ,textAlign:'center'}} >{item ? item : '--'}</th>

                        ))}
                        {
                            QRCode &&
                            <th style={{ background: "#000" }}>QR Code</th>
                        }
                        {
                            Permissions &&
                            <th style={{ background: "#000" }}>Permissions</th>
                        }
                        {
                            Action &&
                            <th style={{ background: "#000" }}>Action</th>
                        }



                    </tr>

                </thead>
                {
                    currentItems &&
                    currentItems.map((item) => (
                        <tbody>
                            <tr style={{ background: "#373E43" }}>
                                {colNames.map((name, index) => {
                                    return (
                                      <td>
                                        {getNested(item, name) === undefined
                                          ? "--"
                                          : name === "createdAt"
                                          ? moment(getNested(item, name))
                                              .tz("Asia/Dubai")
                                              .format("YYYY-MM-DD hh:mm:ss A")
                                          : getNested(item, name)}
                                      </td>
                                    );
                                }
                                )}
                                {
                                    QRCode &&
                                    <td style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{
                                        <div>
                                            <button className="action-btn" onClick={() => modalopen ? modalopen(item) : null} style={{ backgroundColor: "transparent", border: "1 px solid white", borderRadius: "10px" }}>
                                                <p className="action-txt">QR code</p>
                                            </button>
                                        </div>
                                    }</td>
                                }
                                {
                                    Permissions &&
                                    <td style={{ width: "200px" }}>{
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <button className="action-btn" style={{ backgroundColor: "#0069D9", border: "none", borderRadius: "10px" }}
                                                onClick={() => showPermissionHandler ? showPermissionHandler(item) : null}>
                                                <p className="action-txt">View</p>
                                            </button>
                                        </div>
                                    }</td>
                                }

                                {
                                    Action &&
                                    <td style={{ width: "200px" }}>
                                        {
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div>
                                                    <button className="action-btn" style={{ backgroundColor: "#0069D9", border: "none", borderRadius: "10px" }}
                                                        onClick={() => editHandler ? editHandler(item) : null}>
                                                        <p className="action-txt">Edit</p>
                                                    </button>
                                                </div>
                                                <div>
                                                    <button className="action-btn" style={{ backgroundColor: "#E41119", border: "none", borderRadius: "10px" }}
                                                        onClick={() => deleteHandler ? deleteHandler(item) : null}>
                                                        <p className="action-txt">Delete</p>
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </td>
                                }

                            </tr>
                        </tbody>
                    ))}
            </table>
            {/* {currentItems &&
                currentItems.map((item) => (
                    <div>
                        <h3>Item #{item.serialNumber}</h3>
                    </div>
                ))} */}
        </>
    );
}

export const PaginatedItemsOffline = ({ itemsPerPage, data, headers, colNames, Action, Permissions, editHandler, deleteHandler, QRCode, modalopen, showPermissionHandler, refresh }) => {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(data.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(data.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, data]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        console.log(event.selected);
        const newOffset = (event.selected * itemsPerPage) % data.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };
    useEffect(() => {

        const newOffset = (0 * itemsPerPage) % data.length;
        console.log(
            `User requested page number ${0}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    }, [refresh, data, itemsPerPage])
    console.log(data, 'datadata');

    return (
        <>
            <Items headers={headers} colNames={colNames}
                {...{ deleteHandler, editHandler, modalopen, showPermissionHandler }} Action={Action} Permissions={Permissions} currentItems={currentItems} QRCode={QRCode} />
            <ReactPaginate
                className='ul-main'
                activeClassName='active-table-btn'
                activeLinkClassName='active-table-btn-link'
                previousClassName='previous-table-btn'
                pageLinkClassName='page-link-btn'
                nextClassName='next-table-btn'
                previousLinkClassName='previous-table-btn-link'
                nextLinkClassName='previous-table-btn-link'
                breakLabel="..."
                nextLabel="Next"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="Previous"
                renderOnZeroPageCount={null}
            />
        </>
    );
}